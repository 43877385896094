import ReactHTMLTableToExcel from 'react-html-table-to-excel';

/*
* Botón descargar Excel
* Funcion para exportar un botón como componente.
* ! Recibe id de tabla que se quiere descargar
* Al hacer click, descarga excel con nombre id-fecha. Formato YYYY-MM-DD.
* VCG
*/
export default function DownloadButton(props) {
    const { id, buttonText, filenameSuffix } = props;
    const date = new Date().toISOString().slice(0, 10);
    const filename = filenameSuffix ? `${filenameSuffix}-${date}` : `${id}-${date}`;
    return (
        <ReactHTMLTableToExcel
            id={`${id}-button`}
            className="btn btn-primary mt-2 mb-2"
            table={id}
            filename={filename}
            sheet="medallero"
            buttonText={buttonText || "Descargar Excel"} />
    );
}