//Libraries
import React, { useState } from "react";
// Components
import ListaTiendas from "./ListaTiendas";
import ListaAsesores from "./ListaAsesores";
import ListaCSS from "./ListaCSS";

const AdministracionMetas = ({ setloading, userInfo, usuario }) => {
  // View que decide si se muestra el listado de tiendas o una tienda en específico
  const vista = (usuario.puede.ver.administracionDeMetas.credito)?2:3;
  const [view, setview] = useState(usuario.tipo === 1 ? 1 : vista);
  const [cadenaComercial, setcadenaComercial] = useState(
    userInfo.cadenaComercial ? userInfo.cadenaComercial : 1
  );
  // Id de tienda en la que se va a administrar meta
  const [IdTienda, setIdTienda] = useState(
    userInfo.numeroTienda ? userInfo.numeroTienda : null
  );
  const [nombreTienda, setNombreTienda] = useState(null);

  switch (view) {
    case 1:
      return (
        <ListaTiendas
          setloading={setloading}
          cadenaComercial={cadenaComercial}
          setcadenaComercial={setcadenaComercial}
          userInfo={userInfo}
          usuario={usuario}
          setview={setview}
          setIdTienda={setIdTienda}
          vista={vista}
          setNombreTienda={setNombreTienda}
        />
      );

    case 2:
      return (
        <ListaAsesores
          setview={setview}
          setloading={setloading}
          IdTienda={IdTienda}
          cadenaComercial={cadenaComercial}
          userInfo={userInfo}
          setcadenaComercial={setcadenaComercial}
          usuario={usuario}
          nombreTienda={nombreTienda}
        />
      );
    case 3:
      return (
        <ListaCSS
          setview={setview}
          setloading={setloading}
          IdTienda={IdTienda}
          cadenaComercial={cadenaComercial}
          userInfo={userInfo}
          setcadenaComercial={setcadenaComercial}
          usuario={usuario}
          nombreTienda={nombreTienda}
        />
      );
    default:
      break;
  }
};

export default AdministracionMetas;
