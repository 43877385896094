// Libraries
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
// Components
import TablaCSS from "./tablas/TablaCSS";
// Icons
import IconAdminGoals from "../../assets/icons/admin_goals_black.svg";
import IconBack from "../../assets/icons/back.svg";

const ListaCSS = ({
  setview,
  setloading,
  IdTienda,
  cadenaComercial,
  userInfo,
  usuario,
  nombreTienda
}) => {
  // Historial de navegación
  const history = useHistory();

  // Status que nos dice si ya hubo respuesta o no
  const [status, setstatus] = useState(null);
  // Hook que determina si se está hablando de reactivaciones o migraciones
  const [categoria, setcategoria] = useState("reactivaciones");

  // Si se devuelve un error, se envía un mensaje de error
  if (status === 0) {
    return (
      <div className="card text-center">
        <div className="text-center">
          {usuario.puede.ver.administracionDeMetas.todos && (
            <div className="text-left">
              <div onClick={() => history.goBack()} className="cursor-pointer">
                <img src={IconBack} alt="Back Arrow" className="back-arrow" />
              </div>
            </div>
          )}
          <div className="alert alert-warning my-1rem" role="alert">
            Aún no hay usuarios o metas en esta tienda. Si te gustaría añadir un
            empleado, haz click{" "}
            <Link
              to={`${process.env.PUBLIC_URL}/anadir-usuario`}
              className="alert-link"
            >
              aquí.
            </Link>
            {userInfo.clavePerfil === "Administrador_PIF" ? (
              <p>
                Si te gustaría añadir metas, haz click{" "}
                <Link
                  to={`${process.env.PUBLIC_URL}/carga-de-metas`}
                  className="alert-link"
                >
                  aquí.
                </Link>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  // Si todo sale bien
  return (
    <div className="card rounded">
      {usuario.puede.ver.administracionDeMetas.todos && (
        <div onClick={() => setview(1)} className="cursor-pointer">
          <img src={IconBack} alt="Back Arrow" className="back-arrow" />
        </div>
      )}

      {usuario.puede.ver.administracionDeMetas.csscredito && (
      <div className='row'>
        <div class="col-6">
          <button class="btn btn-primary btn-block p-1" onClick= { ()=>{ setview(2)}}>Metas Credito</button>
        </div>
        <div class="col-6">
          <button disabled="" class="btn btn-secondary btn-block p-1">Metas Centro de Seguros</button>
        </div>
      </div>
      )}
      <div className="text-center py-1rem">
        <img
          src={IconAdminGoals}
          alt="ícono administración de metas"
          className="main-icon text-black"
        />
        <h5 className="pt-1rem">Administración de {categoria} en CS</h5>
        <h6 className="pt-1rem">{nombreTienda}</h6>
      </div>
      <TablaCSS
        cadenaComercial={cadenaComercial}
        IdTienda={IdTienda}
        setloading={setloading}
        userInfo={userInfo}
        status={status}
        setstatus={setstatus}
        categoria={categoria}
        setcategoria={setcategoria}
      />
    </div>
  );
};

export default ListaCSS;
