// Libraries
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import instance from "../../helpers/api.instance";
import swal from "@sweetalert/with-react";
// Components
import AlertForm from "../../components/general/AlertForm";
import { notification } from "../../helpers/Helpers";
// ICONS
import IconProfile from "../../assets/icons/profile_black.svg";

const Perfil = ({ userInfo, setloading }) => {
  const [readOnly, setReadOnly] = useState(true);
  // React hook form
  const { register, handleSubmit, errors } = useForm();
  // State que define si se muestra la sección de cambio de contraseña o no
  const [togglePassword, settogglePassword] = useState(false);

  // Función que esconde o muestra los campos par cambiar contraseña
  const toggleChangePassword = () => {
    settogglePassword(!togglePassword);
  };

  // Submit para cambiar la contraseña
  const onSubmit = ({ Nueva, Confirmar }) => {
    // Verificamos que la contraseña y la confirmación sean idénticas
    if (Nueva !== Confirmar) {
      notification("Ambos campos deben ser iguales.");
    }
    // Verificamos que ambos campos existan
    else if (!Nueva || !Confirmar) {
      notification("Ambos campos son obligatorios.");
    }
    // Si todo se cumple, actualizamos la contraseña
    else {
      setloading(true);
      instance
        .post(`/usuario/actualizaPassword`, {
          idUsuario: userInfo.idUsuario,
          password: Nueva,
        })
        .then((res) => {
          if (res.status === 1)
            swal({
              title: "Contraseña actualizada",
              text: "Guárdala en un lugar seguro y no la compartas con nadie.",
              icon: "success",
            });
        })
        // Apagamos el loader
        .finally(() => setloading(false));
    }
  };

  const onSubmitPerfil = (data) => {
    data.idUsuario = userInfo.idUsuario;
    delete data.numeroEmpleado;
    setloading(true);
    instance
      .post(`/usuario/actualizarDatosUsuario`, data)
      .then((res) => {
        if (res.status === 1) {
          notification(res.msg, "success");
        } else {
          notification(res.msg);
        }
      })
      .then(() => setloading(false));
  };

  return (
    <div className="card rounded">
      <div className="text-center">
        <img
          src={IconProfile}
          className="img-fluid"
          width="75"
          alt="Icon Profile"
        />
      </div>
      <div className="text-center pt-1rem mb-3rem">
        <h2>Información de usuario</h2>
      </div>
      <div className="row">
        <div className="d-none d-lg-block col"></div>
        <div className="col my-1rem">
          <form onSubmit={handleSubmit(onSubmitPerfil)} className="text-center">
            <div className="row justify-content-between mt-rem5">
              <div className="col text-left">Tipo de perfil:</div>
              <div className="col text-right">{userInfo.nombrePerfil}</div>
            </div>
            <div className="row justify-content-between mt-rem5">
              <div className="col text-left">Nombre(s):</div>
              <div className="col text-right">
                <input
                  type="text"
                  name="nombres"
                  className="form-control mb-rem5"
                  defaultValue={userInfo.nombres ? userInfo.nombres : ""}
                  ref={register({ required: true })}
                  readOnly={readOnly}
                />
                <div className="text-right">
                  {errors.nombres && <AlertForm />}
                </div>
              </div>
            </div>
            <div className="row justify-content-between mt-rem5">
              <div className="col text-left">Apellido paterno:</div>
              <div className="col text-right">
                <input
                  type="text"
                  name="apPat"
                  className="form-control mb-rem5"
                  defaultValue={userInfo.apPat ? userInfo.apPat : ""}
                  ref={register({ required: true })}
                  readOnly={readOnly}
                />
                <div className="text-right">
                  {errors.apPat && <AlertForm />}
                </div>
              </div>
            </div>
            <div className="row justify-content-between mt-rem5">
              <div className="col text-left">Apellido materno:</div>
              <div className="col text-right">
                <input
                  type="text"
                  name="apMat"
                  className="form-control mb-rem5"
                  defaultValue={userInfo.apMat ? userInfo.apMat : ""}
                  ref={register({ required: true })}
                  readOnly={readOnly}
                />
                <div className="text-right">
                  {errors.apMat && <AlertForm />}
                </div>
              </div>
            </div>
            {userInfo.numeroEmpleado ? (
              <div className="row justify-content-between mt-rem5">
                <div className="col text-left">Número de empleado:</div>
                <div className="col text-right">
                  <input
                    type="text"
                    name="numeroEmpleado"
                    className="form-control mb-rem5"
                    defaultValue={userInfo.numeroEmpleado}
                    readOnly={true}
                  />
                </div>
              </div>
            ) : null}
            <div className="row justify-content-between mt-rem5">
              <div className="col text-left">Correo:</div>
              <div className="col text-right">
                <input
                  type="mail"
                  name="correo"
                  className="form-control mb-rem5"
                  defaultValue={userInfo.correo ? userInfo.correo : ""}
                  ref={register({
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  readOnly={readOnly}
                />
                <div className="text-right">
                  {errors.correo && <AlertForm />}
                </div>
              </div>
            </div>
            <div className="row justify-content-between mt-rem5">
              <div className="col text-left">Celular:</div>
              <div className="col text-right">
                <input
                  type="tel"
                  name="celular"
                  maxLength={10}
                  minLength={10}
                  className="form-control mb-rem5"
                  defaultValue={userInfo.celular ? userInfo.celular : ""}
                  ref={register({ pattern: /^([0-9])*$/ })}
                  readOnly={readOnly}
                />
                <div className="text-right">
                  {errors.celular && <AlertForm />}
                </div>
              </div>
            </div>
            {readOnly && (
              <div className="text-center">
                {" "}
                <button
                  className="btn btn-outline-info mt-1rem5"
                  onClick={() => setReadOnly(false)}
                >
                  Editar usuario
                </button>
              </div>
            )}
            {!readOnly && (
              <div className="text-center">
                <button
                  className="btn btn-info mt-1rem5"
                  onClick={() => setReadOnly(false)}
                >
                  Editar usuario
                </button>
              </div>
            )}
          </form>
          <div className="text-center justify-content-center mt-3rem">
            {togglePassword === true ? (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mw-343px text-center justify-content-center mx-auto"
              >
                <div className="form-label-group">
                  <input
                    type="password"
                    name="Nueva"
                    placeholder="1"
                    ref={register({ required: true })}
                    className="form-control"
                  />
                  <label>Nueva Contraseña</label>
                </div>
                {errors.Nueva && <AlertForm />}

                <div className="form-label-group mt-1rem5">
                  <input
                    type="password"
                    name="Confirmar"
                    placeholder="1"
                    ref={register({ required: true })}
                    className="form-control"
                  />
                  <label>Confirmar Contraseña</label>
                </div>
                {errors.Confirmar && <AlertForm />}

                <button className="mt-1rem btn btn-danger">
                  Cambiar contraseña
                </button>
              </form>
            ) : (
              <button
                className="btn btn-outline-danger"
                onClick={() => toggleChangePassword()}
              >
                Cambiar contraseña
              </button>
            )}
          </div>
        </div>
        <div className="d-none d-lg-block col"></div>
      </div>
    </div>
  );
};

export default Perfil;
