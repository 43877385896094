import React, { useState } from 'react';
import instance from '../../helpers/api.instance';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { normalizePercentage } from '../../helpers/Helpers';

const ReporteEmpleadosAll = ({ cadenaComercial, meta, mes, anio, tipo, setloading }) => {
  const [reportes, setReportes] = useState(null);

  const handleDownloadExcel = async () => {
    setloading(true);
    try {
      const res = await instance.get(`/reportes/avances?cadenaComercial=${cadenaComercial}&meta=${meta}&mes=${mes}&anio=${anio}&tipo=${tipo}`);
      if (res.status === 1) {
        setReportes(res.data.avances);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Reporte');

        worksheet.views = [{ state: 'frozen', ySplit: 4 }];

        // estilos de título general
        const titleStyle = {
          font: { bold: true, size: 14, color: { argb: 'FFFFFFFF' } },
          alignment: { vertical: 'middle', horizontal: 'center' },
          fill: { type: 'pattern', pattern:'solid', fgColor:{ argb:'555555' } }
        };

        // estilos subtitulos
        const subtitleStyle = { 
          font: { size: 12, bold: false, color: { argb: '3333333' } },
          alignment: { vertical: 'middle', horizontal: 'center' },
          fill: { type: 'pattern', pattern:'solid', fgColor:{ argb:'ECECEC' } }
        };

        // estilos de celdas
        const cellStyle = {
          font: { size: 12 },
          alignment: { vertical: 'middle', horizontal: 'center' },
          border: {
            top: { style: 'hair' },
            left: { style: 'hair' },
            bottom: { style: 'hair' },
            right: { style: 'hair' }
          },
        };

        if (cadenaComercial === '1') {
          // títulos principales
          worksheet.mergeCells('A1:A4');
          worksheet.getCell('A1').value = 'Rank';
          worksheet.getCell('A1').style = titleStyle;

          worksheet.mergeCells('B1:B4');
          worksheet.getCell('B1').value = '# Empleado';
          worksheet.getCell('B1').style = titleStyle;

          worksheet.mergeCells('C1:C4');
          worksheet.getCell('C1').value = 'Asesor';
          worksheet.getCell('C1').style = titleStyle;

          worksheet.mergeCells('D1:D4');
          worksheet.getCell('D1').value = 'Área';
          worksheet.getCell('D1').style = titleStyle;

          worksheet.mergeCells('E1:E4');
          worksheet.getCell('E1').value = 'Zona';
          worksheet.getCell('E1').style = titleStyle;

          worksheet.mergeCells('F1:F4');
          worksheet.getCell('F1').value = 'Numero Tienda';
          worksheet.getCell('F1').style = titleStyle;

          worksheet.mergeCells('G1:G4');
          worksheet.getCell('G1').value = 'Nombre Tienda';
          worksheet.getCell('G1').style = titleStyle;

          worksheet.mergeCells('H1:K1');
          worksheet.getCell('H1').value = 'Reactivaciones';
          worksheet.getCell('H1').style = titleStyle;

          worksheet.mergeCells('L1:O1');
          worksheet.getCell('L1').value = 'Migraciones';
          worksheet.getCell('L1').style = titleStyle;

          worksheet.mergeCells('P1:AB1');
          worksheet.getCell('P1').value = 'Cuentas nuevas';
          worksheet.getCell('P1').style = titleStyle;

          worksheet.mergeCells('AC1:AC4');
          worksheet.getCell('AC1').value = '% DE RETENCIÓN';
          worksheet.getCell('AC1').style = titleStyle;

          worksheet.mergeCells('AD1:AM1');
          worksheet.getCell('AD1').value = 'Rechazo inicial';
          worksheet.getCell('AD1').style = titleStyle;

          worksheet.mergeCells('AN1:AO1');
          worksheet.getCell('AN1').value = 'IPPF';
          worksheet.getCell('AN1').style = titleStyle;

          // SUBTITULOS REACTIVACIONES
          worksheet.mergeCells('H2:H4');
          worksheet.getCell('H2').value = 'Meta individual';
          worksheet.getCell('H2').style = subtitleStyle;

          worksheet.mergeCells('I2:I4');
          worksheet.getCell('I2').value = 'Acumulado';
          worksheet.getCell('I2').style = subtitleStyle;

          worksheet.mergeCells('J2:J4');
          worksheet.getCell('J2').value = 'Faltan';
          worksheet.getCell('J2').style = subtitleStyle;

          worksheet.mergeCells('K2:K4');
          worksheet.getCell('K2').value = '% Avance';
          worksheet.getCell('K2').style = subtitleStyle;

          // SUBTITULOS MIGRACIONES
          worksheet.mergeCells('L2:L4');
          worksheet.getCell('L2').value = 'Meta individual';
          worksheet.getCell('L2').style = subtitleStyle;

          worksheet.mergeCells('M2:M4');
          worksheet.getCell('M2').value = 'Acumulado';
          worksheet.getCell('M2').style = subtitleStyle;

          worksheet.mergeCells('N2:N4');
          worksheet.getCell('N2').value = 'Faltan';
          worksheet.getCell('N2').style = subtitleStyle;

          worksheet.mergeCells('O2:O4');
          worksheet.getCell('O2').value = '% Avance';
          worksheet.getCell('O2').style = subtitleStyle;

          // SUBTITULOS CUENTAS NUEVAS
          worksheet.mergeCells('P2:R3');
          worksheet.getCell('P2').value = 'CN';
          worksheet.getCell('P2').style = subtitleStyle;

          worksheet.mergeCells('S2:U3');
          worksheet.getCell('S2').value = 'CN CP';
          worksheet.getCell('S2').style = subtitleStyle;

          worksheet.mergeCells('V2:X3');
          worksheet.getCell('V2').value = 'CN CP SF';
          worksheet.getCell('V2').style = subtitleStyle;

          worksheet.mergeCells('Y2:AA3');
          worksheet.getCell('Y2').value = 'CN TOTAL';
          worksheet.getCell('Y2').style = subtitleStyle;

          worksheet.mergeCells('AB2:AB4');
          worksheet.getCell('AB2').value = 'Cuentas totales canceladas en el mismo mes';
          worksheet.getCell('AB2').style = subtitleStyle;

          // Rechazo inicial
          worksheet.mergeCells('AD2:AE3');
          worksheet.getCell('AD2').value = 'CN';
          worksheet.getCell('AD2').style = subtitleStyle;

          worksheet.mergeCells('AF2:AG3');
          worksheet.getCell('AF2').value = 'CN CP';
          worksheet.getCell('AF2').style = subtitleStyle;

          worksheet.mergeCells('AH2:AI3');
          worksheet.getCell('AH2').value = 'CN CP SF';
          worksheet.getCell('AH2').style = subtitleStyle;

          worksheet.mergeCells('AJ2:AJ4');
          worksheet.getCell('AJ2').value = '% Cancelación';
          worksheet.getCell('AJ2').style = subtitleStyle;

          worksheet.mergeCells('AK2:AK4');
          worksheet.getCell('AK2').value = 'Cuentas totales rechazadas';
          worksheet.getCell('AK2').style = subtitleStyle;

          worksheet.mergeCells('AL2:AL4');
          worksheet.getCell('AL2').value = 'Anterior';
          worksheet.getCell('AL2').style = subtitleStyle;

          worksheet.mergeCells('AM2:AM4');
          worksheet.getCell('AM2').value = 'Actual';
          worksheet.getCell('AM2').style = subtitleStyle;

          worksheet.mergeCells('AN2:AN4');
          worksheet.getCell('AN2').value = 'Meta Tienda';
          worksheet.getCell('AN2').style = subtitleStyle;

          worksheet.mergeCells('AO2:AO4');
          worksheet.getCell('AO2').value = 'IPPF por asesor al mes anterior';
          worksheet.getCell('AO2').style = subtitleStyle;

          // SUB SUBTITULOS
          worksheet.mergeCells('P4:P4');
          worksheet.getCell('P4').value = 'Total';
          worksheet.getCell('P4').style = subtitleStyle;
          worksheet.mergeCells('Q4:Q4');
          worksheet.getCell('Q4').value = 'Básico #';
          worksheet.getCell('Q4').style = subtitleStyle;
          worksheet.mergeCells('R4:R4');
          worksheet.getCell('R4').value = 'Básico %';
          worksheet.getCell('R4').style = subtitleStyle;

          worksheet.mergeCells('S4:S4');
          worksheet.getCell('S4').value = 'Total';
          worksheet.getCell('S4').style = subtitleStyle;
          worksheet.mergeCells('T4:T4');
          worksheet.getCell('T4').value = 'Básico #';
          worksheet.getCell('T4').style = subtitleStyle;
          worksheet.mergeCells('U4:U4');
          worksheet.getCell('U4').value = 'Básico %';
          worksheet.getCell('U4').style = subtitleStyle;

          worksheet.mergeCells('V4:V4');
          worksheet.getCell('V4').value = 'Total';
          worksheet.getCell('V4').style = subtitleStyle;
          worksheet.mergeCells('W4:W4');
          worksheet.getCell('W4').value = 'Básico #';
          worksheet.getCell('W4').style = subtitleStyle;
          worksheet.mergeCells('X4:X4');
          worksheet.getCell('X4').value = 'Básico %';
          worksheet.getCell('X4').style = subtitleStyle;

          worksheet.mergeCells('Y4:Y4');
          worksheet.getCell('Y4').value = 'Total';
          worksheet.getCell('Y4').style = subtitleStyle;
          worksheet.mergeCells('Z4:Z4');
          worksheet.getCell('Z4').value = 'Básico #';
          worksheet.getCell('Z4').style = subtitleStyle;
          worksheet.mergeCells('AA4:AA4');
          worksheet.getCell('AA4').value = 'Básico %';
          worksheet.getCell('AA4').style = subtitleStyle;

          worksheet.mergeCells('AD4:AD4');
          worksheet.getCell('AD4').value = 'Cantidad';
          worksheet.getCell('AD4').style = subtitleStyle;
          worksheet.mergeCells('AE4:AE4');
          worksheet.getCell('AE4').value = '%';
          worksheet.getCell('AE4').style = subtitleStyle;

          worksheet.mergeCells('AF4:AF4');
          worksheet.getCell('AF4').value = 'Cantidad';
          worksheet.getCell('AF4').style = subtitleStyle;
          worksheet.mergeCells('AG4:AG4');
          worksheet.getCell('AG4').value = '%';
          worksheet.getCell('AG4').style = subtitleStyle;

          worksheet.mergeCells('AH4:AH4');
          worksheet.getCell('AH4').value = 'Cantidad';
          worksheet.getCell('AH4').style = subtitleStyle;
          worksheet.mergeCells('AI4:AI4');
          worksheet.getCell('AI4').value = '%';
          worksheet.getCell('AI4').style = subtitleStyle;

          // ANCHO DE LAS COLUMNAS
          worksheet.getColumn('B').width = 15;
          worksheet.getColumn('C').width = 40;
          worksheet.getColumn('D').width = 10;
          worksheet.getColumn('E').width = 10;
          worksheet.getColumn('F').width = 20;
          worksheet.getColumn('G').width = 35;
          worksheet.getColumn('H').width = 15;
          worksheet.getColumn('I').width = 15;
          worksheet.getColumn('J').width = 10;
          worksheet.getColumn('L').width = 15;
          worksheet.getColumn('M').width = 15;
          worksheet.getColumn('AB').width = 45;
          worksheet.getColumn('AC').width = 25;
          worksheet.getColumn('AJ').width = 20;
          worksheet.getColumn('AK').width = 30;
          worksheet.getColumn('AL').width = 15;
          worksheet.getColumn('AN').width = 15;
          worksheet.getColumn('AO').width = 30;
        } else if (cadenaComercial === '2') {
          // Suburbia
          // títulos principales
          worksheet.mergeCells('A1:A4');
          worksheet.getCell('A1').value = 'Rank';
          worksheet.getCell('A1').style = titleStyle;

          worksheet.mergeCells('B1:B4');
          worksheet.getCell('B1').value = '# Empleado';
          worksheet.getCell('B1').style = titleStyle;

          worksheet.mergeCells('C1:C4');
          worksheet.getCell('C1').value = 'Asesor';
          worksheet.getCell('C1').style = titleStyle;

          worksheet.mergeCells('D1:D4');
          worksheet.getCell('D1').value = 'Área';
          worksheet.getCell('D1').style = titleStyle;

          worksheet.mergeCells('E1:E4');
          worksheet.getCell('E1').value = 'Zona';
          worksheet.getCell('E1').style = titleStyle;

          worksheet.mergeCells('F1:F4');
          worksheet.getCell('F1').value = 'Numero Tienda';
          worksheet.getCell('F1').style = titleStyle;

          worksheet.mergeCells('G1:G4');
          worksheet.getCell('G1').value = 'Nombre Tienda';
          worksheet.getCell('G1').style = titleStyle;

          worksheet.mergeCells('H1:K1');
          worksheet.getCell('H1').value = 'Reactivaciones';
          worksheet.getCell('H1').style = titleStyle;

          worksheet.mergeCells('L1:O1');
          worksheet.getCell('L1').value = 'Migraciones';
          worksheet.getCell('L1').style = titleStyle;

          worksheet.mergeCells('P1:U1');
          worksheet.getCell('P1').value = 'Cuentas nuevas';
          worksheet.getCell('P1').style = titleStyle;

          worksheet.mergeCells('V1:V4');
          worksheet.getCell('V1').value = '% DE RETENCIÓN';
          worksheet.getCell('V1').style = titleStyle;

          worksheet.mergeCells('W1:X1');
          worksheet.getCell('W1').value = 'Rechazo inicial';
          worksheet.getCell('W1').style = titleStyle;

          worksheet.mergeCells('Y1:Z1');
          worksheet.getCell('Y1').value = 'IPPF';
          worksheet.getCell('Y1').style = titleStyle;

          // SUBTITULOS REACTIVACIONES
          worksheet.mergeCells('H2:H4');
          worksheet.getCell('H2').value = 'Meta individual';
          worksheet.getCell('H2').style = subtitleStyle;
          worksheet.mergeCells('I2:I4');
          worksheet.getCell('I2').value = 'Acumulado';
          worksheet.getCell('I2').style = subtitleStyle;
          worksheet.mergeCells('J2:J4');
          worksheet.getCell('J2').value = 'Faltan';
          worksheet.getCell('J2').style = subtitleStyle;
          worksheet.mergeCells('K2:K4');
          worksheet.getCell('K2').value = '% Avance';
          worksheet.getCell('K2').style = subtitleStyle;

          // SUBTITULOS MIGRACIONES
          worksheet.mergeCells('L2:L4');
          worksheet.getCell('L2').value = 'Meta individual';
          worksheet.getCell('L2').style = subtitleStyle;
          worksheet.mergeCells('M2:M4');
          worksheet.getCell('M2').value = 'Acumulado';
          worksheet.getCell('M2').style = subtitleStyle;
          worksheet.mergeCells('N2:N4');
          worksheet.getCell('N2').value = 'Faltan';
          worksheet.getCell('N2').style = subtitleStyle;
          worksheet.mergeCells('O2:O4');
          worksheet.getCell('O2').value = '% Avance';
          worksheet.getCell('O2').style = subtitleStyle;

          // SUBTITULOS CUENTAS NUEVAS
          worksheet.mergeCells('P2:P4');
          worksheet.getCell('P2').value = 'Total CN';
          worksheet.getCell('P2').style = subtitleStyle;

          worksheet.mergeCells('Q2:Q4');
          worksheet.getCell('Q2').value = '# Básico con LC > 5000';
          worksheet.getCell('Q2').style = subtitleStyle;

          worksheet.mergeCells('R2:R4');
          worksheet.getCell('R2').value = '% Básico con LC > 5000';
          worksheet.getCell('R2').style = subtitleStyle;

          worksheet.mergeCells('S2:S4');
          worksheet.getCell('S2').value = 'Cuentas totales canceladas en el mismo mes';
          worksheet.getCell('S2').style = subtitleStyle;

          worksheet.mergeCells('T2:T4');
          worksheet.getCell('T2').value = '% Cancelación';
          worksheet.getCell('T2').style = subtitleStyle;

          worksheet.mergeCells('U2:U4');
          worksheet.getCell('U2').value = 'Cuentas totales rechazadas';
          worksheet.getCell('U2').style = subtitleStyle;

          worksheet.mergeCells('W2:W4');
          worksheet.getCell('W2').value = 'Anterior';
          worksheet.getCell('W2').style = subtitleStyle;

          worksheet.mergeCells('X2:X4');
          worksheet.getCell('X2').value = 'Actual';
          worksheet.getCell('X2').style = subtitleStyle;

          // SUBTITULOS IPPF
          worksheet.mergeCells('Y2:Y4');
          worksheet.getCell('Y2').value = 'Meta Tienda';
          worksheet.getCell('Y2').style = subtitleStyle;
          worksheet.mergeCells('Z2:Z4');
          worksheet.getCell('Z2').value = 'IPPF por asesor al mes anterior';
          worksheet.getCell('Z2').style = subtitleStyle;

          worksheet.getColumn('B').width = 15;
          worksheet.getColumn('C').width = 38;
          worksheet.getColumn('D').width = 10;

          worksheet.getColumn('E').width = 30;
          worksheet.getColumn('F').width = 20;
          worksheet.getColumn('G').width = 40;
          worksheet.getColumn('H').width = 15;
          
          worksheet.getColumn('I').width = 20;
          worksheet.getColumn('J').width = 15;
          worksheet.getColumn('K').width = 10;
          worksheet.getColumn('L').width = 15;

          worksheet.getColumn('M').width = 15;
          worksheet.getColumn('N').width = 15;
          worksheet.getColumn('O').width = 15;
          worksheet.getColumn('P').width = 15;
          worksheet.getColumn('Q').width = 25;
          worksheet.getColumn('R').width = 25;

          worksheet.getColumn('S').width = 45;
          worksheet.getColumn('T').width = 15;
          worksheet.getColumn('U').width = 25;
          worksheet.getColumn('V').width = 25;
          worksheet.getColumn('Y').width = 15;
          worksheet.getColumn('Z').width = 35;
        }

        const startingRow = 5;
        res.data.avances.forEach((report, index) => {
          const row = worksheet.getRow(startingRow + index);
          for (let col = 1; col <= 45; col++) {
            if (index > 0 || col > 4) {
              row.getCell(col).style = cellStyle;
            }
          }

          if (cadenaComercial === '1') {
            // Mapeo de campos a columnas
            row.getCell(1).value = report.rank; // Rank
            row.getCell(2).value = report.numeroEmpleado; // # Empleado
            row.getCell(3).value = report.nombreEmpleado;  // Asesor
            row.getCell(4).value = report.area; // Área

            row.getCell(5).value = report.zona; // # zona
            row.getCell(6).value = report.numeroTienda;  // numeroTienda
            row.getCell(7).value = report.nombreTienda; // nombreTienda

            row.getCell(8).value = report.metaReactivacion;  // Meta individual	
            row.getCell(9).value = report.avanceReactivacion; // Acumulado
            row.getCell(10).value = report.faltanReactivacion; // Faltan
            row.getCell(11).value = normalizePercentage(report.porcentajeReactivacion); // % avance

            row.getCell(12).value = report.metaMigracion; // Meta individual	
            row.getCell(13).value = report.avanceMigracion; // Acumulado
            row.getCell(14).value = report.faltanMigracion; // Faltan
            row.getCell(15).value = normalizePercentage(report.porcentajeMigracion); // % avance

            // Cuentas nuevas
            row.getCell(16).value = report.cuentasNuevas?.cn.total; // CN Total
            row.getCell(17).value = report.cuentasNuevas?.cn.totalBasico; // CN Básico #
            row.getCell(18).value = normalizePercentage(report.cuentasNuevas?.cn.totalBasicoPorcentaje); // CN Básico %
            row.getCell(19).value = report.cuentasNuevas?.cncp.total; // CN CP Total
            row.getCell(20).value = report.cuentasNuevas?.cncp.totalBasico; // CN CP Básico #
            row.getCell(21).value = normalizePercentage(report.cuentasNuevas?.cncp.totalBasicoPorcentaje); // CN CP Básico %
            row.getCell(22).value = report.cuentasNuevas?.cncpsf.total; // CN CP SF Total
            row.getCell(23).value = report.cuentasNuevas?.cncpsf.totalBasico; // CN CP SF Básico #
            row.getCell(24).value = normalizePercentage(report.cuentasNuevas?.cncpsf.totalBasicoPorcentaje); // CN CP SF Básico %
            row.getCell(25).value = report.cuentasNuevas?.total; // CN Total Total
            row.getCell(26).value = report.cuentasNuevas?.totalBasico; // CN Total Básico #
            row.getCell(27).value = normalizePercentage(report.cuentasNuevas?.totalBasicoPorcentaje); // CN Total Básico %
            row.getCell(28).value = report.canceladas; // Cuentas totales canceladas en el mismo mes
            row.getCell(29).value = normalizePercentage(report.promedioRetencion); // % DE RETENCIÓN
            // Rechazo inicial
            row.getCell(30).value = report.rechazoTotalCN; // CN Cantidad
            row.getCell(31).value = normalizePercentage(report.rechazoInicialCN); // CN %
            row.getCell(32).value = report.rechazoTotalCNCP; // CN CP Cantidad
            row.getCell(33).value = normalizePercentage(report.rechazoInicialCNCP); // CN CP %
            row.getCell(34).value = report.rechazoTotalCNCPSF; // CN CP SF Cantidad
            row.getCell(35).value = normalizePercentage(report.rechazoInicialCNCPSF); // CN CP SF %
            row.getCell(36).value = normalizePercentage(report.porcentajeCanceladas);  // % Cancelación
            row.getCell(37).value = report.totalCuentasRechazo; // Cuentas totales rechazadas
            row.getCell(38).value = normalizePercentage(report.rechazoAnterior); // Anterior
            row.getCell(39).value = normalizePercentage(report.rechazoActual);  // Actual
            row.getCell(40).value = normalizePercentage(report.metaIppf); // Meta
            row.getCell(41).value = normalizePercentage(report.ippfAnterior); // IPPF por asesor al mes anterior
          } else if (cadenaComercial === '2') {
            // Mapeo de campos a columnas
            row.getCell(1).value = report.rank; // Rank
            row.getCell(2).value = report.numeroEmpleado; // # Empleado
            row.getCell(3).value = report.nombreEmpleado;  // Asesor
            row.getCell(4).value = report.area; // Área

            row.getCell(5).value = report.zona; // # zona
            row.getCell(6).value = report.numeroTienda;  // numeroTienda
            row.getCell(7).value = report.nombreTienda; // nombreTienda

            row.getCell(8).value = report.metaReactivacion;  // Meta individual	
            row.getCell(9).value = report.avanceReactivacion; // Acumulado
            row.getCell(10).value = report.faltanReactivacion; // Faltan
            row.getCell(11).value = normalizePercentage(report.porcentajeReactivacion); // % avance

            row.getCell(12).value = report.metaMigracion; // Meta individual	
            row.getCell(13).value = report.avanceMigracion; // Acumulado
            row.getCell(14).value = report.faltanMigracion; // Faltan
            row.getCell(15).value = normalizePercentage(report.porcentajeMigracion); // % avance

            // Cuentas nuevas
            row.getCell(16).value = report.cuentasNuevas?.tlc.total; // Total CN
            row.getCell(17).value = report.cuentasNuevas?.tlc.totalBasico; // # Básico con LC > 5000
            row.getCell(18).value = normalizePercentage(report.cuentasNuevas?.tlc.totalBasicoPorcentaje); // % Básico con LC > 5000
            row.getCell(19).value = report.canceladas; // Cuentas totales canceladas en el mismo mes
            row.getCell(20).value = normalizePercentage(report.porcentajeCanceladas);  // % Cancelación
            row.getCell(21).value = report.totalCuentasRechazo; // Cuentas totales rechazadas
            row.getCell(22).value = normalizePercentage(report.promedioRetencion); // % DE RETENCIÓN
            row.getCell(23).value = normalizePercentage(report.rechazoAnterior); // Anterior
            row.getCell(24).value = normalizePercentage(report.rechazoActual);  // Actual
            row.getCell(25).value = normalizePercentage(report.metaIppf); // Meta Tienda
            row.getCell(26).value = normalizePercentage(report.ippfAnterior); // IPPF por asesor al mes anterior
          }
        });

        // buffer del workbook y descargar el archivo
        const buffer = await workbook.xlsx.writeBuffer();
        const cadenaComercialNombre = cadenaComercial === "1" ? "Liverpool" : "Suburbia";
        const nombreArchivo = `Reporte_Empleados_${cadenaComercialNombre}_${meta}_${mes}_${anio}.xlsx`;
        saveAs(new Blob([buffer]), nombreArchivo);
      } else {
        console.error('No hay datos para descargar');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <button onClick={handleDownloadExcel} className="btn btn-primary mt-2 mb-2">
        Descargar todos los asesores
    </button>
  );
};

export default ReporteEmpleadosAll;
