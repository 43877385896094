import React, { useState, useEffect, useMemo } from "react";
// Libraries
import instance from "../../helpers/api.instance";
import { useHistory, Link } from "react-router-dom";
// Components
import DataTableUsuarios from "../../components/general/DataTableUsuarios";
import { notification } from "../../helpers/Helpers";
import EditarUsuario from "../Usuarios/EditarUsuario";
import DownloadButton from "../../components/general/DownloadButton";

const IndexBuscar = ({ setloading, userInfo, usuario }) => {
  // Hook para acceder al historial de la app
  let history = useHistory();
  // Hook para saber cuál componente cargar
  const [view, setview] = useState(1);
  // Trigger para que se vuelva a detonar el useEffect al actualizar datos
  const [triggerEffect, settriggerEffect] = useState(0);
  // Lista de usuarios a mostrar
  const [listaUsuarios, setlistaUsuarios] = useState(null);
  // Listado de perfiles en los que se puede buscar usuarios
  const [listaPerfiles, setlistaPerfiles] = useState(null);
  // Tipo de usuarios a mostrar
  const [clavePerfil, setclavePerfil] = useState(
    userInfo.tipo === 1
      ? userInfo.clavePerfil
      : userInfo.cadenaComercial === "1"
        ? "Asesor_tienda_liverpool"
        : "Asesor_tienda_suburbia"
  );
  // Perfil al que vamos a editar
  const [perfil, setperfil] = useState(null);
  const [tiendas, settiendas] = useState(null);
  const [fechaInicio, setfechaInicio] = useState(null);
  const [fechaFin, setfechaFin] = useState(null);
  const [tienda, settienda] = useState(null);
  const [movimientos, setmovimientos] = useState([]);
  // Cadena comercial entre la que se eligirán los tipos de perfiles
  const [cadenaComercial, setCadenaComercial] = useState(
    userInfo.cadenaComercial
  );

  const [permiteAsignar, setpermiteAsignar] = useState(1);

  // UseEffect para obtener los tipos de perfiles que se pueden tener
  useEffect(() => {
    setloading(true);
    instance
      .get(`/tiendas/listaTiendas/${cadenaComercial}`)
      .then((response) => {
        settiendas(response.data.tiendas);
      })
      .finally(() => setloading(false));
  }, [cadenaComercial]);


  // Use Effect que trae la lista de usuarios
  useEffect(() => {
    setloading(true);
    // Obtenemos la lista de ususarios disponibles
    if (usuario.puede.ver.usuarios.todos) {
      instance
        .post(
          `/usuario/listaUsuarios`,
          // Cuerpo del request
          {
            clavePerfil: clavePerfil,
            cadenaComercial: cadenaComercial,
            numeroTienda: userInfo.numeroTienda,
          }
        )
        .then((res) => {
          if (res.status === 1) {
            setlistaUsuarios(res.data.usuarios);
            setpermiteAsignar(res.data.banderaMenu);
          } else {
            setlistaUsuarios(0);
          }
        })
        // Apagamos el loader
        .finally(() => setloading(false));
    } else {
      instance
        .post(
          `/usuario/listaUsuariosTienda`,
          // Cuerpo del request
          {
            clavePerfil: clavePerfil,
            cadenaComercial: cadenaComercial,
            numeroTienda: userInfo.numeroTienda,
          }
        )
        .then((res) => {
          if (res.status === 1) {
            setlistaUsuarios(res.data.usuarios);
          } else {
            setlistaUsuarios(0);
          }
        })
        // Apagamos el loader
        .finally(() => setloading(false));
    }
  }, [clavePerfil, triggerEffect]);

  // Damos de baja/alta un usuario
  const toggleUsuario = (usuario, status) => {
    setloading(true);
    instance
      .post(
        `/usuario/estatusUsuario`,
        // Cuerpo del request
        {
          idUsuario: usuario.idUsuario,
          status: status,
        }
      )
      .then((res) => {
        // Si la respuesta es exitosa
        console.log(res.status)
        if (res.status === 1) {
          notification(res.msg, "warning");
          // Activamos el trigger para que el useEffect se actualice
          settriggerEffect(usuario.idUsuario + '-' + status);
        }
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  // Al presionarse el botón de añadir usuario, lo llevamos a la página para añadir usuarios
  const onAnadir = () => {
    setloading(true);
    instance
      .post(
        `avances/movimientosPif`,
        {
          cadenaComercial: cadenaComercial,
          numeroTienda: tienda,
          fechaInicio: fechaInicio,
          fechaFin: fechaFin

        }
      )
      .then((res) => {
        // Si la respuesta es exitosa
        console.log(res.status)
        if (res.status === 1) {
          setmovimientos(res.data.lista)
          // Activamos el trigger para que el useEffect se actualice
        } else {
          setmovimientos([])
          // notification(res.msg, "warning");
        }
      })
      // Apagamos el loader
      .finally(() => setloading(false));

  };

  // Funciones para manejar cambio de filtros
  const handleTienda = (e) => settienda(e.target.value);
  const handleCadenaComercial = (e) => setCadenaComercial(e.target.value);
  const handleFechaInicial = (e) => setfechaInicio(e.target.value);
  const handleFechaFin = (e) => setfechaFin(e.target.value);
  const handleEditar = (usuario) => {
    setview(2);
    setperfil(usuario);
  };


  if (view === 1)
    return (
      <div className="card pt-1rem">
        <h3 className="text-center mb-3rem">Avance Movimientos</h3>
        <div className="text-left row flex-column pt-1rem5 pl-1rem mb-5">
          <p>Instrucciones:</p>
          <p className="m-0">
            <span className="text-primary">1.-</span> Selecciona tu tienda en la lista desplegable
          </p>
          <p className="m-0">
            <span className="text-primary">2.-</span> Elige un rango de fechas o el día en específico a consultar (El periodo máximo de consulta es 3 meses atrás)
          </p>
          <p className="m-0">
            <span className="text-primary">3.-</span> Da clic en buscar
          </p>
          <p className="m-0">
            <span className="text-primary">*</span> Podrás descargar la consulta en Excel
          </p>
          <p className="m-0">
            <span className="text-primary">*</span> La información está actualizada a la misma fecha del avance general
          </p>
        </div>
        <div className="justify-content-center">
          <div className="row justify-content-between">
            {
              // Si el usuario es administrador, mostramos filtros de tienda y tipo de usuario
               (
                <>
                  <div className="col">
                    <div className="form-label-group">
                      Tiendas:
                      <select
                        name="tipoUsuario"
                        className="form-control mb-rem5"
                        onChange={(e) => handleTienda(e)}
                      >
                        <option value="">Selecciona</option>
                        {tiendas &&
                          tiendas.map((t) => (
                            <option
                              value={t.numeroTienda}
                              key={t.numeroTienda}
                            >
                              {t.numeroTienda} - {t.nombreTienda}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-label-group">
                      Fecha Inicial:
                      <input
                        type='date'
                        name="cadenaComercial"
                        className="form-control mb-rem5"
                        onChange={(e) => handleFechaInicial(e)}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-label-group">
                      Fecha Final:
                      <input
                        type='date'
                        name="cadenaComercial"
                        className="form-control mb-rem5"
                        onChange={(e) => handleFechaFin(e)}
                      />
                    </div>
                  </div>
                </>
              )
            }
            <div className="text-center col">
              <button
                className="btn btn-primary my-1rem"
                onClick={() => onAnadir()}
              >
                Buscar
              </button>
            </div>
          </div>
          {
            // Verificamos si hay usuarios, de lo contrario, devolvemos un warning para dirigir el usuario a añadir usuarios
            movimientos.length > 0 && (
              <>
                <div className='row justify-content-center'>
                  <div className='co-4'>
                    <DownloadButton id={'movimientos'} />

                  </div>
                </div>
                <div className="text-center table-responsive scroll-x">
                  <div className="">
                    <table className="table table-striped table-hover" id='movimientos'>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Póliza</th>
                          <th scope="col">Fecha Movimiento</th>
                          <th scope="col">Número Empleado</th>
                          <th scope="col">Número Tienda</th>
                          <th scope="col">Tipo Movimiento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {movimientos.map((empleado, i) => {
                          return (
                            <tr key={i}>
                              <td>{empleado.poliza}</td>
                              <td>{empleado.fechaMovimiento}</td>
                              <td>{empleado.numeroEmpleado}</td>
                              <td>{empleado.numeroTienda}</td>
                              <td>{empleado.tipoMovimiento}</td>
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
    );

  // Editar usuario
};


export default IndexBuscar;
