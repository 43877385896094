import React, { useState, useEffect, useMemo } from "react";
import instance from "../../helpers/api.instance";
import { notification, normalizePercentage, closest } from "../../helpers/Helpers";
// Libraries
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
// Icons
import IconGold from "../../assets/icons/stars/gold.svg";
import IconSilver from "../../assets/icons/stars/silver.svg";
import IconBronze from "../../assets/icons/stars/bronze.svg";
import IconBefore from "../../assets/icons/backArrow.svg";
import IconNext from "../../assets/icons/nextArrow.svg";

const EditarMedallero = ({ setloading, userInfo }) => {
  // Useform para validar que todos los campos tengan un valor
  const { handleSubmit, register } = useForm();
  // Variable para obtener la fecha local
  const [date, setDate] = useState(DateTime.now());
  const [mes, setmes] = useState(date.c.month);
  const [anio, setanio] = useState(date.c.year);
  // Cadena comercial
  const [cadenaComercial, setCadenaComercial] = useState("1");
  // Información obtenida
  const [data, setData] = useState(0);
  const [datados, setDatados] = useState(0);
  // Memoización de la información obtenida
  const tablaData = useMemo(() => data, [data]);
  // Trigger para re-detonar la llamada a la api inicial
  const [trigger, settrigger] = useState(0);

  const handleDate = (op) => {
    if (op === "addition") {
      setDate(date.plus({ months: 1 }));
    } else {
      setDate(date.minus({ months: 1 }));
    }
  };

  const splitKeyValue = (obj) => {
    const keys = Object.keys(obj);
    const res = [];
    for (let i = 0; i < keys.length; i++) {
      res.push({
        numeroTienda: keys[i],
        estrella: parseInt(obj[keys[i]]),
      });
    }
    return res;
  };

  const onSubmit = (formData) => {
    const { idUsuario } = userInfo;
    const prev = splitKeyValue(formData).slice(0, data.length);
    console.log(prev);
    const estrellas = prev.map((dato, i) => {
      dato.ayuda = formData[`${i}Ayudado`];
      return dato;
    });




    setloading(true);
    instance
      .post(`/medallero/publicaEstrellas`, {
        cadenaComercial,
        mes,
        anio,
        idUsuario,
        estrellas,
      })
      .then((res) => {
        if (res.status === 1) {
          notification("Datos actualizados correctamente", "success");
          settrigger(trigger + 1);
        }
      })
      .finally(() => setloading(false));
  };

  useEffect(() => {
    setmes(date.c.month);
    setanio(date.c.year);
  }, [date]);

  useEffect(() => {
    setloading(true);
    instance
      .post(`/medallero/calculaEstrellasPrevio`, {
        cadenaComercial,
        mes: parseInt(mes),
        anio: parseInt(anio),
      })
      .then((res) => {
        if (res.status === 1) {
          setData(res.data);
        }
        if (res.status === 0) setData(0);
      })
      .finally(() => setloading(false));
  }, [cadenaComercial, mes, anio, trigger]);

  useEffect(() => {
    setloading(true);
    instance
      .post(`/medallero/medallasMes`, {
        cadenaComercial,
        mes: parseInt(mes),
        anio: parseInt(anio),
      })
      .then((res) => {
        if (res.status === 1) {
          setDatados(res.data);
        }
        if (res.status === 0) setDatados(0);
      })
      .finally(() => setloading(false));
  }, [cadenaComercial, mes, anio, trigger]);

  return (
    <div className="rounded card">
      <h4 className="text-center pb-2rem">Edita el medallero</h4>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-label-group">
              Línea de negocio:
              <select
                name="cadenaComercial"
                className="form-control mb-rem5"
                onChange={(e) => setCadenaComercial(e.target.value)}
              >
                <option value="" disabled>
                  Selecciona
                </option>
                <option value="1">Liverpool</option>
                <option value="2">Suburbia</option>
              </select>
            </div>
          </div>
          <div className="text-center col d-flex align-items-center justify-content-center">
            <img
              src={IconBefore}
              alt=""
              className="cursor-pointer card-hover-big"
              data-tip="Cambiar de categoría"
              onClick={() => handleDate("substract")}
            />
            <p className="m-0 px-1rem">
              {date
                .setLocale("es")
                .toLocaleString({ year: "numeric", month: "long" })}
            </p>
            <img
              src={IconNext}
              alt=""
              className="cursor-pointer card-hover-big"
              data-tip="Cambiar de categoría"
              onClick={() => handleDate("addition")}
            />
          </div>
          <div className="text-right col">
            <button className="btn btn-primary mt-1rem" type="submit">
              Guardar
            </button>
          </div>
        </div>
        {datados === 0 ? (
          <div className="alert alert-warning my-1rem" role="alert">
            Aún no hay medallas disponibles para este mes.
          </div>
        ) : (
          <div className='row justify-content-center my-3 mt-5'>
            <div className='col-md-8'>
              <div className="table-responsive scroll-x">
                <table className="table text-center mt-1rem table-sm table-bordered table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th rowSpan="2" className="align-middle px-1rem5 table-dark">
                        Estrella Oro
                      </th>
                      <th rowSpan="2" className="align-middle px-1rem5 table-dark">
                        Estrella Plata
                      </th>
                      <th rowSpan="2" className="align-middle px-1rem5 table-dark">
                        Estrella Bronce
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{datados.estrellasOro}</td>
                      <td>{datados.estrellasPlata}</td>
                      <td>{datados.estrellasBronce}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}


        {tablaData === 0 ? (
          <div className="alert alert-warning my-1rem" role="alert">
            Aún no hay medallas disponibles para este mes.
          </div>
        ) : (
          /* TABLA  */
          <div className="table-responsive scroll-x">
            <table className="table text-center mt-1rem table-sm table-bordered table-hover">
              <thead className="thead-dark">
                <tr>
                  <th rowSpan="2" className="align-middle px-1rem5 table-dark">
                    ID
                  </th>
                  <th rowSpan="2" className="align-middle table-dark">
                    Tienda
                  </th>
                  <th colSpan="4" scope="colgroup" className="even-title">
                    Reactivaciones
                  </th>
                  <th colSpan="4" scope="colgroup" className="table-dark">
                    Migraciones
                  </th>
                  <th colSpan="4" scope="colgroup" className="table-dark">
                    IPPF
                  </th>
                  {cadenaComercial === "2" && (
                    <th colSpan="2" scope="colgroup" className="even-title">
                      Rechazo inicial
                    </th>
                  )}
                  <th rowSpan="2" className="align-middle table-dark">
                    Estrella
                  </th>
                  <th rowSpan="2" className="align-middle table-dark">
                    Ayudado
                  </th>
                </tr>
                <tr>
                  {/* Reactivaciones */}
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconGold} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconSilver} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconBronze} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header even"
                  >
                    Cierre
                  </th>

                  {/* Migraciones */}
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconGold} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconSilver} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconBronze} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header even"
                  >
                    Cierre
                  </th>

                  {/* IPPF */}
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconGold} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconSilver} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header px-1rem even"
                  >
                    <img src={IconBronze} alt="Estrella dorada" />
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle sticky-header even"
                  >
                    Actual
                  </th>

                  {/* Rechazo inicial */}
                  {cadenaComercial === "2" && (
                    <React.Fragment>
                      <th
                        scope="col"
                        className="text-center align-middle sticky-header even"
                      >
                        Límite
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle sticky-header even"
                      >
                        Actual
                      </th>
                    </React.Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                {tablaData !== 0 &&
                  tablaData?.map((row, i) => {
                    const closestReact = closest(
                      [
                        row.meta.reactivacionesOro,
                        row.meta.reactivacionesPlata,
                        row.meta.reactivacionesBronce,
                      ],
                      parseFloat(row.avances.totalReactivaciones)
                    );
                    const closestMigrac = closest(
                      [
                        row.meta.migracionesOro,
                        row.meta.migracionesPlata,
                        row.meta.migracionesBronce,
                      ],
                      parseFloat(row.avances.totalMigraciones)
                    );
                    const closestIppf = closest(
                      [
                        row.meta.ippfOro,
                        row.meta.ippfPlata,
                        row.meta.ippfBronce,
                      ],
                      parseFloat(row.ippfFinal)
                    );

                    return (
                      <tr key={row.numeroTienda}>
                        {/* GENERAL  */}
                        <td>{row.numeroTienda}</td>
                        <td>{row.nombreTienda}</td>

                        {/* REACTIVACIONES  */}
                        <td
                          className={`even ${closestReact ===
                            parseFloat(row.meta.reactivacionesOro) &&
                            `fw-bold`
                            }`}
                        >
                          {row.meta.reactivacionesOro}
                        </td>
                        <td
                          className={`even ${closestReact ===
                            parseFloat(row.meta.reactivacionesPlata) &&
                            `fw-bold`
                            }`}
                        >
                          {row.meta.reactivacionesPlata}
                        </td>
                        <td
                          className={`even ${closestReact ===
                            parseFloat(row.meta.reactivacionesBronce) &&
                            `fw-bold`
                            }`}
                        >
                          {row.meta.reactivacionesBronce}
                        </td>
                        <td className="even">
                          {row.avances.totalReactivaciones}
                        </td>

                        {/* MIGRACIONES  */}
                        <td
                          className={`${closestMigrac ===
                            parseFloat(row.meta.migracionesOro) && `fw-bold`
                            }`}
                        >
                          {row.meta.migracionesOro}
                        </td>
                        <td
                          className={`${closestMigrac ===
                            parseFloat(row.meta.migracionesPlata) && `fw-bold`
                            }`}
                        >
                          {row.meta.migracionesPlata}
                        </td>
                        <td
                          className={`${closestMigrac ===
                            parseFloat(row.meta.migracionesBronce) &&
                            `fw-bold`
                            }`}
                        >
                          {row.meta.migracionesBronce}
                        </td>
                        <td>{row.avances.totalMigraciones}</td>

                        {/* IPPF  */}
                        <td
                          className={`even px-rem5 ${closestIppf === parseFloat(row.meta.ippfOro) &&
                            `fw-bold`
                            }`}
                        >
                          {normalizePercentage(row.meta.ippfOro)}
                        </td>
                        <td
                          className={`even px-rem5 ${closestIppf === parseFloat(row.meta.ippfPlata) &&
                            `fw-bold`
                            }`}
                        >
                          {normalizePercentage(row.meta.ippfPlata)}
                        </td>
                        <td
                          className={`even px-rem5 ${closestIppf === parseFloat(row.meta.ippfBronce) &&
                            `fw-bold`
                            }`}
                        >
                          {normalizePercentage(row.meta.ippfBronce)}
                        </td>
                        <td className="even px-rem5">
                          {normalizePercentage(row.ippfFinal)}
                        </td>

                        {/* RECHAZO INICIAL  */}
                        {cadenaComercial === "2" && (
                          <React.Fragment>
                            <td className="px-rem5">
                              {normalizePercentage(row.meta.rechazo)}
                            </td>
                            <td className="px-rem5">
                              {normalizePercentage(
                                row.avances.porcentajeRechazo
                              )}
                            </td>
                          </React.Fragment>
                        )}

                        {/* ESTRELLA GANADA  */}
                        <td className="even">
                          <div className="form-label-group">
                            <select
                              name={row.numeroTienda}
                              id="categoria"
                              className="form-control mb-rem5 mw-50"
                              ref={register({ required: true })}
                            >
                              <option
                                value={0}
                                selected={row.estrella === 0 ? true : false}
                              >
                                No gana
                              </option>
                              <option
                                value={1}
                                selected={row.estrella === 1 ? true : false}
                              >
                                Bronce
                              </option>
                              <option
                                value={2}
                                selected={row.estrella === 2 ? true : false}
                              >
                                Plata
                              </option>
                              <option
                                value={3}
                                selected={row.estrella === 3 ? true : false}
                              >
                                Oro
                              </option>
                            </select>
                          </div>
                        </td>

                        {/* FUE AYUDADO? */}
                        <td className="even">
                          <div className="form-label-group">
                            <select
                              name={`${i}Ayudado`}
                              id="categoria"
                              className="form-control mb-rem5 mw-50"
                              ref={register({ required: true })}
                            >
                              <option value={0}>No ayudada</option>
                              <option value={1}>Ayudada</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditarMedallero;
