import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const SuburbiaTheme = React.lazy(() => import("./themes/Suburbia"));
const LiverpoolTheme = React.lazy(() => import("./themes/Liverpool"));

export const ThemeContext = React.createContext();
export const ThemeUpdateContext = React.createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext);
}

export const ThemeSelector = ({ children }) => {
  // Cadena comercial que será usada en todo el sitio para determinar el origen del usuario
  const [globalCadenaComercial, setglobalCadenaComercial] = useState("1");

  return (
    <ThemeContext.Provider value={globalCadenaComercial}>
      <ThemeUpdateContext.Provider value={setglobalCadenaComercial}>
        <React.Suspense fallback={<></>}>
          {globalCadenaComercial === "1" && <LiverpoolTheme />}
          {globalCadenaComercial === "2" && <SuburbiaTheme />}
        </React.Suspense>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeSelector>
      <App />
    </ThemeSelector>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
