/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from "react";
// Libraries
import { Link, useHistory } from "react-router-dom";
import swal from "@sweetalert/with-react";
import ReactTooltip from "react-tooltip";
// Components
import Navbar from "react-bootstrap/Navbar";
// ICONS
import LogoLiverpool from "../../assets/logos/liverpool.svg";
import LogoSuburbia from "../../assets/logos/suburbia.svg";

const Header = ({ logged, setlogged, usuario, userInfo }) => {
  // Hook para acceder al historial de la app
  let history = useHistory();
  // Hook que esconde o muestre el menú de navegación móvil
  const [hideMenu, sethideMenu] = useState(true);

  // Obtenemos el nombre del local para mostrarlo en el header
  const nombre = localStorage.getItem("nombre");

  const handleLoggout = () => {
    if (!hideMenu) sethideMenu(true);
    swal({
      title: "¿Desea cerrar sesión?",
      text:
        "La sesión se perderá y será necesario volver a ingresas sus credenciales.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setlogged(false);
        localStorage.clear();
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    });
  };

  // Versión loggeada
  if (logged) {
    if (!usuario) return null;

    // console.log(usuario);
    return (
      <Fragment>
        <Navbar className="d-flex justify-content-between py-0 px-1rem5 w-100 bg-primary shadow-lg">
          <nav className="navbar">
            <Link to={`${process.env.PUBLIC_URL}/`} className="navbar-brand">
              <img
                src={
                  userInfo && userInfo.cadenaComercial === "2"
                    ? LogoSuburbia
                    : LogoLiverpool
                }
                alt="Logo de liverpool"
                className="img-fluid"
                width="150"
              />
            </Link>
          </nav>

          <nav className="d-none d-md-block">
            <ReactTooltip />
            <span
              className="nav-link text-white ml-1rem my-0 cursor-pointer"
              data-tip="Cerrar sesión"
              onClick={() => handleLoggout()}
            >
              Hola, {nombre}
            </span>
          </nav>
          {/* Todo: Crear componente para esto de abajo y replicarlo en el else de este if */}
          <div
            className="mobile-menu-icon d-md-none"
            onClick={() => sethideMenu(!hideMenu)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Navbar>
        <div className={`mobile-menu-container ${hideMenu ? `hide-menu` : ``}`}>
          <div className="mobile-menu-wrapper bg-lead">
            <div className="d-flex flex-nowrap w-100 justify-content-between">
              <h4 className="text-white pb-3rem">Hola, {nombre}</h4>
              <h4
                className="justify-content-right text-white cursor-pointer card-hover"
                onClick={() => sethideMenu(!hideMenu)}
              >
                X
              </h4>
            </div>
            {usuario.puede.entrarA.home && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/home`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">Home</h5>
                </div>
              </Link>
            )}
            {usuario.puede.entrarA.estadisticas && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/estadisticas`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">Avances</h5>
                </div>
              </Link>
            )}
            {usuario.puede.entrarA.medallero && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/medallero`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">Medallero</h5>
                </div>
              </Link>
            )}
            {usuario.puede.entrarA.administracionDeMetas && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/administracion-de-metas`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">
                    Administración de metas
                  </h5>
                </div>
              </Link>
            )}
            {usuario.puede.entrarA.usuarios && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/usuarios`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">
                    Administración de usuarios
                  </h5>
                </div>
              </Link>
            )}
            {usuario.puede.entrarA.cargaDeArchivos && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/carga-de-metas`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">Carga de archivos</h5>
                </div>
              </Link>
            )}
            {usuario.puede.entrarA.perfil && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/perfil`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">Perfil</h5>
                </div>
              </Link>
            )}
            {userInfo && userInfo.cadenaComercial === "1" && (
              <Link
                className="nav-link-item card-hover row align-items-center"
                to={`${process.env.PUBLIC_URL}/busqueda`}
                onClick={() => sethideMenu(!hideMenu)}
              >
                <div className="col">
                  <h5 className="text-white fw-light">Busqueda</h5>
                </div>
              </Link>
            )}
            <div
              className="nav-link-item card-hover row align-items-center cursor-pointer"
              onClick={() => handleLoggout()}
            >
              <ReactTooltip />
              <div className="col mt-3rem">
                <h5 className="text-white fw-light">Cerrar sesión</h5>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  // Versión sin loggear
  else {
    return null;
  }
};

export default Header;
