import React from "react";

const AlertForm = ({ texto }) => {
  return (
    <p
      style={{ fontSize: "0.75rem" }}
      className="text-danger text-xs position-absolute mt-nrem5"
    >
      {texto || "Este campo es requerido."}
    </p>
  );
};

export default AlertForm;
