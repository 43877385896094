// Libraries
import React, { useEffect, useState, useMemo } from "react";
import instance from "../../helpers/api.instance";
// Components
import TablaTiendas from "./tablas/TablaTiendas";
import DataTable from "../../components/general/DataTable";
// Icons
import IconAdminGoals from "../../assets/icons/admin_goals_black.svg";

const ListaTiendas = ({
  cadenaComercial,
  setcadenaComercial,
  setview,
  setIdTienda,
  setloading,
  usuario,
  userInfo,
  vista,
  setNombreTienda
}) => {
  //Tiendas que se pueden cargar metas
  const [tiendas, settiendas] = useState(null);

  // Lista de tiendas disponibles para asignar metas
  useEffect(() => {
    setloading(true);
    // Se cargan la lista de tiendas de liverpool por default
    instance
      .post(
        `/metas/listadoTiendasMetas`,
        // Cuerpo del request
        {
          cadenaComercial: cadenaComercial,
        }
      )
      .then((res) => {
        if (res.status === 1) settiendas(res.data.tiendas);
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  }, [cadenaComercial]);

  // Memos para la tabla
  const columns = useMemo(
    () => [
      { Header: "Zona", accessor: "zona" },
      { Header: "Número de tienda", accessor: "numeroTienda" },
      { Header: "Tienda", accessor: "nombreTienda" },
    ],
    []
  );
  const data = useMemo(() => tiendas, [tiendas]);

  // En caso de que aún no se carguen las tablas, se regresa null
  if (!tiendas) return null;

  return (
    <div className="row">
      <div className="col-2"></div>
      <div className="col">
        <div className="card">
          <div className="text-center py-1rem">
            <img
              src={IconAdminGoals}
              alt="ícono administración de metas"
              className="main-icon text-black"
            />
            <h5 className="pt-1rem">Administración de metas</h5>
            <h6>Elige la tienda cuyas metas quieres administrar</h6>
          </div>
          {usuario.puede.ver.administracionDeMetas.todos && (
            <div className="row">
              <div className="col-3 d-flex align-items-center">
                <p>Línea de negocio:</p>
              </div>
              <div className="col-3 form-label-group">
                <select
                  name="cadenaComercial"
                  id="cadenaComercial"
                  className="form-control"
                  onChange={(e) => setcadenaComercial(e.target.value)}
                >
                  <option
                    value="1"
                    selected={userInfo.cadenaComercial === 1 ? true : false}
                  >
                    Liverpool
                  </option>
                  <option
                    value="2"
                    selected={userInfo.cadenaComercial === 2 ? true : false}
                  >
                    Suburbia
                  </option>
                </select>
              </div>
            </div>
          )}
          {data && (
            <TablaTiendas
              data={data}
              columns={columns}
              setview={setview}
              setIdTienda={setIdTienda}
              vista={vista}
              setNombreTienda={setNombreTienda}
            />
          )}
        </div>
      </div>
      <div className="col-2"></div>
    </div>
  );
};

export default ListaTiendas;
