// Libraries
import React from "react";
import { Link } from "react-router-dom";
// Images
import ImgBanner from "../../assets/images/banner.svg";
import ImgPifLiverpool from "../../assets/images/logo_pif_liverpool.png";
import ImgPifSuburbia from "../../assets/images/logo_pif_suburbia.png";
import ImgLogoMedallero from "../../assets/images/LPLCM.png";
import ImgLogoMedalleroSuburbia from "../../assets/images/SBBCM.png";

const Home = () => {
  return (
    <div>
      <img
        src={ImgBanner}
        alt="Banner de gente trabajando"
        className="d-none d-lg-block img-fluid w-100"
      />
      <div className="p-1rem">
        <div className="card rounded">
          <div className="row text-center">
            <div className="col-sm justify-content-center">
              <img
                src={ImgPifLiverpool}
                alt="Logo Pif Liverpool"
                className="img-fluid"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <button className="btn btn-primary d-block mx-auto">
                  Entrar a Liverpool
                </button>
              </Link>
              <img
                src={ImgLogoMedallero}
                alt="Logo Medallero"
                className="img-fluid pt-3rem"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
            </div>
            <div className="col-sm pt-0 pt-sm-1rem5">
              <h3 className="fw-bold">Bienvenido</h3>
              <h4 className="pt-1rem">A la plataforma PIF</h4>
              <p>
                Aquí podrás consultar tus metas, resultados y medallas ganadas.
              </p>
            </div>
            <div className="col-sm pt-1rem">
              <img
                src={ImgPifSuburbia}
                alt="Logo Pif Suburbia"
                className="img-fluid"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <button className="btn btn-suburbia d-block mx-auto mt-rem5">
                  Entrar a Suburbia
                </button>
              </Link>
              <img
                src={ImgLogoMedalleroSuburbia}
                alt="Logo Medallero"
                className="img-fluid pt-3rem"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
