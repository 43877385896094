// Libraries
import React, { useEffect, useMemo, useState } from "react";
import instance from "../../../helpers/api.instance";
// Helpers
import { colorSemaphore, normalizePercentage, colorSemaphoreAvance, formatoMiles } from "../../../helpers/Helpers";
// Boton para exportar tabla a excel
import DownloadButton from '../../../components/general/DownloadButton';

import { ModalHistorico } from './ModalHistorico';
import { ModalHistoricoEmpleado } from './ModalHistoricoEmpleado';
import ReporteTiendasAll from "../../../components/reportes/ReporteTiendasAll";
import ReporteEmpleadosAll from "../../../components/reportes/ReporteEmpleadosAll";

const DataTable = (props) => {

  const {
    setloading,
    view,
    zona,
    numeroEmpleado,
    numeroTienda,
    usuario,
    cadenaComercial,
    handleGlobal,
    handleZona,
    handleTienda,
    setTitleFecha,
    mes,
    anio,
    meta,
    userInfo
    //dateNow,
  } = props;

  // Data de la tabla
  const [avances, setavances] = useState(null);
  const [mesActual, setMesActual] = useState(null);
  const [subTotal, setSubtotal] = useState(null);
  const [tiendaNombre, setTiendaNombre] = useState('');
  const [avanceIdeal, setAvanceIdeal] = useState(null);
  const [nombreEmpleado, setNombreEmpleado] = useState(null);

  const tienePermisoTiendasAll = userInfo?.permisos?.includes("reporte.avances.tiendas.all");
  const tienePermisoEmpleadosAll = userInfo?.permisos?.includes("reporte.avances.colaboradores.all");

  // UseEffect principal que cambia la llamada axios dependiendo de la vista
  useEffect(() => {
    fetchGlobal();
    const mesactual = new Date();
    setMesActual(mesactual.getMonth() + 1);
  }, [view, cadenaComercial, mes, anio, meta]);

  // Función fetch que hace la llamada
  function fetchGlobal() {
    setloading(true);
    setavances(null);
    if (mes && anio) {
      if (view === 1) {
        // Request para obtener gráfica
        instance
          .post(
            "/avances/globalZonasTabla",
            // Body
            { cadenaComercial, mes, anio, meta }
          )
          .then((res) => {
            // Guardamos los avances en un hook para enviarlo al memo de la tabla
            if (res.status === 1) {
              setavances(res.data.avances);
              setSubtotal(res.data.subTotal);
              setTitleFecha(res.data.fechaActualizacion);
              setAvanceIdeal(res.data.porcentajeIdeal);
            }
            if (res.status === 0) setavances(0);
          })
          .finally(() => setloading(false));
      } else if (view === 2) {
        // Request para obtener gráfica
        instance
          .post(
            "/avances/globalPorZonasTabla",
            // Body
            { cadenaComercial, zona, mes, anio, meta }
          )
          .then((res) => {
            // Guardamos los avances en un hook para enviarlo al memo de la tabla
            if (res.status === 1) {
              setavances(res.data.avances);
              setSubtotal(res.data.subTotal);
              setTitleFecha(res.data.fechaActualizacion);
              setAvanceIdeal(res.data.porcentajeIdeal);
            }
            if (res.status === 0) setavances(0);
          })
          .finally(() => setloading(false));
      } else if (view === 3) {
        // Request para obtener gráfica
        instance
          .post(
            "/avances/globalPorTiendasTabla",
            // Body
            { cadenaComercial, numeroTienda, mes, anio, meta }
          )
          .then((res) => {
            // Guardamos los avances en un hook para enviarlo al memo de la tabla
            if (res.status === 1) {
              setavances(res.data.avances);
              setSubtotal(res.data.subTotal);
              setTitleFecha(res.data.fechaActualizacion);
              setAvanceIdeal(res.data.porcentajeIdeal);
            }
            if (res.status === 0) setavances(0);
          })
          .finally(() => setloading(false));
      } else if (view === 4) {
        // Request para pbtener la tabla de un asesor
        instance
          .post(
            `/avances/globalPorEmpleado`,
            // Cuerpo del request
            {
              cadenaComercial,
              numeroTienda,
              numeroEmpleado,
              mes,
              anio,
              meta
            }
          )
          .then((res) => {
            if (res.status === 1) {
              setavances(res.data.avances);
              setSubtotal(res.data.subTotal);
              setTitleFecha(res.data.fechaActualizacion);
              setAvanceIdeal(res.data.porcentajeIdeal);
            }
            if (res.status === 0) setavances(0);
          })
          // Apagamos el loader
          .finally(() => setloading(false));
      }
    }
  }

  let suma1 = 0;
  let suma2 = 0;
  let suma3 = 0;
  let suma4 = 0;
  let suma5 = 0;
  let suma6 = 0;
  let suma7 = 0;
  let suma8 = 0;
  let suma9 = 0;
  let suma10 = 0;
  let suma11 = 0;
  let suma12 = 0;
  let suma13 = 0;
  let suma14 = 0;
  let suma15 = 0;
  let suma16 = 0;
  let suma17 = 0;
  let suma18 = 0;
  let suma19 = 0;
  let suma20 = 0;
  let suma21 = 0;
  let suma22 = 0;
  let suma23 = 0;
  let suma24 = 0;
  let sumaCN = 0;
  let sumaCNCP = 0;
  let sumaCNCPSF = 0;
  let sumaCNTotal = 0;
  let sumaCNCPTotal = 0;
  let sumaCNCPSFTotal = 0;
  let sumaTotalRechazado = 0;
  let porcentajeRetencion = 0;

  // Hook para memoizar lo que se obtenga de la api
  const data = useMemo(() => avances, [avances]);

  if (data === 0) return <Warning />;

  if (!data) return null;

  switch (view) {
    // Zonas
    case 1:
      const nameCase1 = "ranking-nacional";
      return (
        <>
          <DownloadButton id={nameCase1} />
          <table id={nameCase1} className="table table-hover table-sm">
            <thead>
              <tr>
                <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                  Rank
                </th>
                <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                  Zona
                </th>
                <th colSpan="7" scope="colgroup" className="even-title">
                  Reactivaciones
                </th>
                <th colSpan="7" scope="colgroup" className="table-dark">
                  Migraciones
                </th>
                <th
                  colSpan={cadenaComercial === '1' ? '9' : '2'}
                  scope="colgroup" className="even-title">
                  Rechazo inicial
                </th>
                <th colSpan="1" scope="colgroup" rowSpan='0' className="text-sm align-middle even-title px-1rem">
                  % DE RETENCIÓN
                </th>
                <th colSpan="3" scope="colgroup" className="table-dark">
                  IPPF
                </th>
              </tr>

              <tr>
                {/* Reactivaciones */}
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Meta Global<br />(C + CS)
                </th>
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Avance Global<br />(C + CS)
                </th>
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Avance <br />Crédito
                </th>
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Meta CS
                </th>
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Avance <br /> CS
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Faltan
                </th>
                <th
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  scope="col"
                  className="text-sm align-middle sticky-header even"
                >
                  % avance
                </th>

                {/* Migraciones */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Meta Global <br /> (C + CS)
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Avance Global <br /> (C + CS)
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Avance <br />Crédito
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Meta CS
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Avance <br /> CS
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Faltan
                </th>
                <th
                  scope="col"
                  className="text-sm align-middle sticky-header odd"
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                >
                  % avance
                </th>

                {/* Rechazo inicial */}
                {cadenaComercial === '1' &&
                  <>
                    <th colSpan="2" scope="col" className="align-middle sticky-header even">
                      CN
                    </th>
                    <th colSpan="2" scope="col" className="align-middle sticky-header even">
                      CN CP
                    </th>
                    <th colSpan="2" scope="col" className="align-middle sticky-header even">
                      CN CP <br />SF
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                      Cuentas totales <br />rechazadas
                    </th>
                  </>
                }
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Anterior
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Actual
                </th>
                {/* IPPF */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Meta
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  {(mes === mesActual) ? 'Al mes anterior' : 'Al cierre de mes'}
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Faltante
                </th>
              </tr>
              {cadenaComercial === '1' &&
                <tr>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                </tr>
              }
            </thead>
            <colgroup>
              <col span="2" />
              <col span="6" className="even" />
              <col span="6" />
              <col span="2" className="even" />
            </colgroup>
            <tbody className="table-bordered">
              {data.map((row, i) => {
                // Reactivaciones
                suma1 = suma1 + parseFloat(row.metaReactivacion);
                suma2 = suma2 + parseFloat(row.avanceReactivaciones);
                suma3 = suma3 + parseFloat(row.avanceCreditoReactivacion);
                suma4 = suma4 + parseFloat(row.avanceCssReactivacion);
                suma5 = suma5 + parseFloat(row.faltanReactivaciones);
                suma11 = suma11 + parseFloat(row.metaReactivacionCs);
                // Migraciones
                suma6 = suma6 + parseFloat(row.metaMigracion);
                suma7 = suma7 + parseFloat(row.avanceMigraciones);
                suma8 = suma8 + parseFloat(row.avanceCreditoMigracion);
                suma9 = suma9 + parseFloat(row.avanceCssMigracion);
                suma10 = suma10 + parseFloat(row.faltanMigraciones);
                suma12 = suma12 + parseFloat(row.metaMigracionCs);
                sumaCNTotal = sumaCNTotal + parseFloat(row.rechazoTotalCN);
                sumaCNCPTotal = sumaCNCPTotal + parseFloat(row.rechazoTotalCNCP);
                sumaCNCPSFTotal = sumaCNCPSFTotal + parseFloat(row.rechazoTotalCNCPSF);
                sumaTotalRechazado = sumaTotalRechazado + parseFloat(row.totalCuentasRechazo);
                porcentajeRetencion = porcentajeRetencion + parseFloat(row.promedioRetencion);
                return (
                  <tr
                    key={i}
                    className="cursor-pointer"
                    onClick={() => {
                      handleGlobal(row.zona, row.zona);
                    }}
                  >
                    {/* General */}
                    <td className="sticky-col">{row.rank}</td>
                    <td>{row.zona}</td>

                    {/* Reactivación */}
                    <td>{row.metaReactivacion}</td>
                    <td>{row.avanceReactivaciones}</td>
                    <td>{row.avanceCreditoReactivacion}</td>
                    <td>{row.metaReactivacionCs}</td>
                    <td>{row.avanceCssReactivacion}</td>
                    <td>{row.faltanReactivaciones}</td>
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeReactivacion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeReactivacion)}
                    </td>

                    {/* Migraciones */}
                    <td>{row.metaMigracion}</td>
                    <td>{row.avanceMigraciones}</td>
                    <td>{row.avanceCreditoMigracion}</td>
                    <td>{row.metaMigracionCs}</td>
                    <td>{row.avanceCssMigracion}</td>
                    <td className="odd">{row.faltanMigraciones}</td>
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeMigracion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeMigracion)}
                    </td>

                    {/* Rechazo incial */}
                    {
                      cadenaComercial === '1' &&
                      <>

                        <td>{row.rechazoTotalCN}</td>
                        <td>{normalizePercentage(row.rechazoInicialCN)}</td>
                        <td>{row.rechazoTotalCNCP}</td>
                        <td>{normalizePercentage(row.rechazoInicialCNCP)}</td>
                        <td>{row.rechazoTotalCNCPSF}</td>
                        <td>{normalizePercentage(row.rechazoInicialCNCPSF)}</td>
                        <td>{row.totalCuentasRechazo}</td>
                      </>
                    }
                    <td>{normalizePercentage(row.rechazoInicalAnterior)}</td>
                    <td
                      className={`bg-${colorSemaphore(
                        "rechazo",
                        row.rechazoInicalActual,
                        cadenaComercial
                      )}`}
                    >
                      {normalizePercentage(row.rechazoInicalActual)}
                    </td>

                    {/* % DE RETENCIÓN */}
                    <td>{normalizePercentage(row.promedioRetencion)}</td>

                    {/* IPPF */}
                    <td>{normalizePercentage(row.ippfMeta)}</td>
                    <td style={{ backgroundColor: row.ippfAnterior >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>
                      {normalizePercentage(row.ippfAnterior)}
                    </td>
                    <td
                      className={`bg-${colorSemaphore(
                        "ippf",
                        row.ippfFaltante,
                        cadenaComercial
                      )}`}
                    >
                      {normalizePercentage(row.ippfFaltante)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tr className="bg-white border-0">
              <td></td>
              <td></td>
              <td className="fw-bold">{formatoMiles(suma1)}</td>
              <td className="fw-bold">{formatoMiles(suma2)}</td>
              <td className="fw-bold">{formatoMiles(suma3)}</td>
              <td className="fw-bold">{formatoMiles(suma11)}</td>
              <td className="fw-bold">{formatoMiles(suma4)}</td>
              <td className="fw-bold">{formatoMiles(suma5)}</td>
              <td></td>
              <td className="fw-bold">{formatoMiles(suma6)}</td>
              <td className="fw-bold">{formatoMiles(suma7)}</td>
              <td className="fw-bold">{formatoMiles(suma8)}</td>
              <td className="fw-bold">{formatoMiles(suma12)}</td>
              <td className="fw-bold">{formatoMiles(suma9)}</td>
              <td className="fw-bold">{formatoMiles(suma10)}</td>
              <td></td>
              {cadenaComercial === '1' && <>
              <td className="fw-bold">{formatoMiles(sumaCNTotal)}</td>
              <td></td>
              <td className="fw-bold">{formatoMiles(sumaCNCPTotal)}</td>
              <td></td>
              <td className="fw-bold">{formatoMiles(sumaCNCPSFTotal)}</td>
              <td></td>
              <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
              <td></td>
              <td></td>
              <td></td>
              </>
              }
            </tr>
          </table>
        </>
      );

    // Zona
    case 2:
      const nameCase2 = "ranking-zona";
      return (
        <>
          <DownloadButton 
          id={nameCase2} 
          buttonText="Descargar tiendas de zona" 
          />
          {/* DESCARGA UN REPORTE GENERAL DE TIENDAS  */}
          {tienePermisoTiendasAll && (
            <div>
              <ReporteTiendasAll
                cadenaComercial={cadenaComercial} 
                meta={meta} 
                mes={mes} 
                anio={anio} 
                tipo="tiendas" 
                setloading={setloading} 
              />
            </div>
          )}
          <table id={nameCase2} className="table table-hover table-sm">
            <thead>
              <tr>
                {cadenaComercial === '1' &&
                  <>
                    <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                      Rank
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                      ID
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark sticky-col">
                      Tienda
                    </th>
                  </>
                }

                {cadenaComercial === '2' &&
                  <>
                    <th rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle table-dark">
                      Rank
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle table-dark">
                      ID
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle table-dark sticky-col">
                      Tienda
                    </th>
                  </>
                }
                
                <th
                  colSpan={usuario.puede.ver.estadisticas.otros ? "7" : "1"}
                  scope="colgroup"
                  className="even-title"
                >
                  Reactivaciones
                </th>
                <th
                  colSpan={usuario.puede.ver.estadisticas.otros ? "7" : "1"}
                  scope="colgroup"
                  className="table-dark"
                >
                  Migraciones
                </th>
                {cadenaComercial === '2' &&
                  <th colSpan="6" scope="colgroup" className="even-title">
                    Cuentas nuevas
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th colSpan={cadenaComercial === '1' ? '13' : '2'} scope="colgroup" className="even-title">
                    Cuentas nuevas
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th
                    scope="col"
                    className="text-sm align-middle even-title px-1rem"
                    rowSpan="0"
                    >
                      % DE RETENCIÓN
                  </th>
                }
                {cadenaComercial === '2' &&
                  <th
                    scope="col"
                    className="text-sm align-middle even-title px-1rem"
                    rowSpan="0"
                    >
                      % DE RETENCIÓN
                  </th>
                }
                <th
                  colSpan={cadenaComercial === '1' ? '10' : '2'}
                  scope="colgroup" className="table-dark">
                  Rechazo inicial
                </th>
                {cadenaComercial === '2' &&
                  <th
                  scope="col"
                  className="text-sm align-middle even-title px-1rem"
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  >
                    {(data[0].banderaEstrella === 1) ? '¿Ganaste estrella este mes?' : '¿Si hoy fuera cierre de mes, ganarías estrella?'}
                    {/* {mes === mesNow && anio === anioNow
                    ? "Si hoy fuera cierre de mes, ganarías estrella de:"
                    : "En este mes, ganaste estrella de:"} */}
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th
                  scope="col"
                  className="text-sm align-middle even-title px-1rem"
                  rowSpan={cadenaComercial === '1' ? '3' : '2'}
                  >
                    {(data[0].banderaEstrella === 1) ? '¿Ganaste estrella este mes?' : '¿Si hoy fuera cierre de mes, ganarías estrella?'}
                  </th>
                }
                {cadenaComercial === '2' &&
                  <th colSpan="4" scope="colgroup" className="table-dark">
                    IPPF
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th colSpan="4" scope="colgroup" className="table-dark">
                    IPPF
                  </th>
                }
              </tr>

              <tr className="table-bordered">
                {/* Reactivaciones */}
                {usuario.puede.ver.estadisticas.otros && (
                  <>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">

                      Meta Global <br />(C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                      Avance Global <br />(C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                      Avance <br />Crédito
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                      Meta CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                      Avance <br />CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                      Faltan
                    </th>
                  </>
                )}
                <th
                  scope="col"
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  className="text-sm align-middle sticky-header even"
                >
                  % avance
                </th>

                {/* Migraciones */}
                {usuario.puede.ver.estadisticas.otros && (
                  <>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                      Meta Global <br />(C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                      Avance Global <br /> (C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                      Avance<br /> Crédito
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                      Meta CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                      Avance CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                      Faltan
                    </th>
                  </>
                )}

                <th
                  scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  className="text-sm align-middle sticky-header odd"
                >
                  % avance
                </th>
                {cadenaComercial === '2' &&
                  <>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      Total CN
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      # Básico con <br />
                      {'LC > 5000'}
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      % Básico con <br />
                      {'LC > 5000'}
                    </th>
                  </>
                }
                {cadenaComercial === '1' &&
                  <>
                    <th colSpan="3" scope="col" className="align-middle sticky-header even">
                      CN
                    </th>
                    <th colSpan="3" scope="col" className="align-middle sticky-header even">
                      CN CP
                    </th>
                    <th colSpan="3" scope="col" className="align-middle sticky-header even">
                      CN CP <br />SF
                    </th>
                    <th colSpan="3" scope="col" rowSpan='1' className="align-middle sticky-header even">
                      CN <br />total
                    </th>
                  </>
                }
                {cadenaComercial === '2' &&
                  <th scope="col" className="align-middle sticky-header even" rowSpan={cadenaComercial === '1' ? '2' : '0'}>
                    Cuentas canceladas <br /> en el mismo mes
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th scope="col" rowspan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Cuentas totales canceladas <br /> en el mismo mes
                  </th>
                }
                {/* Rechazo inicial */}
                {cadenaComercial === '1' &&
                  <>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN
                    </th>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN CP
                    </th>
                    <th
                      colspan='2'
                      scope="col"
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN CP <br /> SF
                    </th>
                  </ >
                }
                <th scope="col" rowspan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  %<br />cancelación
                </th>
                <th scope="col" rowspan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Cuentas totales<br />rechazadas
                </th>

                <th
                  scope="col"
                  className="align-middle sticky-header even px-1rem"
                  rowspan={cadenaComercial === '1' ? '2' : '0'}
                >
                  Anterior
                </th>
                <th
                  scope="col"
                  className="align-middle sticky-header even px-1rem"
                  rowspan={cadenaComercial === '1' ? '2' : '0'}
                >
                  Actual
                </th>
                {/* IPPF */}
                <th scope="col" rowspan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Meta
                </th>
                <th scope="col" rowspan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  {(mes === mesActual) ? 'Al mes anterior' : 'Al cierre de mes'}
                </th>
                <th scope="col" rowspan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Faltante
                </th>
              </tr>
              {cadenaComercial === '1' &&
                <tr>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                </tr>
              }
            </thead>

            <colgroup>
              <col span="4" />
              <col
                span={usuario.puede.ver.estadisticas.otros ? "6" : "1"}
                className="even"
              />
              <col span={usuario.puede.ver.estadisticas.otros ? "6" : "1"} />
              <col span="5" className="even" />
              <col span="1" />
              <col span="4" className="even" />
            </colgroup>

            <tbody className="table-bordered">
              {data.map((row, i) => {
                // Reactivaciones
                suma1 = suma1 + parseFloat(row.metaReactivacion);
                suma2 = suma2 + parseFloat(row.avanceReactivacion);
                suma3 = suma3 + parseFloat(row.avanceReactivacionesAsesorCredito);
                suma4 = suma4 + parseFloat(row.avanceReactivacionesAsesorCss);
                suma5 = suma5 + parseFloat(row.faltanReactivaciones);
                suma13 = suma13 + parseFloat(row.metaReactivacionCs);
                // Migraciones
                suma6 = suma6 + parseFloat(row.metaMigracion);
                suma7 = suma7 + parseFloat(row.avanceMigracion);
                suma8 = suma8 + parseFloat(row.avanceMigracionesAsesorCredito);
                suma9 = suma9 + parseFloat(row.avanceMigracionesAsesorCss);
                suma10 = suma10 + parseFloat(row.faltanteMigraciones);
                suma14 = suma14 + parseFloat(row.metaMigracionCs);
                
                // Cuentas nuevas
                // Cuentas nuevas CN
                const cnTotal = parseFloat(row.cuentasNuevas?.cn.total);
                const cnBasicoNumero = parseFloat(row.cuentasNuevas?.cn.totalBasico);
                const cnBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cn.totalBasicoPorcentaje);
                sumaCN = sumaCN + parseFloat(row.cuentasNuevas?.cn.total);
                suma15 = suma15 + parseFloat(row.cuentasNuevas?.cn.totalBasico);
                suma20 = parseFloat([(suma15 * 100) / sumaCN]);

                // Cuentas nuevas CNCP
                const cncpTotal = parseFloat(row.cuentasNuevas?.cncp.total);
                const cncpBasicoNumero = parseFloat(row.cuentasNuevas?.cncp.totalBasico);
                const cncpBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncp.totalBasicoPorcentaje);
                sumaCNCP = sumaCNCP + parseFloat(row.cuentasNuevas?.cncp.total);
                suma16 = suma16 + parseFloat(row.cuentasNuevas?.cncp.totalBasico);
                suma21 = parseFloat([(suma16 * 100) / sumaCNCP]);

                // Cuentas nuevas CNCPSF
                const cncpsfTotal = parseFloat(row.cuentasNuevas?.cncpsf.total);
                const cncpsfBasicoNumero = parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
                const cncpsfBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncpsf.totalBasicoPorcentaje);
                sumaCNCPSF = sumaCNCPSF + parseFloat(row.cuentasNuevas?.cncpsf.total);
                suma17 = suma17 + parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
                suma22 = parseFloat([(suma17 * 100) / sumaCNCPSF]);

                // CN Total
                const cnTotalTotal = parseFloat(row.cuentasNuevas?.total);
                const cnTotalBasicoNumero = parseFloat(row.cuentasNuevas?.totalBasico);
                const cnTotalBasicoPorcentaje = parseFloat(row.cuentasNuevas?.totalBasicoPorcentaje);
                sumaCNTotal = sumaCNTotal + parseFloat(row.cuentasNuevas?.total);
                suma18 = suma18 + parseFloat(row.cuentasNuevas?.totalBasico);
                suma23 = parseFloat([(suma18 * 100) / sumaCNTotal]);

                // Cuentas nuevas Tlc
                const cncpTotalTlc = parseFloat(row.cuentasNuevas?.tlc.total);
                const cncpNumeroTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasico);
                const cncpPorcentajeTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasicoPorcentaje);
                suma11 = suma11 + parseFloat(row.cuentasNuevas?.tlc.total);
                suma19 = suma19 + parseFloat(row.cuentasNuevas?.tlc.totalBasico);
                suma24 = parseFloat([(suma19 * 100) / suma11]);

                // Cuentas Canceladas
                suma12 = suma12 + parseFloat(row.canceladas);
                sumaTotalRechazado = sumaTotalRechazado + parseFloat(row.totalCuentasRechazo);
                sumaCNCPTotal = sumaCNCPTotal + parseFloat(row.rechazoTotalCNCP);
                sumaCNCPSFTotal = sumaCNCPSFTotal + parseFloat(row.rechazoTotalCNCPSF);
                porcentajeRetencion = porcentajeRetencion + parseFloat(row.promedioRetencion);

                return (
                  <tr
                    key={i}
                    className="cursor-pointer"
                    onClick={() => {
                      handleZona(row.id, row.tienda);
                      setTiendaNombre(row.tienda)
                    }}
                  >
                    {/* General */}
                    <td>{row.rank}</td>
                    <td>{row.id}</td>
                    <td className="sticky-col">{row.tienda}</td>

                    {/* Reactivación */}
                    {usuario.puede.ver.estadisticas.otros && (
                      <>
                        <td className="even">{row.metaReactivacion}</td>
                        <td>{row.avanceReactivacion}</td>
                        <td>{row.avanceReactivacionesAsesorCredito}</td>
                        <td>{row.metaReactivacionCs}</td>
                        <td>{row.avanceReactivacionesAsesorCss}</td>
                        <td>{row.faltanReactivaciones}</td>
                      </>
                    )}
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeReactivacion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeReactivacion)}
                    </td>

                    {/* Migraciones */}
                    {usuario.puede.ver.estadisticas.otros && (
                      <>
                        <td>{row.metaMigracion}</td>
                        <td>{row.avanceMigracion}</td>
                        <td>{row.avanceMigracionesAsesorCredito}</td>
                        <td>{row.metaMigracionCs}</td>
                        <td>{row.avanceMigracionesAsesorCss}</td>
                        <td>{row.faltanteMigraciones}</td>
                      </>
                    )}
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeMigracion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeMigracion)}
                    </td>
                    {cadenaComercial === '2' &&
                      <>
                        <td  className="align-middle text-md even">{cncpTotalTlc}</td>
                        <td  className="align-middle text-md even">{cncpNumeroTlc}</td>
                        <td style={{ backgroundColor: cncpPorcentajeTlc <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}  className="align-middle text-md even">{normalizePercentage(cncpPorcentajeTlc)}</td>
                      </>
                    }
                    {/* Cuentas nuevas CN, CNCP, CNCPSF, CNTotal */}
                    {cadenaComercial === '1' &&
                      <>
                        {/* Total */}
                        <td>{cnTotal}</td>
                        {/* Básico # */}
                        <td>{cnBasicoNumero}</td>
                        {/* Básico % */}
                        <td style={{ backgroundColor: cnBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnBasicoPorcentaje)}</td>
                        {/* Total */}
                        <td>{cncpTotal}</td>
                        {/* Básico # */}
                        <td className="even">{cncpBasicoNumero}</td>
                        {/* Básico % */}
                        <td style={{ backgroundColor: cncpBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpBasicoPorcentaje)}</td>
                        {/* Total */}
                        <td>{cncpsfTotal}</td>
                        {/* Básico # */}
                        <td>{cncpsfBasicoNumero}</td>
                        {/* Básico % */}
                        <td style={{ backgroundColor: cncpsfBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpsfBasicoPorcentaje)}</td>
                        {/* CNTotal */}
                        <td className="even">{cnTotalTotal}</td>
                        <td className="even">{cnTotalBasicoNumero}</td>
                        <td style={{ backgroundColor: cnTotalBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }} className="even">{normalizePercentage(cnTotalBasicoPorcentaje)}</td>
                      </>
                    }
                    <td className="even">{row.canceladas}</td>
                    {cadenaComercial === '1' &&
                      <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                    }
                    {/* Rechazo incial */}
                    {cadenaComercial === '1' &&
                      <>
                        <td>{row.rechazoTotalCN}</td>
                        <td>{normalizePercentage(row.rechazoInicialCN)}</td>
                        <td>{row.rechazoTotalCNCP}</td>
                        <td>{normalizePercentage(row.rechazoInicialCP)}</td>
                        <td>{row.rechazoTotalCNCPSF}</td>
                        <td>{normalizePercentage(row.rechazoInicialCPSF)}</td>
                      </>
                    }
                    <td className="even">{normalizePercentage(row.porcentajeCanceladas)}</td>
                    <td>{row.totalCuentasRechazo}</td>
                    {cadenaComercial === '2' &&
                      <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                    }
                    <td>{normalizePercentage(row.rechazoInicalAnterior)}</td>
                    <td
                      className={`bg-${colorSemaphore(
                        "rechazo",
                        row.rechazoInicalActual,
                        cadenaComercial
                      )}`}
                    >
                      {normalizePercentage(row.rechazoInicalActual)}
                    </td>

                    {/* Estrella? */}
                    <td>
                      {row.ganaEstrella === "No ganaría"
                        ? "No"
                        : row.ganaEstrella}
                    </td>

                    {/* IPPF */}
                    <td>{normalizePercentage(row.metaIppf)}</td>
                    <td style={{ backgroundColor: row.metaIppfAnterior >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>
                      {normalizePercentage(row.metaIppfAnterior)}
                    </td>
                    <td
                      className={`bg-${colorSemaphore(
                        "ippf",
                        row.ippfFaltante,
                        cadenaComercial
                      )}`}
                    >
                      {normalizePercentage(row.ippfFaltante)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tr className="bg-white border-0">
              <td></td>
              <td></td>
              <td></td>
              {usuario.puede.ver.estadisticas.otros && (
                <>
                  <td className="fw-bold">{formatoMiles(suma1)}</td>
                  <td className="fw-bold">{formatoMiles(suma2)}</td>
                  <td className="fw-bold">{formatoMiles(suma3)}</td>
                  <td className="fw-bold">{formatoMiles(suma13)}</td>
                  <td className="fw-bold">{formatoMiles(suma4)}</td>
                  <td className="fw-bold">{formatoMiles(suma5)}</td>
                </>
              )}
              <td></td>
              {usuario.puede.ver.estadisticas.otros && (
                <>
                  <td className="fw-bold">{formatoMiles(suma6)}</td>
                  <td className="fw-bold">{formatoMiles(suma7)}</td>
                  <td className="fw-bold">{formatoMiles(suma8)}</td>
                  <td className="fw-bold">{formatoMiles(suma14)}</td>
                  <td className="fw-bold">{formatoMiles(suma9)}</td>
                  <td className="fw-bold">{formatoMiles(suma10)}</td>
                </>
              )}
              <td></td>
              {cadenaComercial === '1' &&
                <>
                  <td className="fw-bold">{formatoMiles(sumaCN)}</td>
                  <td className="fw-bold">{formatoMiles(suma15)}</td>
                  <td className="fw-bold">{formatoMiles(suma20.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(sumaCNCP)}</td>
                  <td className="fw-bold">{formatoMiles(suma16)}</td>
                  <td className="fw-bold">{formatoMiles(suma21.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(sumaCNCPSF)}</td>
                  <td className="fw-bold">{formatoMiles(suma17)}</td>
                  <td className="fw-bold">{formatoMiles(suma22.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(sumaCNTotal)}</td>
                  <td className="fw-bold">{formatoMiles(suma18)}</td>
                  <td className="fw-bold">{formatoMiles(suma23.toFixed(2))}%</td>
                </>
              }
              {cadenaComercial === '2' &&
                <>
                  <td className="fw-bold">{formatoMiles(suma11)}</td>
                  <td className="fw-bold">{formatoMiles(suma19)}</td>
                  <td className="fw-bold">{formatoMiles(suma24.toFixed(2))}%</td>
                </>
              }
              <td className="fw-bold">{formatoMiles(suma12)}</td>
              {cadenaComercial === '2' &&
                <>
                  <td className="fw-bold"></td>
                </>
              }
              {cadenaComercial === '1' &&
                <td className="fw-bold"></td>
              }
              <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
              {cadenaComercial === '1' &&
                <>
                  <td></td>
                  <td className="fw-bold">{formatoMiles(sumaCNCPTotal)}</td>
                  <td></td>
                  <td className="fw-bold">{formatoMiles(sumaCNCPSFTotal)}</td>
                  <td></td>
                  <td></td>
                  <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
                </>
              }
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </>
      );

    // Tienda
    case 3:
      const nameCase3 = "ranking-tienda";
      return (
        <>
          <DownloadButton 
          id={nameCase3}
          buttonText="Descargar asesores tienda" 
          filenameSuffix="ranking-tienda" 
          />
           {/* DESCARGA UN REPORTE GENERAL DE EMPLEADOS  */}
           {tienePermisoEmpleadosAll && (
              <div>
                <ReporteEmpleadosAll 
                  cadenaComercial={cadenaComercial} 
                  meta={meta} 
                  mes={mes} 
                  anio={anio} 
                  tipo="empleados" 
                  setloading={setloading} 
                />
              </div>
            )}
          <ModalHistorico 
            tienda={tiendaNombre} 
            cadenaComercial={cadenaComercial} 
            numeroTienda={numeroTienda} 
            nombreEmpleado={nombreEmpleado} 
          />
          <table id={nameCase3} className="table table-hover table-sm">
            <thead>
              <tr>
                <th rowSpan='3' className="align-middle table-dark">
                  Rank
                </th>
                <th rowSpan='3' className="align-middle table-dark">
                  # Empleado
                </th>
                <th rowSpan='3' className="align-middle table-dark">
                  Asesor
                </th>
                <th rowSpan='3' className="align-middle table-dark">
                  Área
                </th>
                <th colSpan="4" scope="colgroup" className="even-title">
                  Reactivaciones
                </th>
                <th colSpan="4" scope="colgroup" className="table-dark">
                  Migraciones
                </th>
                {cadenaComercial === '2' &&
                  <th colSpan="6" scope="colgroup" className="even-title">
                    Cuentas nuevas
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th colSpan='13' scope="colgroup" className="even-title">
                    Cuentas nuevas
                  </th>
                }
                <th
                  scope="col"
                  className="text-sm align-middle even-title px-1rem"
                  rowSpan="0"
                  >
                    % DE RETENCIÓN
                </th>
                <th
                  colSpan={cadenaComercial === '1' ? '10' : '2'}
                  scope="colgroup" className="table-dark">
                  Rechazo inicial
                </th>
                <th colSpan="2" scope="colgroup" className="even-title">
                  IPPF
                </th>
              </tr>

              <tr className="table-bordered">
                {/* Reactivaciones */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Meta individual
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Acumulado
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Faltan
                </th>
                <th
                  scope="col"
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  className="text-sm align-middle sticky-header even"
                >
                  % avance
                </th>

                {/* Migraciones */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Meta individual
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Acumulado
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Faltan
                </th>
                <th
                  scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  className="text-sm align-middle sticky-header odd"
                >
                  % avance
                </th>
                {cadenaComercial === '2' &&
                  <>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      Total CN
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      # Básico con <br />
                      {'LC > 5000'}
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      % Básico con <br />
                      {'LC > 5000'}
                    </th>
                  </>
                }
                {cadenaComercial === '1' &&
                  <>
                    <th scope="col" colSpan="3" className="align-middle sticky-header even">
                      CN
                    </th>
                    <th scope="col" colSpan="3" className="align-middle sticky-header even">
                      CN CP
                    </th>
                    <th scope="col" colSpan="3" className="align-middle sticky-header even">
                      CN CP <br />SF
                    </th>
                    <th scope="col" colSpan="3" rowSpan='1' className="align-middle sticky-header even">
                      CN<br />total
                    </th>
                  </>
                }
                {cadenaComercial === '2' &&
                  <th scope="col" rowSpan='2' className="align-middle sticky-header even">
                    Cuentas canceladas <br /> en el mismo mes
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th scope="col" className="align-middle sticky-header even" rowSpan={cadenaComercial === '1' ? '2' : '0'}>
                    Cuentas totales canceladas <br /> en el mismo mes
                  </th>
                }
                {cadenaComercial === '1' &&
                  <>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN
                    </th>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN CP
                    </th>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN CP <br /> SF
                    </th>
                  </>
                }
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  %<br />cancelación
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Cuentas totales<br />rechazadas
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Anterior
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Actual
                </th>

                {/* IPPF */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Meta tienda
                </th>
                <th
                  scope="col"
                  className="text-xs align-middle sticky-header odd"
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                >
                  {(mes === mesActual) ? 'IPPF por asesor al mes anterior' : 'IPPF por asesor al cierre de mes'}

                </th>
              </tr>
              {cadenaComercial === '1' &&
                <tr>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                </tr>
              }
            </thead>

            <colgroup>
              <col span="5" />
              <col span="4" className="even" />
              <col span="4" />
              <col span="5" className="even" />
              <col span="3" />
            </colgroup>

            <tbody className="table-bordered">
              {data.map((row, i) => {
                // Reactivaciones
                suma1 = suma1 + parseFloat(row.metaReactivacion);
                suma2 = suma2 + parseFloat(row.avanceReactivacion);
                suma3 = suma3 + parseFloat(row.faltanReactivacion);
                // Migraciones
                suma4 = suma4 + parseFloat(row.metaMigracion);
                suma5 = suma5 + parseFloat(row.avanceMigracion);
                suma6 = suma6 + parseFloat(row.faltanMigracion);
                
                // Cuentas nuevas
                // Cuentas nuevas CN
                const cnTotal = parseFloat(row.cuentasNuevas?.cn.total);
                const cnBasicoNumero = parseFloat(row.cuentasNuevas?.cn.totalBasico);
                const cnBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cn.totalBasicoPorcentaje);
                suma7 = suma7 + parseFloat(row.cuentasNuevas?.cn.total);
                suma15 = suma15 + parseFloat(row.cuentasNuevas?.cn.totalBasico);
                suma20 = parseFloat([(suma15 * 100) / suma7]);

                // Cuentas nuevas CNCP
                const cncpTotal = parseFloat(row.cuentasNuevas?.cncp.total);
                const cncpBasicoNumero = parseFloat(row.cuentasNuevas?.cncp.totalBasico);
                const cncpBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncp.totalBasicoPorcentaje);
                sumaCNCP = sumaCNCP + parseFloat(row.cuentasNuevas?.cncp.total);
                suma16 = suma16 + parseFloat(row.cuentasNuevas?.cncp.totalBasico);
                suma21 = parseFloat([(suma16 * 100) / sumaCNCP]);

                // Cuentas nuevas CNCPSF
                const cncpsfTotal = parseFloat(row.cuentasNuevas?.cncpsf.total);
                const cncpsfBasicoNumero = parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
                const cncpsfBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncpsf.totalBasicoPorcentaje);
                sumaCNCPSF = sumaCNCPSF + parseFloat(row.cuentasNuevas?.cncpsf.total);
                suma17 = suma17 + parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
                suma22 = parseFloat([(suma17 * 100) / sumaCNCPSF]);

                // CN Total
                const cnTotalTotal = parseFloat(row.cuentasNuevas?.total);
                const cnTotalBasicoNumero = parseFloat(row.cuentasNuevas?.totalBasico);
                const cnTotalBasicoPorcentaje = parseFloat(row.cuentasNuevas?.totalBasicoPorcentaje);
                sumaCNTotal = sumaCNTotal + parseFloat(row.cuentasNuevas?.total);
                suma18 = suma18 + parseFloat(row.cuentasNuevas?.totalBasico);
                suma23 = parseFloat([(suma18 * 100) / sumaCNTotal]);

                // Cuentas nuevas Tlc
                const cncpTotalTlc = parseFloat(row.cuentasNuevas?.tlc.total);
                const cncpNumeroTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasico);
                const cncpPorcentajeTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasicoPorcentaje);
                suma9 = suma9 + parseFloat(row.cuentasNuevas?.tlc.total);
                suma10 = suma10 + parseFloat(row.cuentasNuevas?.tlc.totalBasico);
                suma24 = parseFloat([(suma10 * 100) / suma9]);

                // Cuentas canceladas
                suma8 = suma8 + parseFloat(row.canceladas);
                sumaTotalRechazado = sumaTotalRechazado + parseFloat(row.totalCuentasRechazo);
                sumaCN = sumaCN + parseFloat(row.rechazoTotalCN);
                sumaCNCPTotal = sumaCNCPTotal + parseFloat(row.rechazoTotalCNCP);
                sumaCNCPSFTotal = sumaCNCPSFTotal + parseFloat(row.rechazoTotalCNCPSF);
                porcentajeRetencion = porcentajeRetencion + parseFloat(row.promedioRetencion);
                return (
                  <tr
                    key={i}
                    className="cursor-pointer"
                    onClick={() => {
                      setNombreEmpleado(row.nombreEmpleado)
                      handleTienda(row.numeroEmpleado, row.nombreEmpleado);
                    }}
                  >
                    {/* General */}
                    <td>{row.rank}</td>
                    <td>{row.numeroEmpleado}</td>
                    <td>{row.nombreEmpleado}</td>
                    <td>{row.area}</td>

                    {/* Reactivación */}
                    <td className="even">{row.metaReactivacion}</td>
                    <td>{row.avanceReactivacion}</td>
                    <td>{row.faltanReactivacion}</td>
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeReactivacion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeReactivacion)}
                    </td>

                    {/* Migraciones */}
                    <td className="odd">{row.metaMigracion}</td>
                    <td>{row.avanceMigracion}</td>
                    <td>{row.faltanMigracion}</td>
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeMigracion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeMigracion)}
                    </td>
                    {cadenaComercial === '2' &&
                      <>
                        <td  className="align-middle sticky-header even">{cncpTotalTlc}</td>
                        <td  className="align-middle sticky-header even">{cncpNumeroTlc}</td>
                        <td style={{ backgroundColor: cncpPorcentajeTlc <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }} className="align-middle sticky-header even">{normalizePercentage(cncpPorcentajeTlc)}</td>
                      </>
                    }

                    {/* Cuentas nuevas CN, CNCP, CNCPSF, CNTotal */}
                    {cadenaComercial === '1' &&
                      <>
                        <td className="even">{cnTotal}</td>
                        <td>{cnBasicoNumero}</td>
                        <td style={{ backgroundColor: cnBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnBasicoPorcentaje)}</td>
                        <td>{cncpTotal}</td>
                        <td>{cncpBasicoNumero}</td>
                        <td style={{ backgroundColor: cncpBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpBasicoPorcentaje)}</td>
                        <td className="even">{cncpsfTotal}</td>
                        <td className="even">{cncpsfBasicoNumero}</td>
                        <td style={{ backgroundColor: cncpsfBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpsfBasicoPorcentaje)}</td>
                        <td className="even">{cnTotalTotal}</td>
                        <td className="even">{cnTotalBasicoNumero}</td>
                        <td style={{ backgroundColor: cnTotalBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnTotalBasicoPorcentaje)}</td>
                      </>
                    }
                    <td className="even">{row.canceladas}</td>
                    {cadenaComercial === '1' &&
                      <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                    }
                    {/* Rechazo incial */}
                    {cadenaComercial === '1' &&
                      <>
                        <td>{row.rechazoTotalCN}</td>
                        <td>{normalizePercentage(row.rechazoInicialCN)}</td>
                        <td>{row.rechazoTotalCNCP}</td>
                        <td>{normalizePercentage(row.rechazoInicialCNCP)}</td>
                        <td>{row.rechazoTotalCNCPSF}</td>
                        <td>{normalizePercentage(row.rechazoInicialCNCPSF)}</td>
                      </>

                    }
                    <td>{normalizePercentage(row.porcentajeCanceladas)}</td>
                    <td className="even">{row.totalCuentasRechazo}</td>
                    {cadenaComercial === '2' &&
                      <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                    }
                    <td>{normalizePercentage(row.rechazoAnterior)}</td>
                    <td
                      className={`bg-${colorSemaphore(
                        "rechazo",
                        row.rechazoActual,
                        cadenaComercial
                      )}`}
                    >
                      {normalizePercentage(row.rechazoActual)}
                    </td>

                    {/* IPPF */}
                    <td>{normalizePercentage(row.metaIppf)}</td>
                    <td
                      // className={`bg-${colorSemaphore(
                      //   "mayorOMenor",
                      //   Number(row.ippfAnterior),
                      //   cadenaComercial,
                      //   Number(row.metaIppf)
                      // )}`}
                      style={{ backgroundColor: row.ippfAnterior >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}
                    >
                      {normalizePercentage(row.ippfAnterior)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tr className="bg-white border-0">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="fw-bold">{formatoMiles(suma2)}</td>
              <td className="fw-bold">{formatoMiles(suma3)}</td>
              <td></td>
              <td></td>
              <td className="fw-bold">{formatoMiles(suma5)}</td>
              <td className="fw-bold">{formatoMiles(suma6)}</td>
              <td></td>
              {cadenaComercial === '2' &&
                <>
                  <td className="fw-bold">{formatoMiles(suma9)}</td>
                  <td className="fw-bold">{formatoMiles(suma10)}</td>
                  <td className="fw-bold">{formatoMiles(suma24.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(suma8)}</td>
                </>
              }
              {cadenaComercial === '1' &&
                <>
                  <td className="fw-bold">{formatoMiles(suma7)}</td>
                  <td className="fw-bold">{formatoMiles(suma15)}</td>
                  <td className="fw-bold">{formatoMiles(suma20.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(sumaCNCP)}</td>
                  <td className="fw-bold">{formatoMiles(suma16)}</td>
                  <td className="fw-bold">{formatoMiles(suma21.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(sumaCNCPSF)}</td>
                  <td className="fw-bold">{formatoMiles(suma17)}</td>
                  <td className="fw-bold">{formatoMiles(suma22.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(sumaCNTotal)}</td>
                  <td className="fw-bold">{formatoMiles(suma18)}</td>
                  <td className="fw-bold">{formatoMiles(suma23.toFixed(2))}%</td>
                  <td className="fw-bold">{formatoMiles(suma8)}</td>
                </>
              }
              {cadenaComercial === '1' &&
                <td className="fw-bold"></td>
              }
              {cadenaComercial === '1' &&
                <>
                  <td className="fw-bold">{formatoMiles(sumaCN)}</td>
                  <td></td>
                  <td className="fw-bold">{formatoMiles(sumaCNCPTotal)}</td>
                  <td></td>
                  <td className="fw-bold">{formatoMiles(sumaCNCPSFTotal)}</td>
                  <td></td>
                  <td></td>
                  <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
                </>
              }
            </tr>
          </table>
        </>
      );

    // Empleado
    case 4:
      const nameCase4 = "detalle-empleado";
      return (
        <>
          <DownloadButton id={nameCase4} />
          <ModalHistoricoEmpleado
            numeroEmpleado={numeroEmpleado}
            tienda={tiendaNombre}
            cadenaComercial={cadenaComercial}
            numeroTienda={numeroTienda}
            nombreEmpleado={nombreEmpleado}
          />
          <table id={nameCase4} className="table table-bordered table-hover table-sm">
            <thead>
              <tr>
                <th rowSpan='3' className="align-middle table-dark">
                  Fecha
                </th>
                <th colSpan="5" scope="colgroup" className="even-title">
                  Reactivaciones
                </th>
                <th colSpan="5" scope="colgroup" className="table-dark">
                  Migraciones
                </th>
                {cadenaComercial === '2' &&
                  <th rowSpan="1" colSpan="3" className="align-middle even-title">
                    Cuentas nuevas
                  </th>
                }
                {cadenaComercial === '1' &&
                  <th colSpan='12' scope="colgroup" className="even-title">
                    Cuentas nuevas
                  </th>
                }
                <th rowSpan='3' className="align-middle table-dark">
                  Cuentas canceladas <br /> en el mismo mes
                </th>
                <th rowSpan='3' className="align-middle table-dark even-title">
                  %<br />cancelación
                </th>
                <th
                  colSpan={cadenaComercial === '1' ? '8' : '0'}
                  rowSpan={cadenaComercial === '2' ? '3' : '1'}
                  scope="colgroup" className="table-dark align-middle">
                  Rechazo inicial <br /> individual
                </th>
                <th colSpan="2" scope="colgroup" className="even-title">
                  IPPF
                </th>
              </tr>

              <tr className="table-bordered">
                {/* Reactivaciones */}
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Meta
                </th>
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Avance del día
                </th>
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Acumulado
                </th>
                <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                  Faltan
                </th>
                <th
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  scope="col"
                  className="text-sm align-middle sticky-header even"
                >
                  % logro
                </th>

                {/* Migraciones */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Meta
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Avance del día
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Acumulado
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                  Faltan
                </th>
                <th
                  scope="col"
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  className="text-sm align-middle sticky-header odd"
                >
                  % logro
                </th>
                {cadenaComercial === '2' &&
                  <>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      Total CN
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      # Básico con <br />
                      {'LC > 5000'}
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                      % Básico con <br />
                      {'LC > 5000'}
                    </th>
                  </>
                }
                {cadenaComercial === '1' &&
                  <>
                    <th scope="col" colspan='3' className="align-middle sticky-header even">
                      CN
                    </th>
                    <th scope="col" colspan='3' className="align-middle sticky-header even">
                      CN CP
                    </th>
                    <th scope="col" colspan='3' className="align-middle sticky-header even">
                      CN CP <br /> SF
                    </th>
                    <th scope="col" colspan='3' rowSpan='1' className="align-middle sticky-header even">
                      CN<br />total
                    </th>
                  </>
                }
                {cadenaComercial === '1' &&
                  <>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN
                    </th>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN CP
                    </th>
                    <th
                      scope="col"
                      colspan='2'
                      className="align-middle sticky-header even px-1rem"
                    >
                      CN CP<br />SF
                    </th>
                    <th
                      scope="col"
                      rowSpan={cadenaComercial === '1' ? '2' : '0'}
                      className="align-middle sticky-header even px-1rem"
                    >
                      Cuentas totales<br />rechazadas
                    </th>
                    <th
                      scope="col"
                      rowSpan={cadenaComercial === '1' ? '2' : '0'}
                      className="align-middle sticky-header even px-1rem"
                    >
                      Rechazo Inicial
                    </th>
                  </>
                }

                {/* IPPF */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                  Meta tienda
                </th>
                <th
                  scope="col"
                  rowSpan={cadenaComercial === '1' ? '2' : '0'}
                  className="text-sm align-middle sticky-header even"
                >
                  {(mes === mesActual) ? 'IPPF por asesor al mes anterior' : 'IPPF por asesor al cierre de mes'}
                </th>
              </tr>
              {cadenaComercial === '1' &&
                <tr>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                  </th>
                  <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                  </th>
                  <th scope="col" className="align-middle sticky-header even">
                    %
                  </th>
                </tr>
              }
            </thead>

            <colgroup>
              <col span="2" />
              <col span="5" className="even" />
              <col span="5" />
              <col span="4" className="even" />
              <col span="1" />
            </colgroup>

            <tbody>
              {data.map((row, i) => {
                // Cuentas nuevas
                // Cuentas nuevas CN
                const cnTotal = parseFloat(row.cuentasNuevas?.cn.total);
                const cnBasicoNumero = parseFloat(row.cuentasNuevas?.cn.totalBasico);
                const cnBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cn.totalBasicoPorcentaje);

                // Cuentas nuevas CNCP
                const cncpTotal = parseFloat(row.cuentasNuevas?.cncp.total);
                const cncpBasicoNumero = parseFloat(row.cuentasNuevas?.cncp.totalBasico);
                const cncpBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncp.totalBasicoPorcentaje);

                // Cuentas nuevas CNCPSF
                const cncpsfTotal = parseFloat(row.cuentasNuevas?.cncpsf.total);
                const cncpsfBasicoNumero = parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
                const cncpsfBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncpsf.totalBasicoPorcentaje);

                // CN Total
                const cnTotalTotal = parseFloat(row.cuentasNuevas?.total);
                const cnTotalBasicoNumero = parseFloat(row.cuentasNuevas?.totalBasico);
                const cnTotalBasicoPorcentaje = parseFloat(row.cuentasNuevas?.totalBasicoPorcentaje);

                // Cuentas nuevas Tlc
                const cncpTotalTlc = parseFloat(row.cuentasNuevas?.tlc.total);
                const cncpNumeroTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasico);
                const cncpPorcentajeTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasicoPorcentaje);
                
                return (
                  <tr key={i}>
                    {/* General */}
                    <td>{row.fecha}</td>

                    {/* Reactivación */}
                    <td className="even">{row.metaReactivacion}</td>
                    <td>{row.avanceDiaReactivacion}</td>
                    <td>{row.avanceReactivacion}</td>
                    <td>{row.faltanReactivacion}</td>
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeReactivacion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeReactivacion)}
                    </td>

                    {/* Migraciones */}
                    <td className="odd">{row.metaMigracion}</td>
                    <td>{row.avanceDiaMigracion}</td>
                    <td>{row.avanceMigracion}</td>
                    <td>{row.faltanMigracion}</td>
                    <td
                      className={`bg-${colorSemaphoreAvance(
                        row.porcentajeMigracion,
                        avanceIdeal
                      )}`}
                    >
                      {normalizePercentage(row.porcentajeMigracion)}
                    </td>

                    {/* Cuentas nuevas */}
                    {cadenaComercial === '2' &&
                      <>
                        <td  className="align-middle sticky-header even">{cncpTotalTlc}</td>
                        <td  className="align-middle sticky-header even">{cncpNumeroTlc}</td>
                        <td style={{ backgroundColor: cncpPorcentajeTlc <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }} className="align-middle sticky-header even">{normalizePercentage(cncpPorcentajeTlc)}</td>
                        {/* <td>{row.tarjetasEntregadas}</td> */}
                      </>
                    }
                    {cadenaComercial === '1' &&
                      <>
                        <td className="even">{cnTotal}</td>
                        <td>{cnBasicoNumero}</td>
                        <td style={{ backgroundColor: cnBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnBasicoPorcentaje)}</td>
                        <td>{cncpTotal}</td>
                        <td>{cncpBasicoNumero}</td>
                        <td style={{ backgroundColor: cncpBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpBasicoPorcentaje)}</td>
                        <td className="even">{cncpsfTotal}</td>
                        <td className="even">{cncpsfBasicoNumero}</td>
                        <td style={{ backgroundColor: cncpsfBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpsfBasicoPorcentaje)}</td>
                        <td className="even">{cnTotalTotal}</td>
                        <td className="even">{cnTotalBasicoNumero}</td>
                        <td style={{ backgroundColor: cnTotalBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnTotalBasicoPorcentaje)}</td>
                      </>
                    }
                    <td>{row.canceladas}</td>
                    <td>{normalizePercentage(row.porcentajeCanceladas)}</td>
                    {/* Rechazo incial individual*/}
                    {cadenaComercial === '1' &&
                      <>
                        <td>{row.rechazoTotalCN}</td>
                        <td>{normalizePercentage(row.rechazoInicial)}</td>
                        <td>{row.rechazoTotalCNCP}</td>
                        <td>{normalizePercentage(row.rechazoInicialCNCP)}</td>
                        <td>{row.rechazoTotalCNCPSF}</td>
                        <td>{normalizePercentage(row.rechazoInicialCNCPSF)}</td>
                        <td>{row.totalCuentasRechazo}</td>
                      </>
                    }
                    <td
                      className={`bg-${colorSemaphore(
                        "rechazo",
                        row.rechazoInicial,
                        cadenaComercial
                      )}`}
                    >
                      {normalizePercentage(row.rechazoInicial)}
                    </td>

                    {/* IPPF */}
                    <td>{normalizePercentage(row.metaIppfTienda)}</td>
                    <td
                      // className={`bg-${colorSemaphore(
                      //   "mayorOMenor",
                      //   Number(row.ippfAsesor),
                      //   cadenaComercial,
                      //   Number(row.metaIppfTienda)
                      // )}`}
                      style={{ backgroundColor: row.ippfAsesor >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}
                    >
                      {normalizePercentage(row.ippfAsesor)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );

    default:
      break;
  }
};

export const Warning = () => {
  return (
    <div className="text-center alert alert-warning my-1rem" role="alert">
      Aún no hay avances aquí.
    </div>
  );
};

export default DataTable;
