import React, { useState } from "react";
// Context providers
import { useTheme, useThemeUpdate } from "../index";
// Libraries
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import instance from "../helpers/api.instance";
// Components
import AlertForm from "../components/general/AlertForm";
// Bootstrap Components
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
// ICONS
import IconProfile from "../assets/icons/profile_black.svg";

// Helpers
import asignarPermisos from "../helpers/permissions";
// Easter egg
import party from "party-js";

const Login = ({
  logged,
  setuserInfo,
  setlogged,
  setloading,
  setusuario,
  setreciente,
}) => {
  // ??????
  const [easter, seteaster] = useState(1);
  // View
  const [view, setview] = useState(1);
  // Theme updater
  const setglobalCadenaComercial = useThemeUpdate();

  // React hook form
  const { register, handleSubmit, errors } = useForm();

  const handleEasterEgg = () => {
    if (easter === 5) {
      party.screen();
      seteaster(1);
    } else {
      seteaster(easter + 1);
    }
  };

  // Submit login
  const onSubmit = async function ({ NumEmpleado, Contraseña }) {
    setloading(true);
    // Hacemos la petición
    instance
      .post(
        "login/acceso",
        // Cuerpo de la request
        {
          numeroEmpleado: NumEmpleado,
          password: Contraseña,
        }
      )
      // Obtenemos la respuesta
      .then((res) => {
        if (res.status === 1) {
          setlogged(true);
          setuserInfo(res.data.datos);
          setusuario(asignarPermisos(res.data.datos.clavePerfil));
          setreciente(res.data.datos.reciente);
          localStorage.setItem("clavePerfil", res.data.datos.clavePerfil);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("logged", "true");
          localStorage.setItem(
            "cadenaComercial",
            res.data.datos.cadenaComercial
          );
          setglobalCadenaComercial(res.data.datos.cadenaComercial);
          localStorage.setItem("nombre", res.data.datos.nombres);
        }
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  if (logged) {
    return <Redirect to={`${process.env.PUBLIC_URL}/home`} />;
  } else {
    if (view === 1) {
      return (
        <Container>
          <div className="card py-3rem">
            <div className="row justify-content-center m-0">
              <img
                src={IconProfile}
                className="img-fluid"
                width="75"
                alt="Icon Profile"
              />
            </div>
            <div className="row justify-content-center pt-1rem mb-3rem mx-0">
              <h2>Bienvenido</h2>
            </div>
            <div className="row justify-content-center m-0">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-100 mw-343px text-center"
              >
                <div className="form-label-group">
                  <input
                    type="text"
                    name="NumEmpleado"
                    placeholder="1"
                    ref={register({ required: true })}
                    className="form-control"
                  />
                  <label>No. Empleado</label>
                </div>
                {errors.NumEmpleado && <AlertForm />}

                <div className="form-label-group mt-1rem5">
                  <input
                    type="password"
                    name="Contraseña"
                    placeholder="1"
                    ref={register({ required: true })}
                    className="form-control"
                  />
                  <label>Contraseña</label>
                </div>
                {errors.Contraseña && <AlertForm />}

                <div className="row">
                  <div className="col text-right cursor-pointer">
                    <p
                      className="text-primary p-0 m-0 mt-1rem5"
                      onClick={() => setview(2)}
                    >
                      ¿Olvidaste tu contraseña?
                    </p>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      onClick={() => handleEasterEgg()}
                    >
                      Iniciar sesión
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Container>
      );
    } else {
      return (
        <Container>
          <div className="card py-3rem">
            <div className="row justify-content-center m-0">
              <img
                src={IconProfile}
                className="img-fluid"
                width="75"
                alt="Icon Profile"
              />
            </div>
            <div className="row justify-content-center pt-1rem mb-3rem mx-0">
              <h2>¿Olvidaste tu contraseña?</h2>
            </div>
            <div className="row justify-content-center pt-1rem mb-3rem mx-0">
              <p>
                Si olvidaste tu contraseña, envía un correo a
                soporteplataformapif@gpmass.com de lunes a viernes en un horario
                de 8 a 17 hrs o acércate con tu jefe de crédito.
              </p>
            </div>
            <div className="row justify-content-center pt-1rem mb-3rem mx-0">
              <button className="btn btn-primary" onClick={() => setview(1)}>
                Regresar
              </button>
            </div>
          </div>
        </Container>
      );
    }
  }
};

export default Login;
