import React, { useState, useEffect, useMemo } from "react";
// Libraries
import { useForm } from "react-hook-form";
import instance from "../../helpers/api.instance";
import { normalizePercentage } from "../../helpers/Helpers";
// Components
import AlertForm from "../../components/general/AlertForm";
import Tabla from "../../components/general/DataTable";
// Bootstrap Components
import Container from "react-bootstrap/Container";
import { notification } from "../../helpers/Helpers";
// Icons
import IconBack from "../../assets/icons/back.svg";

const CargaArchivos = ({ setloading }) => {
  // React Form Hook
  const { register, handleSubmit, errors, setValue, reset } = useForm();
  // Usestate para cambiar la vista
  const [view, setview] = useState(1);
  // Archivo
  const [files, setfiles] = useState(null);
  const [fileName, setfileName] = useState(null);
  // Fecha y cadena para verificar si ya existe archivo
  const [fecha, setfecha] = useState(null);
  const [mes, setmes] = useState(null);
  const [anio, setanio] = useState(null);
  const [cadenaComercial, setcadenaComercial] = useState(null);
  const [area, setArea] = useState(null);
  // Tabla para guardar información en caso de que ya exista
  const [tabla, settabla] = useState(null);
  // Hook para guardar los titulos
  const [titulos, settitulos] = useState(null);

  // Titulos para las tablas
  const titulosAvances = [
    {
      Header: "zona",
      accessor: "zona",
    },
    {
      Header: "Número de tienda",
      accessor: "numeroTienda",
    },
    {
      Header: "Número de empleado",
      accessor: "numeroEmpleado",
    },
    {
      Header: "Cuenta nueva 03",
      accessor: "nuevoPifBasico",
    },
    {
      Header: "Cuenta nueva 04",
      accessor: "nuevoPifPlus",
    },
    {
      Header: "Cuenta nueva 05",
      accessor: "nuevoPifPareja",
    },
    {
      Header: "Cuenta nueva 06",
      accessor: "nuevoPifSuperior",
    },
    {
      Header: "Migracion 04",
      accessor: "migracionPifPlus",
    },
    {
      Header: "Migracion 05",
      accessor: "migracionPifPareja",
    },
    {
      Header: "Migracion 06",
      accessor: "migracionPifSuperior",
    },
    {
      Header: "Reactivacion 03",
      accessor: "reactivacionPifBasico",
    },
    {
      Header: "Reactivacion 04",
      accessor: "reactivacionPifPlus",
    },
    {
      Header: "Reactivacion 05",
      accessor: "reactivacionPifPareja",
    },
    {
      Header: "Reactivacion 06",
      accessor: "reactivacionPifSuperior",
    },
    {
      Header: "Rechazo",
      accessor: "rechazo",
    },
    {
      Header: "Cancelado",
      accessor: "cancelado",
    },
    {
      Header: "Total de reactivaciones",
      accessor: "totalReactivaciones",
    },
    {
      Header: "Total de migraciones",
      accessor: "totalMigraciones",
    },
    {
      Header: "Total de cuentas nuevas",
      accessor: "totalCuentasNuevas",
    },
  ];
  const titulosMetasCs = [
    {
      Header: "Zona",
      accessor: "zona",
    },
    {
      Header: "Número de tienda",
      accessor: "numeroTienda",
    },
    {
      Header: "Nombre de tienda",
      accessor: "nombreTienda",
    },
    {
      Header: "Migraciones",
      accessor: "migraciones",
    },
    {
      Header: "Reactivaciones",
      accessor: "reactivaciones",
    },
  ];
  const titulosMetas = [
    {
      Header: "Zona",
      accessor: "zona",
    },
    {
      Header: "Número de tienda",
      accessor: "numeroTienda",
    },
    {
      Header: "Nombre de tienda",
      accessor: "nombreTienda",
    },
    {
      Header: "IPPF Oro",
      accessor: "ippfOro",
    },
    {
      Header: "IPPF Plata",
      accessor: "ippfPlata",
    },
    {
      Header: "IPPF Bronce",
      accessor: "ippfBronce",
    },
    {
      Header: "Reactivaciones Oro",
      accessor: "reactivacionesOro",
    },
    {
      Header: "Reactivaciones Plata",
      accessor: "reactivacionesPlata",
    },
    {
      Header: "Reactivaciones Bronce",
      accessor: "reactivacionesBronce",
    },
    {
      Header: "Migraciones Oro",
      accessor: "migracionesOro",
    },
    {
      Header: "Migraciones Plata",
      accessor: "migracionesPlata",
    },
    {
      Header: "Migraciones Bronce",
      accessor: "migracionesBronce",
    },
    {
      Header: "Rechazo inicial",
      accessor: "rechazoInicial",
    },
  ];
  const titulosIppf = [
    {
      Header: "Número de tienda",
      accessor: "numeroTienda",
    },
    {
      Header: "IPPF",
      accessor: "ippf",
      Cell: ({ value }) => normalizePercentage(value),
    },
  ];
  const titulosIppfEmpleado = [
    {
      Header: "Número de empleado",
      accessor: "numeroEmpleado",
    },
    {
      Header: "IPPF por empleado",
      accessor: "ippf",
      Cell: ({ value }) => normalizePercentage(value),
    },
  ];

  // const titulosComosionesPagadas = [
  //   {
  //     Header: "Número de empleado",
  //     accessor: "numeroEmpleado",
  //   },
  //   {
  //     Header: "Comisiones pagadas por Empledo",
  //     accessor: "numeroEmpleado",
  //     Cell: ({ value }) => normalizePercentage(value),
  //   },
  // ];

  // Memos para la tabla
  const data = useMemo(() => tabla, [tabla]);
  const columns = useMemo(() => titulos, [titulos]);

  // Hook para cambiar la información del memo
  useEffect(() => {
    if (view === 2){
        if(area ==='1'){
            settitulos(titulosMetas);
        }else{
            settitulos(titulosMetasCs);
        }
    }
    if (view === 3) settitulos(titulosAvances);
    if (view === 4) settitulos(titulosIppf);
    if (view === 5) settitulos(titulosIppfEmpleado);
    // if (view === 6) settitulos(titulosComosionesPagadas);
  }, [view, area]);

  // Hook que trae la información
  useEffect(() => {
    if ((mes && anio && cadenaComercial) || (fecha && cadenaComercial)) {
        setloading(true);
        let post = {};
        if(view === 2){
            post = {
                mesCarga: mes,
                anioCarga: anio,
                cadenaComercial: cadenaComercial,
                area: area
            }
        } else{
            post = {
                mesCarga: mes,
                anioCarga: anio,
                cadenaComercial: cadenaComercial,
            }
        }
        
      // Request para enviar archivos
      instance
        .post(
          `${
            view === 2
              ? `metas/listadoMetasCargadas`
              : view === 3
              ? `avances/listadoAvances`
              : view === 4
              ? `avances/listadoIppfCargados`
              : `metas/listadoIppfEmpleados`
          }`,
            post
        )
        .then((res) => {
          setloading(false);
          if (res.status === 1) {
            // En caso de que veamos metas
            if (view === 2) {
              settabla(res.data.metas);
            }
            // En caso de que veamos avances
            else if (view === 3) {
              settabla(res.data.avances);
            }
            // En caso de que veamos IPPF
            else if (view === 4) {
              settabla(res.data.metas);
            }
            // En caso de que veamos IPPF personal
            else if (view === 5) {
              settabla(res.data.metas);
            } 
          } else {
            settabla(false);
          }
        });
    }
  }, [fecha, mes, anio, cadenaComercial,area]); 

  // Hook que elimina la información cada vez que se cambia de vista (para evitar confusiones)
  useEffect(() => {
    settabla(null);
    setfecha(null);
    setmes(null);
    setanio(null);
    setcadenaComercial(null);
  }, [view]);

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setfiles(e.target.files[0]);
      setfileName(e.target.files[0].name);
    } else {
      notification("Sube un archivo");
    }
  };

  const guardarFecha = (e) => {
    /*     Verificamos si la vista es para Avances o IPPF y metas
    if (view === 3) {
      Si es de avances diarios, guardamos los valores directamente
      setfecha(e.target.value);
      Borramos el valor de mes y año, por si acaso, para que no se envíe al axios y devuelva un error
      setmes(null);
      setanio(null);
    } */
    // Si es cualquiera de los otros dos, enviamos sólo mes y año
    /*else {  */
    // Separamos fecha por mes y año
    const fechaSeparada = e.target.value.split("-");
    // Los almacenamos
    setmes(fechaSeparada[1]);
    setanio(fechaSeparada[0]);
    // Borramos el valor de fecha, por si acaso, para que no se envíe al axios y devuelva un error
    setfecha(null);
  };

  const guardarCadena = (e) => {
    // Guardamos la cadena
    setcadenaComercial(e.target.value);
  };
  const guardarArea = (e) => {
    // Guardamos la cadena
    setArea(e.target.value);
  };

  const onSubmit = async function (data) {
    // Obtenemos fecha y cadena
    const { fecha, cadenaComercial } = data;
    // Separamos fecha por mes y año
    const fechaSeparada = fecha.split("-");
    const mesCarga = fechaSeparada[1];
    const anioCarga = fechaSeparada[0];
    // Creamos el archivo y les hacemos append para enviarlo
    let archivo = new FormData();
    archivo.append("archivo", files);
    archivo.append("cadenaComercial", cadenaComercial);
    // Verificamos si es avance diario o IPPF y metas y cargamos fecha dependiendo del caso
    if(view === 2){
        archivo.append('area',area);
    }

    archivo.append("mesCarga", mesCarga);
    archivo.append("anioCarga", anioCarga);

    // Regfile para validar elt tipo de archivo
    const regFile = /(\.xls|\.xlsx|\.txt)$/i;

    // Verificamos que el archivo sea de un tipo válido
    if (regFile.exec(fileName)) {
      setloading(true);
      // Cargamos los datos a la API dependiendo del tipo de archivo que sea
      instance
        .post(
          `/archivos/${
            view === 2
              ? `cargaMetas`
              : view === 3
              ? `cargaAvancesDiarios`
              : view === 4
              ? `cargaIppf`
              : `cargaIppfEmpleados`
          }`,
          // Cuerpo del request (archivo a enviar)
          archivo
        )
        // Concatenamos otra llamada axios
        .then((res) => {
          // Si la request es exitosa
          if (res.status === 1) {
                let post = {};

                if(view === 2){
                    post = {
                        mesCarga: mes,
                        anioCarga: anio,
                        cadenaComercial: cadenaComercial,
                        area: area
                    }
                }else{
                    post = {
                        mesCarga: mes,
                        anioCarga: anio,
                        cadenaComercial: cadenaComercial,
                    }
                }

            notification("¡Archivo subido exitosamente!", "success");
            // Concatenamos un request con la información contenida en el archivo recién subido
            instance
              .post(
                `/${
                  view === 2
                    ? `metas/listadoMetasCargadas`
                    : view === 3
                    ? `avances/listadoAvances`
                    : view === 4
                    ? `avances/listadoIppfCargados`
                    : `metas/listadoIppfEmpleados`
                }`,
                // Cuerpo del request
                  post
              )
              .then((res) => {
                if (res.status === 1) {
                  // En caso de que veamos metas
                  if (view === 2) {
                    settabla(res.data.metas);
                  }
                  // En caso de que veamos avances
                  else if (view === 3) {
                    settabla(res.data.avances);
                  }
                  // En caso de que veamos IPPF general
                  else if (view === 4) {
                    settabla(res.data.metas);
                  }
                  // En caso de veamos IPPF personal
                  else if (view === 5) {
                    settabla(res.data.metas);
                  }
                }
              });
          }
          // Si la request no es exitosa
          else {
            // Devolvemos un error con el mensaje enviado por la API
            notification(res.msg);
          }
        })
        // Una vez terminado, apagamos el loader
        .finally(() => setloading(false));
    }
    // Si el tipo de archivo no es soportado, devolvemos un error
    else {
      notification("¡Tipo de archivo no soportado!");
    }
  };

  const onSubmitComisionesPagadas = async (data) => {
    const { cadenaComercial } = data;
    let archivo = new FormData();
    archivo.append("file", files);
    archivo.append("cadenaComercial", cadenaComercial);

    // RegFile para validar que el tipo de archivo sea .txt
    const regFile = /(\.txt)$/i;
    if (!regFile.exec(files.name)) {
      notification("¡Tipo de archivo no soportado! Solo se permiten archivos .txt", "error");
      return; // Detiene la ejecución si el archivo no es .txt
    }

    setloading(true);

    instance.post("/archivos/uploadComisionesPagadas", archivo)
      .then((res) => {
        if (res.status === "success") {
            notification(res.message, "success");
            setcadenaComercial("");
            reset();
        } else {
            notification(res.message || res.msg, "error");
        }
      })
      .catch((error) => {
        console.error('Error al realizar la solicitud:', error);
        notification("Error en la carga del archivo.", "error");
      })
      .finally(() => setloading(false));
  };

  // Menú de carga
  if (view === 1) {
    return (
      <div fluid className="text-center rounded card">
        <h3>¿Qué quieres cargar?</h3>
        <Container
          fluid
          className="m-0 bg-white rounded w-100 p-3rem justify-content-center"
        >
          <div className="row">
            <div className="col my-1rem my-lg-1rem">
              <button
                className="btn btn-outline-info"
                onClick={() => setview(2)}
              >
                <h4>Metas</h4>
                <p className="text-xs">(Cualquiera)</p>
              </button>
            </div>
            <div className="col my-1rem my-lg-1rem">
              <button
                className="btn btn-outline-info"
                onClick={() => setview(3)}
              >
                <h4>Avances globales</h4>
                <p className="text-xs">(Migración, Reactivación y Rechazo)</p>
              </button>
            </div>
            <div className="col my-1rem my-lg-1rem">
              <button
                className="btn btn-outline-info"
                onClick={() => setview(4)}
              >
                <h4>Avances globales</h4>
                <p className="text-xs">(IPPF)</p>
              </button>
            </div>
            <div className="col my-1rem my-lg-1rem">
              <button
                className="btn btn-outline-info"
                onClick={() => setview(5)}
              >
                <h4>Avances individuales</h4>
                <p className="text-xs">(IPPF)</p>
              </button>
            </div>
            <div className="col my-1rem my-lg-1rem">
              <button
                className="btn btn-outline-info"
                onClick={() => setview(6)}
              >
                <h4>Comisiones pagadas</h4>
                <p className="text-xs">(Cualquiera)</p>
              </button>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  // Mensuales (IPPF, IPPF individual, metas y comisiones pagadas)
  if (view === 2 || view === 4 || view === 5)
    return (
      <div fluid className="text-center rounded card">
        <div className="text-left">
          <div onClick={() => setview(1)} className="cursor-pointer">
            <img src={IconBack} alt="Back Arrow" className="back-arrow" />
          </div>
        </div>
        <h3 className="py-1rem">
          Subir {view === 2 ? "metas" : "avances (sólo IPPF)"}
        </h3>
        <form
          className="w-100"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <div className="form-label-group w-100">
            <div className="row justify-content-center">
              <div className="col-2">
                <label htmlFor="fecha">Fecha:</label>
              </div>
              <div className="col-3">
                <input
                  type="month"
                  id="fecha"
                  name="fecha"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                  onChange={(e) => guardarFecha(e)}
                />
                {errors.fecha && <AlertForm />}
              </div>
            </div>
              { view === 2 &&
            <div className="form-label-group w-100 pt-1rem">
              <div className="row justify-content-center">
                <div className="col-2">
                  <label htmlFor="area">Area:</label>
                </div>
                <div className="col-3">
                  <select
                    name="area"
                    className="form-control mb-rem5"
                    ref={register({ required: true })}
                    onChange={(e) => guardarArea(e)}
                  >
                    <option value="">Selecciona</option>
                    <option value="1">Credito</option>
                    <option value="2">Centro de Seguros</option>
                  </select>
                  {errors.area && <AlertForm />}
                </div>
              </div>
            </div>
              }
            <div className="form-label-group w-100 pt-1rem">
              <div className="row justify-content-center">
                <div className="col-2">
                  <label htmlFor="cadenaComercial">Línea de negocio:</label>
                </div>
                <div className="col-3">
                  <select
                    name="cadenaComercial"
                    className="form-control mb-rem5"
                    ref={register({ required: true })}
                    onChange={(e) => guardarCadena(e)}
                  >
                    <option value="">Selecciona</option>
                    <option value="1">Liverpool</option>
                    <option value="2">Suburbia</option>
                  </select>
                  {errors.cadenaComercial && <AlertForm />}
                </div>
              </div>
            </div>
            {/* Uploading module */}
            <div className="form-label-group w-100">
              <div className="row justify-content-center">
                <div className="col-2">
                  <label htmlFor="archivo">Archivo:</label>
                </div>
                <div className="col-3">
                  <input
                    type="file"
                    id="archivo"
                    name="archivo"
                    onChange={(e) => handleFile(e)}
                    accept=".xls,.xlsx"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary mt-1rem5"
            onClick={() =>
              tabla
                ? notification(
                    "¿Estás seguro que deseas sobreescribir las metas ya cargadas?",
                    "warning"
                  )
                : null
            }
          >
            {tabla ? "Resubir" : "Subir"}
          </button>
        </form>
        {/* END OF: Uploading module */}
        <div className="table-responsive scroll-x mt-1rem">
          <div className="">
            {tabla && data && columns && (
              <Tabla data={data} columns={columns} />
            )}
          </div>
        </div>
        <div className="text-center">{tabla === false && <MensajeVacio />}</div>
      </div>
    );

  // Avances diarios
  if (view === 3)
    return (
      <div fluid className="text-center rounded card">
        <div className="text-left">
          <div onClick={() => setview(1)} className="cursor-pointer">
            <img src={IconBack} alt="Back Arrow" className="back-arrow" />
          </div>
        </div>
        <h3 className="py-1rem">Subir avances (sin IPPF)</h3>
        <form
          className="w-100"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <div className="form-label-group w-100">
            <div className="row justify-content-center">
              <div className="col-2">
                <label htmlFor="fecha">Fecha:</label>
              </div>
              <div className="col-3">
                <input
                  type="month"
                  id="fecha"
                  name="fecha"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                  onChange={(e) => guardarFecha(e)}
                />
                {errors.fecha && <AlertForm />}
              </div>
            </div>
            <div className="form-label-group w-100 pt-1rem">
              <div className="row justify-content-center">
                <div className="col-2">
                  <label htmlFor="cadenaComercial">Línea de negocio:</label>
                </div>
                <div className="col-3">
                  <select
                    name="cadenaComercial"
                    className="form-control mb-rem5"
                    ref={register({ required: true })}
                    onChange={(e) => guardarCadena(e)}
                  >
                    <option value="">Selecciona</option>
                    <option value="1">Liverpool</option>
                    <option value="2">Suburbia</option>
                  </select>
                  {errors.cadenaComercial && <AlertForm />}
                </div>
              </div>
            </div>
            {/* Uploading module */}
            <div className="form-label-group w-100">
              <div className="row justify-content-center">
                <div className="col-2">
                  <label htmlFor="archivo">Archivo:</label>
                </div>
                <div className="col-3">
                  <input
                    type="file"
                    id="archivo"
                    name="archivo"
                    onChange={(e) => handleFile(e)}
                    accept=".xls,.xlsx,.txt"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary mt-1rem5"
            onClick={() =>
              tabla
                ? notification(
                    "¿Estás seguro que deseas sobreescribir las metas ya cargadas?",
                    "warning"
                  )
                : null
            }
          >
            {tabla ? "Resubir" : "Subir"}
          </button>
        </form>
        {/* END OF: Uploading module */}

        {tabla && data && columns && (
          <div className="text-center table-responsive scroll-x mt-1rem5">
            <div className="">
              <Tabla data={data} columns={columns} />
            </div>
          </div>
        )}

        {tabla === false && (
          <div className="text-center">
            <MensajeVacio />
          </div>
        )}
      </div>
    );
    
  // Comisiones pagadas
  if (view === 6)
  return (
    <div fluid className="text-center rounded card">
      <div className="text-left">
        <div onClick={() => setview(1)} className="cursor-pointer">
          <img src={IconBack} alt="Back Arrow" className="back-arrow" />
        </div>
      </div>
      <h3 className="py-1rem">Subir Comisiones pagadas</h3>
      <form
        className="w-100"
        onSubmit={handleSubmit(onSubmitComisionesPagadas)}
        encType="multipart/form-data"
      >
        <div className="form-label-group w-100">
          <div className="form-label-group w-100 pt-1rem">
            <div className="row justify-content-center">
              <div className="col-2">
                <label htmlFor="cadenaComercial">Línea de negocio:</label>
              </div>
              <div className="col-3">
                <select
                  name="cadenaComercial"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                  onChange={(e) => guardarCadena(e)}
                >
                  <option value="">Selecciona</option>
                  <option value="1">Liverpool</option>
                  <option value="2">Suburbia</option>
                </select>
                {errors.cadenaComercial && <AlertForm />}
              </div>
            </div>
          </div>
          {/* Uploading module */}
          <div className="form-label-group w-100">
            <div className="row justify-content-center">
              <div className="col-2">
                <label htmlFor="archivo">Archivo:</label>
              </div>
              <div className="col-3">
                <input
                  type="file"
                  id="archivo"
                  name="archivo"
                  onChange={(e) => handleFile(e)}
                  accept=".txt"
                  ref={register({ required: true })}
                  // {...register("archivo", { required: true })}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-1rem5"
          onClick={() =>
            tabla
              ? notification(
                  "¿Estás seguro que deseas sobreescribir las comisiones ya cargadas?",
                  "warning"
                )
              : null
          }
        >
          {tabla ? "Resubir" : "Subir"}
        </button>
      </form>
      {/* END OF: Uploading module */}

      {tabla && data && columns && (
        <div className="text-center table-responsive scroll-x mt-1rem5">
          <div className="">
            <Tabla data={data} columns={columns} />
          </div>
        </div>
      )}

      {tabla === false && (
        <div className="text-center">
          <MensajeVacioComisionesPagadas />
        </div>
      )}
    </div>
  );
};

export const MensajeVacio = () => {
  return (
    <div className="mb-0 alert alert-info mt-3rem">
      Aún no hay metas cargadas para esta línea de negocio en esta fecha.
    </div>
  );
};

export const MensajeVacioComisionesPagadas = () => {
  return (
    <div className="mb-0 alert alert-info mt-3rem">
      Aún no hay comisiones pagadas para esta línea de negocio en esta fecha.
    </div>
  );
};

export default CargaArchivos;
