// Libraries
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import instance from "../../helpers/api.instance";
import { returnStar, medalHeader } from "../../helpers/Helpers";
import { Link } from "react-router-dom";
import IconBefore from "../../assets/icons/backArrow.svg";
import IconNext from "../../assets/icons/nextArrow.svg";
// Components
import DataTableMedallero from "../../components/general/DataTableMedallero";

const Medallero = ({ userInfo, setloading, usuario }) => {
  // Cadena comercial a buscar
  const [cadenaComercial, setcadenaComercial] = useState(
    userInfo.cadenaComercial ? userInfo.cadenaComercial : "1"
  );
  // State en el que se guardarán las medallas obtenidas en la request del API
  const [medallas, setmedallas] = useState(0);
  // Fecha a mostrar como última actualización
  const [fecha, setfecha] = useState(null);
  // Categoría a mostrar
  const [categoria, setCategoria] = useState("zonas");
  // Filtrado por zona
  const [zona, setZonas] = useState("");
  // Si esta expandido o contraido
  const [detalle, setdetalle] = useState("resumido");
  // Hook para guardar el nombre de las zonas.
  const [nombreZonas, setNombreZonas] = useState([]);

  const [anio, setanio] = useState(2021);
  const [date, setDate] = useState(DateTime.now());

  //Obtiene el nombre de las zonas para usarlo en el listado desplegable.
  useEffect(() => {

  }, [zona, cadenaComercial])

  // Effect principal para obtener la información de la api
  useEffect(() => {
    setloading(true);
    if (categoria === "zonas") {
      instance
        .post(
          `/medallero/globalMedalleroTabla`,
          // Cuerpo del request
          {
            cadenaComercial: cadenaComercial,
            zona: zona,
            anio: date.setLocale("es")
                  .toLocaleString({ year: "numeric" })
          }
        )
        .then((res) => {
          if (res.status === 1) {
            setmedallas(res.data.lista);
          } else {
            setmedallas(0);
          }
        })
        // Apagamos el loader
        .finally(() => setloading(false));
    }
    if (categoria === "tiendas") {
      instance
        .post(
          `/medallero/tablaGlobalMedalleroZona`,
          // Cuerpo del request
          {
            cadenaComercial: cadenaComercial,
            zona: zona,
            anio: date.setLocale("es")
                  .toLocaleString({ year: "numeric" })
          }
        )
        .then((res) => {
          if (res.status === 1) {
            setmedallas(res.data.lista);
          } else {
            setmedallas(0);
          }
        })
        // Apagamos el loader
        .finally(() => setloading(false));
    }
    instance.get(`/tiendas/catalogoZonas/${cadenaComercial}`)
    .then((res) => {
      if (res.status === 1) {
        setNombreZonas(() => res.data.zonas);
      }
    }).finally(()=>setloading(false));
  }, [cadenaComercial, categoria, zona, date]);


  // Cambia la fecha cada vez que cambiamos algo del documento
  useEffect(() => {
    setfecha(new Date());
  }, []);

  // Memo para usar en la tabla
  const data = useMemo(() => medallas, [medallas]);

  // Funciones para manejar el cambio según el select
  const handleCategoria = (e) => {
    setCategoria(e.target.value)
  };
  const handleZonas = (e) => setZonas(e.target.value);
  const handleDetalle = (e) => {
    if (e.target.value === "detalle") {
      setdetalle(true);
    } else {
      setdetalle(false);
    }
  };

  const handleCadenaComercial = (e) => {
    setNombreZonas(()=>[]);
    setZonas(()=>"");
    setcadenaComercial(e.target.value);
  }

  const handleDate = (op) => {
    if (op === "addition") {
      setDate(date.plus({ years: 1 }));
    } else {
      setDate(date.minus({ years: 1 }));
    }
  };
  return (
    <>
    <div className="text-center rounded card">
      <h2>Ranking por {categoria}</h2>
      <p className="text-sm">{`Actualizado el ${fecha}`}</p>

      {/* Filtros  */}
      <div className="text-left row mt-3rem align-items-left">
        <div className="col-1">
          <p>Filtrar:</p>
        </div>
        <div className="col-2">
          <div className="form-label-group">
            <select
              name="detalle"
              id="detalle"
              className="form-control"
              onChange={(e) => handleDetalle(e)}
            >
              <option value="detalle">A detalle</option>
              <option value="resumido">Resumido</option>
            </select>
          </div>
        </div>
        <div className="col-2">
          <div className="form-label-group">
            <select
              name="categoria"
              id="categoria"
              className="form-control"
              onChange={(e) => handleCategoria(e)}
            >
              <option value="zonas">Zonas</option>
              <option value="tiendas">Tiendas</option>
            </select>
          </div>
        </div>
        <div className="col-2">
          {usuario.puede.ver.medallero.todo && (
            <div className="col">
              <div className="form-label-group">
                <select
                  name="categoria"
                  id="categoria"
                  className="form-control"
                  onChange={(e) => handleCadenaComercial(e)}
                >
                  <option value="1">Liverpool</option>
                  <option value="2">Suburbia</option>
                </select>
              </div>
            </div>
          )}
        </div>
        <div className="col-2">
          {usuario.puede.ver.medallero.todo && categoria==="tiendas" && (
            <div className="col">
              <div className="form-label-group">
                <select
                  name="zonas"
                  id="zonas"
                  className="form-control"
                  onChange={(e) => handleZonas(e)}
                >
                  <option value="">Selecciona Zona</option>
                  {nombreZonas.map((zona)=>{
                    const name = zona["zona"];
                    return(
                      <>
                        <option value={name}>{name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className='col-1'>
        </div>
        <div className='col-2'>
            <div className="text-center col col-lg-3 d-flex align-items-center justify-content-center">
              <img
                src={IconBefore}
                alt=""
                className="cursor-pointer card-hover-big"
                data-tip="Cambiar de categoría"
                onClick={() => handleDate("substract")}
              />
              <p className="m-0 px-1rem">
                {date
                  .setLocale("es")
                  .toLocaleString({ year: "numeric" })}
              </p>
              <img
                src={IconNext}
                alt=""
                className="cursor-pointer card-hover-big"
                data-tip="Cambiar de categoría"
                onClick={() => handleDate("addition")}
              />
            </div>

        </div>
      </div>
      {
        // No existen medallas
       data === 0 && medallas === 0 && (
          <div className="text-center alert alert-warning mt-1rem" role="alert">
            Aún no hay medallas en esta categoría.
          </div>
        )
      }

      <div className="table-responsive scroll-x">
        <div className="">

          {data.length > 0 && categoria === "tiendas" && (
            <Tiendas data={data} categoria={categoria} detalle={detalle} />
          )}
					{data.length > 0 && categoria !== "tiendas" && (

            <Zonas data={data} detalle={detalle} />
          )}
        </div>
      </div>
    </div>
    </>
  );
};

// Inicializacion de variables para cada medalla
const gold = "gold";
const silver = "silver";
const bronze = "bronze";

const Zonas = ({ data, detalle }) => {
  const columnsExpanded = useMemo(
    () => [
      {
        Header: "Rank",
        accessor: "rank",
      },
      {
        Header: "Zona",
        accessor: "zona",
      },
      {
        Header: "Ene",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Enero.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Enero.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Enero.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Feb",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Febrero.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Febrero.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Febrero.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Mar",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Marzo.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Marzo.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Marzo.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Abr",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Abril.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Abril.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Abril.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "May",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Mayo.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Mayo.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Mayo.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Jun",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Junio.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Junio.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Junio.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Jul",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Julio.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Julio.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Julio.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Ago",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Agosto.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Agosto.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Agosto.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Sep",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Septiembre.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Septiembre.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Septiembre.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Oct",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Octubre.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Octubre.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Octubre.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Nov",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Noviembre.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Noviembre.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Noviembre.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Dic",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "meses.Diciembre.oro",
            Cell: ({ value }) => <span className="text-gold">{value}</span>,
          },
          {
            Header: () => medalHeader(silver),
            accessor: "meses.Diciembre.plata",
            Cell: ({ value }) => <span className="text-silver">{value}</span>,
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "meses.Diciembre.bronce",
            Cell: ({ value }) => <span className="text-bronze">{value}</span>,
          },
        ],
      },
      {
        Header: "Total",
        columns: [
          {
            Header: () => medalHeader(gold),
            accessor: "estrellasOro",
          },
          {
            Header: () => medalHeader(silver),
            accessor: "estrellasPlata",
          },
          {
            Header: () => medalHeader(bronze),
            accessor: "estrellasBronce",
          },
        ],
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Rank",
        accessor: "rank",
      },
      {
        Header: "Zona",
        accessor: "zona",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    []
  );

  return (
    <DataTableMedallero
      data={data}
      columns={detalle ? columnsExpanded : columns}
    />
  );
};

const Tiendas = ({ data, detalle }) => {
  const columnsExpanded = useMemo(
    () => [
      { Header: "Rank", accessor: "rank" },
      {
        Header: "Zona",
        accessor: "zona",
      },
      { Header: "ID", accessor: "numeroTienda" },
      { Header: "Tienda", accessor: "nombreTienda" },
      {
        Header: "Ene",
        accessor: "meses.Enero",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Feb",
        accessor: "meses.Febrero",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Mar",
        accessor: "meses.Marzo",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Abr",
        accessor: "meses.Abril",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "May",
        accessor: "meses.Mayo",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Jun",
        accessor: "meses.Junio",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Jul",
        accessor: "meses.Julio",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Ago",
        accessor: "meses.Agosto",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Sep",
        accessor: "meses.Septiembre",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Oct",
        accessor: "meses.Octubre",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Nov",
        accessor: "meses.Noviembre",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: "Dic",
        accessor: "meses.Diciembre",
        Cell: ({ value }) => returnStar(value),
      },
      {
        Header: () => medalHeader(gold),
        accessor: "estrellasOro"
      },
      {
        Header: () => medalHeader(silver),
        accessor: "estrellasPlata"
      },
      {
        Header: () => medalHeader(bronze),
        accessor: "estrellasBronce"
      },
      {
        Header: "Total",
        accessor: "total"
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      { Header: "Rank", accessor: "rank" },
      {
        Header: "Zona",
        accessor: "zona",
      },
      { Header: "ID", accessor: "numeroTienda" },
      { Header: "Tienda", accessor: "nombreTienda" },
      {
        Header: () => medalHeader(gold),
        accessor: "estrellasOro",
      },
      {
        Header: () => medalHeader(silver),
        accessor: "estrellasPlata",
      },
      {
        Header: () => medalHeader(bronze),
        accessor: "estrellasBronce",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    []
  );
  return (
      <DataTableMedallero
        data={data}
        columns={detalle ? columnsExpanded : columns}
      />
  );
};

export default Medallero;
