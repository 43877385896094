// Libraries
import React, { useState, useEffect, useMemo } from "react";
import instance from "../../../helpers/api.instance";
import { useForm } from "react-hook-form";
import { notification } from "../../../helpers/Helpers";
import ReactTooltip from "react-tooltip";
// Icons
import IconBack from "../../../assets/icons/backArrow.svg";
import IconNext from "../../../assets/icons/nextArrow.svg";

const TablaCSS = ({
  setloading,
  IdTienda,
  cadenaComercial,
  status,
  setstatus,
  categoria,
  setcategoria,
}) => {
  // Useform para validar que todos los campos tengan un valor
  const { handleSubmit, register } = useForm();
  //Total de metas
  const [total, settotal] = useState(0);
  // Metas por asignar
  const [faltantes, setfaltantes] = useState(null);
  // Maximo a asignar
  const [maximo, setmaximo] = useState(null);
  // Usuarios para cargar metas
  const [tabla, settabla] = useState(null);
  // Hooks para guardar las reactivaciones y migraciones
  const [reactivaciones, setreactivaciones] = useState(null);
  const [migraciones, setmigraciones] = useState(null);
  // Metas que se mostrarán encima de las estrellas
  const [metasreactivaciones, setmetasreactivaciones] = useState(null);
  const [metasmigraciones, setmetasmigraciones] = useState(null);
  const [metas, setmetas] = useState(null);
  // Meta elegida por el usuario
  const [meta, setmeta] = useState("Oro");

  const [motivosMetas, setMotivosMetas] = useState(null);

  const [mediasMeta, setMediasMeta] = useState(null);

  const [tablaInactivo, setTablaInactivo] = useState(null);

  const [permiteAsignar, setpermiteAsignar] = useState(1);
  const [diaPermiteAsignar, setdiaPermiteAsignar] = useState(0);

  // Columnas a mostrar en la tabla
  const titulos = [
    "Nombre(s)",
    "Apellido Paterno",
    "Puesto",
    "Núm. Empleado",
    "Edita meta individual",
    "Motivo Asigna",
    "Estatus",
    "Activo",
  ];

  const titulosInactivos = [
    "Nombre(s)",
    "Apellido Paterno",
    "Puesto",
    "Núm. Empleado",
    "Estatus",
    "Activo",
  ];

  // Obtener lista de metas
  const obtenerListaMetas = () => {
    setloading(true);
    // Request para obtener el listado de metas
    instance
      .post(
        `/metas/listadoMetasCs`,
        // Cuerpo del request
        {
          cadenaComercial: cadenaComercial,
          numeroTienda: IdTienda,
        }
      )
      .then((res) => {
        // Si se encontró datos de la tienda
        if (res.status === 1) {
          settabla(res.data.listado);
          setmigraciones(res.data.migraciones);
          setreactivaciones(res.data.reactivaciones);
          setTablaInactivo(res.data.usuariosInactivos);
          setmeta(res.data.tipoMeta);
          setmetasmigraciones(res.data.metaTienda.migraciones);
          setmetasreactivaciones(res.data.metaTienda.reactivaciones);
          setMotivosMetas(res.data.motivosAsignaMetas);
          setMediasMeta(res.data.mediasMeta);
          setpermiteAsignar(res.data.permiteAsignar)
          setdiaPermiteAsignar(res.data.diaPermiteAsignar);
          settotal(
            categoria === "migraciones"
              ? parseInt(res.data.migraciones.totales)
              : parseInt(res.data.reactivaciones.totales)
          );
        }
        setstatus(res.status);
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  // Función que activa / inactiva
  const toggleUsuario = (numeroEmpleado, status) => {
    setloading(true);
    instance
      .post(
        `/usuario/estatusUsuario`,
        // Cuerpo del request
        {
          idUsuario: numeroEmpleado,
          status: status,
        }
      )
      .then((res) => {
        // Si la respuesta es exitosa
        if (res.status === 1) {
          notification(res.msg, "warning");
          settabla(null);
          // Repetimos la función para obtener la lista de empleados de nuevo
          obtenerListaMetas();
        }
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  // UseEffect para obtener la información de la tabla
  useEffect(() => {
    if (IdTienda) {
      obtenerListaMetas();
    }
  }, [IdTienda, cadenaComercial]);

  // Use effect que asigna el total dependiendo de la categoría seleccionada
  useEffect(() => {
    if (status) {
      if (categoria === "reactivaciones") {
        setmaximo(parseInt(reactivaciones.totales));
        setfaltantes(reactivaciones.faltantes);
        setmetas(metasreactivaciones);
      }
      if (categoria === "migraciones") {
        setmaximo(parseInt(migraciones.totales));
        setfaltantes(migraciones.faltantes);
        setmetas(metasmigraciones);
      }
    }
  }, [categoria, status]);


  // Función que envía la información
  const onSubmit = (data) => {
    let menorMetas = [];
    let sinMotivo = [];

    if (categoria === 'reactivaciones') {
      menorMetas = tabla
        .filter((m) => m.bloquearSelect === true && m.reactivaciones < mediasMeta.reactivacion.minimo);
      if (total !== parseInt(metasreactivaciones)) {
        notification("No pueden sobrar ni exceder la meta: " + metasreactivaciones);
        return;
      }
      sinMotivo = tabla
        .filter((m) => m.bloquearSelect === false && !m.motivoReactivaciones );
    }
    if (categoria === 'migraciones') {
      menorMetas = tabla
        .filter((m) => m.bloquearSelect === true && m.migraciones < mediasMeta.migracion.minimo);

      if (total !== parseInt(metasmigraciones)) {
        notification("No pueden sobrar ni exceder la meta: " + metasmigraciones);
        return;
      }
      sinMotivo = tabla
        .filter((m) => m.bloquearSelect === false && !m.motivoMigraciones );
    }
    if (menorMetas.length > 0) {
      notification('La meta no puede ser menor al 50% de la meta dividida entre los asesores')
      return;
    }
    if (sinMotivo.length > 0) {
      notification('Tienes que seleccionar un motivo');
      return;
    }
    setloading(true);
    let metas = [];
    tabla.map((v) => {
      if (categoria === "reactivaciones") {
        const dict = {
          numeroEmpleado: v.numeroEmpleado,
          reactivaciones: v.reactivaciones,
          motivoReactivaciones: v.motivoReactivaciones,
        };
        metas.push(dict);
      } else {
        const dict = {
          numeroEmpleado: v.numeroEmpleado,
          migraciones: v.migraciones,
          motivoMigraciones: v.motivoMigraciones,
        };
        metas.push(dict);
      }
    });
    // Request para asignar metas a empleado
    instance
      .post(
        `/metas/asignarMetasEmpleadoCs`,
        // Cuerpo del request
        {
          cadenaComercial: cadenaComercial,
          numeroTienda: IdTienda,
          tipoMeta: categoria,
          metas: metas,
        }
      )
      .then((res) => {
        // Si se encontró datos de la tienda
        if (res.status === 1)
          notification("Metas asignadas correctamente", "success");
      })
      // Apagamos el loader
      .finally(() => setloading(false));

  };

  // Función que actualiza las metas cada vez que hay un cambio
  const handleChange = () => {
    let sum = 0;
    tabla.forEach((row) => {
      if (categoria === "reactivaciones") {
        sum += parseInt(row.reactivaciones);
      } else {
        sum += parseInt(row.migraciones);
      }
      if (isNaN(sum)) settotal(0);
      settotal(sum);
    });
  };

  // Camia entre las categorías
  const toggleCategoria = () => {
    if (categoria === "reactivaciones") {
      settotal(parseInt(migraciones.totales));
      setcategoria("migraciones");
    }
    if (categoria === "migraciones") {
      settotal(parseInt(reactivaciones.totales));
      setcategoria("reactivaciones");
    }
  };

  const data = useMemo(() => tabla, [tabla]);

  // Si aún no obtenemos los datos, regresamos null
  if (!reactivaciones || !migraciones || !tabla) return null;

  // Devolvemos nada mientras aún no se obtiene respuesta de la API
  if (status === null) return null;

  // Si todo sale bien, se devuelve este contenido con la tabla
  if (!tabla) return null;

  const checkIncapacidad = (row) => {
    return row.motivoMigraciones === 'Incapacidad';
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="justify-content-center text-center py-1rem"
        key={categoria}
      >
        <div className="row justify-content-between align-items-center">
          {permiteAsignar === 1 && metas && (
            <>
              <div className='col-12'>
                <div className='alert alert-danger'>
                  <span>Puedes asignar la meta de tu equipo hasta el {diaPermiteAsignar} de cada mes.</span>
                </div>
              </div>
              <div className="col text-left">
                <div className="col d-flex text-left align-items-center">
                </div>
              </div>
            </>
          )}
          {permiteAsignar === 1 &&
            <div className="col text-center">
            </div>
          }
          {permiteAsignar === 0 &&
            <div className='col-12'>
              <div
                className='alert alert-danger'
                role="alert"
              >
                La fecha límite para asignación de metas fue el {diaPermiteAsignar} de este mes
              </div>
            </div>
          }

          {metas && (
            <div className="col text-left">
              <div className="col d-flex text-left align-items-center">
                <span className="fw-bold">Tu meta es: </span> {metas}
              </div>
            </div>
          )}
          <div className="col text-center">
            <span className="fw-bold">Metas por asignar:</span> {faltantes}
          </div>
          <div
            className="col cursor-pointer text-capitalize text-right"
            onClick={() => toggleCategoria()}
          >
            <img
              src={IconBack}
              alt=""
              className="card-hover-big"
              data-tip="Cambiar de categoría"
            />
            <ReactTooltip />
            <span className="px-1rem fw-bold">{`${categoria}`}</span>
            <ReactTooltip />
            <img
              src={IconNext}
              alt=""
              className="card-hover-big"
              data-tip="Cambiar de categoría"
            />
          </div>
        </div>
        <div className="row text-left mt-1rem">
          <div className="col">
            {" "}
          </div>
        </div>
        {/* TABLA CSS */}
        <div className="table-responsive">
          <table className="table table-striped table-hover table-sm mb-1rem">
            <thead className="thead-dark">
              <tr>
                {titulos.map((titulo) => {
                  return (
                    <th scope="col" key={titulo} className="px-1rem">
                      {titulo}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 && data.map((row, i) => {
                if (categoria === "reactivaciones") {

                  if (parseInt(row.reactivaciones) < parseInt(mediasMeta.reactivacion.minimo)
                    || parseInt(row.reactivaciones) >= parseInt(mediasMeta.reactivacion.maximo)) {

                    row.bloquearSelect = true;
                    row.motivoReactivaciones = '';
                  } else {
                    row.bloquearSelect = false;
                  }
                } else {
                  if (parseInt(row.migraciones) < parseInt(mediasMeta.migracion.minimo)
                    || parseInt(row.migraciones) >= parseInt(mediasMeta.migracion.maximo)) {
                    row.bloquearSelect = true;
                    row.motivoMigraciones = '';
                  } else {
                    row.bloquearSelect = false;
                  }
                }
                return (
                  <tr key={`row-${i}`} className={checkIncapacidad(row) ? 'bg-bl' : ''}>
                    <td className="px-1rem">{row.nombres}</td>
                    <td className="px-1rem">{row.apPat}</td>
                    <td className="px-1rem">{row.nombrePerfil}</td>
                    <td className="px-1rem">{row.numeroEmpleado}</td>
                    <td className="px-1rem">
                      <div className="form-label-group">
                        <input
                          type="number"
                          className="form-control text-center"
                          ref={register({ required: true })}
                          defaultValue={
                            categoria === "reactivaciones"
                              ? row.reactivaciones
                              : row.migraciones
                          }
                          name={row.numeroEmpleado}
                          id={row.numeroEmpleado}
                          onChange={(e) => {
                            if (!e.target.value) {
                              return;
                            }
                            if (categoria === "reactivaciones") {
                              row.reactivaciones = e.target.value;
                              console.log(row.reactivaciones)
                              console.log(mediasMeta.reactivacion)
                              console.log(row.reactivaciones >= parseInt(mediasMeta.reactivacion))
                              if (row.reactivaciones >= parseInt(mediasMeta.reactivacion)) {
                                row.bloquearSelect = false;
                              } else {
                                row.bloquearSelect = true;
                                row.motivoReactivaciones = '';
                                const v = document.querySelector('#mm-' + row.id);
                                v.value = '';
                              }
                            } else {
                              row.migraciones = e.target.value;
                              if (row.migraciones > mediasMeta.migracion) {
                                row.bloquearSelect = true;
                                row.motivoMigraciones = '';
                              } else {
                                row.bloquearSelect = false;
                              }
                            }
                            handleChange(e);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <select name='motivoMigraciones' id={'mm-' + row.id} className='form-control p-0 pl-1'
                        disabled={row.bloquearSelect}
                        onChange={(e) => {
                          if (categoria === "reactivaciones") {
                            row.motivoReactivaciones = e.target.value;
                          } else {
                            row.motivoMigraciones = e.target.value;
                          }

                          const newRow = { ...row, motivoMigraciones: e.target.value };
                          const newTabla = tabla.map(r => r.id === row.id ? newRow : r);
                          settabla(newTabla);

                        }}>
                        <option value =''
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivaciones == '') ? 'selected' : false :
                                (row.motivoMigraciones == '') ? 'selected' : false
                            }
                        >seleccionar un motivo</option>
                        {motivosMetas && motivosMetas.length > 0 && motivosMetas.map((r) => {
                          return (<option key={r.motivo} value={row.motivo}
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivaciones == r.motivo) ? 'selected' : false :
                                (row.motivoMigraciones == r.motivo) ? 'selected' : false
                            }
                          >{r.motivo}</option>)

                        })
                        }
                      </select>
                    </td>
                    <td className="px-1rem">
                      {row.status}
                    </td>
                    <td>
                      {permiteAsignar === 1 &&
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            onChange={() => {
                              toggleUsuario(row.id, 'Inactivo');
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      }
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <p>{total}</p>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* FIN TABLA CSS */}
        <div
          className={`alert alert-danger ${total > maximo ? `` : `d-none`}`}
          role="alert"
        >
          La meta máxima que puedes dividir es la de oro.
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col">
            {permiteAsignar === 1 &&
              <button className="btn btn-primary" type="submit">
                Guardar
              </button>
            }
          </div>
          <div className="col"></div>
        </div>
      </form>
      {tablaInactivo && tablaInactivo.length > 0 &&
        <div class='col-md-12 my-3'>
          <h2 className='text-center my-5'>Usuarios Inactivos del Mes</h2>
          <div className="table-responsive">
            <table className="table table-striped table-hover table-sm mb-1rem">
              <thead className="thead-dark">
                <tr>
                  {titulosInactivos.map((titulo) => {
                    return (
                      <th scope="col" key={titulo} className="px-1rem text-center">
                        {titulo}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tablaInactivo && tablaInactivo.length > 0 && tablaInactivo.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className='px-1rem text-center'>{v.nombres}</td>
                      <td className='px-1rem text-center'>{v.apPat}</td>
                      <td className='px-1rem text-center'>{v.nombrePerfil}</td>
                      <td className='px-1rem text-center'>{v.numeroEmpleado}</td>
                      <td className='px-1rem text-center'>{v.status}</td>
                      <td className='px-1rem text-center'>
                        {permiteAsignar === 1 &&
                          <label className="switch text-center">
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              onChange={() => {
                                toggleUsuario(v.id, 'Activo');
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  );
};

export default TablaCSS;
