// Libraries
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
// Helpers
import instance from "../../../helpers/api.instance";
import {
  colorSemaphore,
  normalizePercentage,
  normalizePercentageAbs,
} from "../../../helpers/Helpers";
// Icons
import IconGold from "../../../assets/icons/stars/gold.svg";
import IconGoldOutlined from "../../../assets/icons/stars/gold_outlined.svg";
import IconSilver from "../../../assets/icons/stars/silver.svg";
import IconSilverOutlined from "../../../assets/icons/stars/silver_outlined.svg";
import IconBronze from "../../../assets/icons/stars/bronze.svg";
import IconBronzeOutlined from "../../../assets/icons/stars/bronze_outlined.svg";

const Graficas = ({
  setloading,
  view,
  zona,
  numeroTienda,
  cadenaComercial,
  mes,
  anio,
  meta,
}) => {
  // Estadísticas a mostrar
  const [graficas, setgraficas] = useState(null);
  // Cadena comercial

  // UseEffect para obtener estadísticas y avances
  useEffect(() => {
    setloading(true);
    if (mes && anio) {
      if (view === 1) {
        // Request de las gráficas
        instance
          .post(
            `/avances/globalZonasGraficas`,
            // Cuerpo del request
            {
              cadenaComercial,
              mes,
              anio,
              meta,
            }
          )
          .then((res) => {
            if (res.status === 1) setgraficas(res.data);
            if (res.status === 0) setgraficas(0);
          })
          // Apagamos el loader
          .finally(() => setloading(false));
      } else if (view === 2) {
        // Request de las gráficas
        instance
          .post(
            `/avances/globalPorZonasGraficas`,
            // Cuerpo del request
            {
              cadenaComercial,
              zona,
              mes,
              anio,
              meta,
            }
          )
          .then((res) => {
            if (res.status === 1) setgraficas(res.data);
            if (res.status === 0) setgraficas(0);
          })
          // Apagamos el loader
          .finally(() => setloading(false));
      } else if (view >= 3) {
        instance
          .post(
            `/avances/globalPorTiendasGraficas`,
            // Cuerpo del request
            {
              cadenaComercial,
              numeroTienda,
              mes,
              anio,
              meta,
            }
          )
          .then((res) => {
            if (res.status === 1) setgraficas(res.data);
            if (res.status === 0) setgraficas(0);
          })
          // Apagamos el loader
          .finally(() => setloading(false));
      }
    }
  }, [zona, view, numeroTienda, cadenaComercial, mes, anio, meta]);

  if (!graficas) return null;
  return (
    <Fragment>
      {view >= 1 && (
        <div className="d-flex flex-column text-center mb-2rem py-1rem border-top border-bottom" style={{backgroundColor:'#666',color:'#fff'}}>
          <p className="text-white text-lg">
            Tu avance ideal de reactivaciones y migraciones es.
          </p>

          <p className="text-h2 text-white mt-0" id="porcentaje-ideal">
            {`${normalizePercentageAbs(graficas.porcentajeIdeal)}%`}
          </p>
        </div>
      )}
      {graficas ? (
        <div className="flex-wrap row justify-content-around h-1rem5">
          {/* Reactivaciones */}
          <div className="col-lg-2 d-flex d-lg-block separator-mobile">
            {
              // Estrellas
              graficas.reactivaciones.metas ? (
                <div className="row pb-1rem flex-column flex-lg-row">
                  <div className="col">
                    <p className="text-gold">Oro</p>
                    <img
                      src={
                        parseInt(graficas.reactivaciones.avance) >=
                        graficas.reactivaciones.metas.oro
                          ? IconGold
                          : IconGoldOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-gold">
                      {`${graficas.reactivaciones.metas.oro}`}{" "}
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-silver">Plata</p>
                    <img
                      src={
                        parseInt(graficas.reactivaciones.avance) >=
                        graficas.reactivaciones.metas.plata
                          ? IconSilver
                          : IconSilverOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-silver">
                      {`${graficas.reactivaciones.metas.plata}`}{" "}
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-bronze">Bronce</p>
                    <img
                      src={
                        parseInt(graficas.reactivaciones.avance) >=
                        graficas.reactivaciones.metas.bronce
                          ? IconBronze
                          : IconBronzeOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-bronze">
                      {`${graficas.reactivaciones.metas.bronce}`}{" "}
                    </p>
                  </div>
                </div>
              ) : null
            }
            <div className="w-100 d-flex flex-column justify-content-center ml-1rem ml-lg-0">
              <div className="mx-auto max-width-256 px-1rem5 px-lg-0">
                <CircularProgressbar
                  value={normalizePercentageAbs(
                    graficas.reactivaciones.porcentaje
                  )}
                  text={normalizePercentage(graficas.reactivaciones.porcentaje)}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `${colorSemaphore(
                        "reactivacion",
                        normalizePercentageAbs(
                          graficas.reactivaciones.porcentaje
                        ),
                        cadenaComercial,
                        undefined,
                        false,
                        // view > 1 &&
                        normalizePercentageAbs(graficas.porcentajeIdeal)
                      )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "reactivacion",
                        normalizePercentageAbs(
                          graficas.reactivaciones.porcentaje
                        ),
                        cadenaComercial,
                        undefined,
                        false,
                        // view > 1 &&
                        normalizePercentageAbs(graficas.porcentajeIdeal)
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
              <div>
                <h6 className="text-center text-lead fw-bold mt-rem5">
                  Reactivaciones
                </h6>

                <div className="row mt-1rem">
                  <div className="text-left col">
                    <p className="text-lead">Avance:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">
                      {`${graficas.reactivaciones.avance}/${graficas.reactivaciones.total}`}
                    </p>
                  </div>
                </div>
                <div className="row mt-rem5">
                  <div className="text-left col">
                    <p className="text-lead">Faltan:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">{`${graficas.reactivaciones.faltan}`}</p>
                  </div>
                </div>
                <div className="row mt-rem5">
                  <div className="text-left col">
                    <p className="text-lead">Forecast al cierre:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">{`${graficas.reactivaciones.forecast}`}</p>
                  </div>
                </div>
                { (graficas.metaCs) &&
                <div className="row mt-rem5">
                  <div className="text-left col">
                    <p className="text-lead">Meta CS:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">{`${graficas.metaCs.reactivaciones}`}</p>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
          {/* Migraciones */}
          <div className="col-lg-2 d-flex d-lg-block separator-mobile">
            {
              // Estrellas
              graficas.migraciones.metas ? (
                <div className="row pb-1rem flex-column flex-lg-row">
                  <div className="col">
                    <p className="text-gold">Oro</p>
                    <img
                      src={
                        parseInt(graficas.migraciones.avance) >=
                        graficas.migraciones.metas.oro
                          ? IconGold
                          : IconGoldOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-gold">
                      {`${graficas.migraciones.metas.oro}`}{" "}
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-silver">Plata</p>
                    <img
                      src={
                        parseInt(graficas.migraciones.avance) >=
                        graficas.migraciones.metas.plata
                          ? IconSilver
                          : IconSilverOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-silver">
                      {`${graficas.migraciones.metas.plata}`}{" "}
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-bronze">Bronce</p>
                    <img
                      src={
                        parseInt(graficas.migraciones.avance) >=
                        graficas.migraciones.metas.bronce
                          ? IconBronze
                          : IconBronzeOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-bronze">
                      {`${graficas.migraciones.metas.bronce}`}{" "}
                    </p>
                  </div>
                </div>
              ) : null
            }
            <div className="w-100 d-flex flex-column justify-content-center ml-1rem ml-lg-0">
              <div className="mx-auto max-width-256 px-1rem5 px-lg-0">
                <CircularProgressbar
                  value={normalizePercentageAbs(
                    graficas.migraciones.porcentaje
                  )}
                  text={normalizePercentage(graficas.migraciones.porcentaje)}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      stroke: `${colorSemaphore(
                        "migracion",
                        normalizePercentageAbs(graficas.migraciones.porcentaje),
                        cadenaComercial,
                        undefined,
                        false,
                        // view > 1 &&
                        normalizePercentageAbs(graficas.porcentajeIdeal)
                      )}`,
                      // Path color
                      // stroke: `${colorSemaphore(
                      //   "migracion",
                      //   normalizePercentageAbs(graficas.migraciones.porcentaje),
                      //   cadenaComercial,
                      //   undefined,
                      //   false,
                      //   view > 1 &&
                      //     normalizePercentageAbs(graficas.porcentajeIdeal)
                      // )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "reactivacion",
                        normalizePercentageAbs(graficas.migraciones.porcentaje),
                        cadenaComercial,
                        undefined,
                        false,
                        // view > 1 &&
                        normalizePercentageAbs(graficas.porcentajeIdeal)
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
              <div>
                <h6 className="text-center text-lead fw-bold mt-rem5">
                  Migraciones
                </h6>
                <div className="row mt-1rem">
                  <div className="text-left col">
                    <p className="text-lead">Avance:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">
                      {`${graficas.migraciones.avance}/${graficas.migraciones.total}`}
                    </p>
                  </div>
                </div>
                <div className="row mt-rem5">
                  <div className="text-left col">
                    <p className="text-lead">Faltan:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">{`${graficas.migraciones.faltan}`}</p>
                  </div>
                </div>
                <div className="row mt-rem5">
                  <div className="text-left col">
                    <p className="text-lead">Forecast al cierre:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">{`${graficas.migraciones.forecast}`}</p>
                  </div>
                </div>
                { ( graficas.metaCs) &&
                <div className="row mt-rem5">
                  <div className="text-left col">
                    <p className="text-lead">Meta CS:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">{`${graficas.metaCs.migraciones}`}</p>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
          {/* IPPF */}
          <div className="col-lg-2 d-flex d-lg-block separator-mobile">
            {
              // Estrellas
              graficas.ippf.metas ? (
                <div className="row pb-1rem flex-column flex-lg-row">
                  <div className="col">
                    <p className="text-gold">Oro</p>
                    <img
                      src={
                        graficas.ippf.calculado >=
                        graficas.ippf.metas.oro
                          ? IconGold
                          : IconGoldOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-gold">{`${graficas.ippf.metas.oro}`} </p>
                  </div>
                  <div className="col">
                    <p className="text-silver">Plata</p>
                    <img
                      src={
                        graficas.ippf.calculado >=
                        graficas.ippf.metas.plata
                          ? IconSilver
                          : IconSilverOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-silver">
                      {`${graficas.ippf.metas.plata}`}{" "}
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-bronze">Bronce</p>
                    <img
                      src={
                        graficas.ippf.calculado >=
                        graficas.ippf.metas.bronce
                          ? IconBronze
                          : IconBronzeOutlined
                      }
                      alt="Estrella dorada"
                    />
                    <p className="text-bronze">
                      {`${graficas.ippf.metas.bronce}`}{" "}
                    </p>
                  </div>
                </div>
              ) : null
            }
            <div className="w-100 d-flex flex-column justify-content-center ml-1rem ml-lg-0">
              <div className="mx-auto max-width-256 px-1rem5 px-lg-0">
                <CircularProgressbar
                  value={normalizePercentageAbs(graficas.ippf.calculado)}
                  text={normalizePercentage(graficas.ippf.calculado)}
                  maxValue={graficas.ippf.meta}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `${colorSemaphore(
                        "ippf",
                        normalizePercentageAbs(graficas.ippf.calculado),
                        cadenaComercial,
                        undefined,
                        false,
                        normalizePercentageAbs(graficas.ippf.meta)
                      )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "ippf",
                        normalizePercentageAbs(graficas.ippf.calculado),
                        cadenaComercial,
                        undefined,
                        false,
                        normalizePercentageAbs(graficas.ippf.meta)
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
              <div>
                <h6 className="text-center text-lead fw-bold mt-rem5">IPPF</h6>
                <div className="row mt-1rem">
                  <div className="text-left col">
                    <p className="text-lead">Meta:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">
                      {normalizePercentage(graficas.ippf.meta)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Rechazo inicial */}
          <div className="col-lg-2 d-flex d-lg-block separator-mobile">
            {graficas.reactivaciones.metas ? (
              <div className="mt-6rem"></div>
            ) : null}
            <div className="w-100 d-flex flex-column justify-content-center ml-1rem ml-lg-0">
              <div className="mx-auto max-width-256 px-1rem5 px-lg-0">
                <CircularProgressbar
                  value={normalizePercentageAbs(graficas.rechazoInicial.actual)}
                  text={normalizePercentage(graficas.rechazoInicial.actual)}
                  maxValue={graficas.rechazoInicial.meta}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `${colorSemaphore(
                        "rechazo",
                        normalizePercentageAbs(graficas.rechazoInicial.actual),
                        cadenaComercial,
                        undefined,
                        false
                      )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "rechazo",
                        normalizePercentageAbs(graficas.rechazoInicial.actual),
                        cadenaComercial,
                        undefined,
                        false
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
              <div>
                <h6 className="text-center text-lead fw-bold mt-rem5">
                  Rechazo inicial
                </h6>
                <div className="row mt-1rem">
                  <div className="text-left col">
                    <p className="text-lead">Límite:</p>
                  </div>
                  <div className="text-right col">
                    <p className="text-lead">
                      {normalizePercentage(graficas.rechazoInicial.meta)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default Graficas;
