// Libraries
import React, { useState, useEffect, useMemo, memo } from "react";
import instance from "../../../helpers/api.instance";
import { useForm } from "react-hook-form";
import { notification } from "../../../helpers/Helpers";
import ReactTooltip from "react-tooltip";
// Icons
import IconGold from "../../../assets/icons/stars/gold.svg";
import IconSilver from "../../../assets/icons/stars/silver.svg";
import IconBronze from "../../../assets/icons/stars/bronze.svg";
import IconBack from "../../../assets/icons/backArrow.svg";
import IconNext from "../../../assets/icons/nextArrow.svg";

const TablaAsesores = ({
  setloading,
  IdTienda,
  cadenaComercial,
  status,
  setstatus,
  categoria,
  setcategoria,
}) => {
  let sumOro = 0;
  let sumPlata = 0;
  let sumBronce = 0;
  // Useform para validar que todos los campos tengan un valor
  const { handleSubmit, register } = useForm();
  //Total de metas
  const [total, settotal] = useState(0);
  const [totalOro, settotalOro] = useState(0);
  const [totalPlata, settotalPlata] = useState(0);
  const [totalBronce, settotalBronce] = useState(0);
  // Metas por asignar
  const [faltantes, setfaltantes] = useState(null);
  // Maximo a asignar
  const [maximo, setmaximo] = useState(null);
  // Usuarios para cargar metas
  const [tabla, settabla] = useState(null);
  const [tablaInactivo, setTablaInactivo] = useState(null);
  const [bandera, setbandera] = useState(0);
  const [diaPermiteAsignar, setdiaPermiteAsignar] = useState(0);
  const [permiteAsignar, setpermiteAsignar] = useState(1);
  // Hooks para guardar las reactivaciones y migraciones
  const [reactivaciones, setreactivaciones] = useState(null);
  const [migraciones, setmigraciones] = useState(null);
  // Metas que se mostrarán encima de las estrellas
  const [metasreactivaciones, setmetasreactivaciones] = useState(null);
  const [metasmigraciones, setmetasmigraciones] = useState(null);
  const [metas, setmetas] = useState(null);
  const [motivosMetas, setMotivosMetas] = useState(null);
  const [mediasMeta, setMediasMeta] = useState(null);
  // Meta elegida por el usuario
  const [meta, setmeta] = useState("Oro");

  // Columnas a mostrar en la tabla
  const titulos = [
    "Nombre(s)",
    "Apellido Paterno",
    "Puesto",
    "Núm. Empleado",
    "Edita meta Oro",
    "Motivo Asigna Oro",
    "Edita meta Plata",
    "Motivo Asigna Plata",
    "Edita meta Bronce",
    "Motivo Asigna Bronce",
    "Estatus",
    "Activo",
  ];
  const titulosInactivos = [
    "Nombre(s)",
    "Apellido Paterno",
    "Puesto",
    "Núm. Empleado",
    "Estatus",
    "Activo",
  ];

  // Obtener lista de metas
  const obtenerListaMetas = () => {
    setloading(true);
    // Request para obtener el listado de metas
    instance
      .post(
        `/metas/listadoMetas`,
        // Cuerpo del request
        {
          cadenaComercial: cadenaComercial,
          numeroTienda: IdTienda,
        }
      )
      .then((res) => {
        // Si se encontró datos de la tienda
        if (res.status === 1) {
          settabla(res.data.listado);
          setTablaInactivo(res.data.usuariosInactivos);
          sumarTodo(res.data.listado);
          setMotivosMetas(res.data.motivosAsignaMetas);
          setMediasMeta(res.data.mediasMeta);
          setmigraciones(res.data.migraciones);
          setreactivaciones(res.data.reactivaciones);
          setmeta(res.data.tipoMeta);
          setmetasmigraciones(res.data.metaTienda.migraciones);
          setmetasreactivaciones(res.data.metaTienda.reactivaciones);
          settotal(
            categoria === "migraciones"
              ? parseInt(res.data.migraciones.totales)
              : parseInt(res.data.reactivaciones.totales)
          );
        }
        setstatus(res.status);
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  const sumarTodo = (tab) => {

    let sumOro = 0;
    let sumPlata = 0;
    let sumBronce = 0;
    tab.forEach((row) => {
      if (categoria === "reactivaciones") {
        sumOro += parseInt(row.reactivacionesOro);
        sumPlata += parseInt(row.reactivacionesPlata);
        sumBronce += parseInt(row.reactivacionesBronce);
      } else {
        sumOro += parseInt(row.migracionesOro);
        sumPlata += parseInt(row.migracionesPlata);
        sumBronce += parseInt(row.migracionesBronce);
      }
      if (isNaN(sumOro)) settotalOro(0);
      if (isNaN(sumPlata)) settotalPlata(0);
      if (isNaN(sumBronce)) settotalBronce(0);
      settotalOro(sumOro);
      settotalPlata(sumPlata);
      settotalBronce(sumBronce);
    });
  }
  // Función que activa / inactiva
  const toggleUsuario = (numeroEmpleado, status) => {
    setloading(true);
    instance
      .post(
        `/usuario/estatusUsuario`,
        // Cuerpo del request
        {
          idUsuario: numeroEmpleado,
          status: status,
        }
      )
      .then((res) => {
        // Si la respuesta es exitosa
        if (res.status === 1) {
          notification(res.msg, "warning");
          settabla(null);
          // Repetimos la función para obtener la lista de empleados de nuevo
          obtenerListaMetas();
        }
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  // UseEffect para obtener la información de la tabla
  useEffect(() => {
    if (IdTienda) {
      obtenerListaMetas();
    }
  }, [IdTienda, cadenaComercial]);

  // Use effect que asigna el total dependiendo de la categoría seleccionada
  useEffect(() => {
    if (status) {
      if (categoria === "reactivaciones") {
        setmaximo(parseInt(reactivaciones.totales));
        setfaltantes(reactivaciones.faltantes);
        setmetas(metasreactivaciones);
        handleChange();
      }
      if (categoria === "migraciones") {
        setmaximo(parseInt(migraciones.totales));
        setfaltantes(migraciones.faltantes);
        setmetas(metasmigraciones);
        handleChange();
      }
    }
  }, [categoria, status]);

  useEffect(() => {
    setloading(true);
    instance
      .post(
        `/metas/obtenMetasYFaltantes`,
        // Cuerpo del request
        {
          cadenaComercial: cadenaComercial,
          numeroTienda: IdTienda,
          estrellaMeta: meta,
          bandera: bandera,
          tipoMeta: categoria === "migraciones" ? "Migracion" : "Reactivacion",
        }
      )
      .then((res) => {
        if (res.status === 1) {
          setmaximo(parseInt(res.data.metasMes));
          setpermiteAsignar(res.data.permiteAsignar)
          setfaltantes(res.data.porAsignar);
          setdiaPermiteAsignar(res.data.diaPermiteAsignar);
          obtenerListaMetas();
        }
      })
      .finally(() => setloading(false));
  }, [meta]);

  const editarEstrella = (meta) => {

    return;
  }

  // Función que envía la información
  const onSubmit = (data) => {
    let menorMetas = [];
    let sinMotivoOro = [];
    let sinMotivoPlata = [];
    let sinMotivoBronce = [];

    if (categoria === 'reactivaciones') {
      menorMetas = tabla
        .filter((m) => m.bloquearSelectOro === true && m.reactivacionesOro < mediasMeta.oro.reactivacion.minimo
          || m.bloquearSelectPlata === true && m.reactivacionesPlata < mediasMeta.plata.reactivacion.minimo
          || m.bloquearSelectBronce === true && m.reactivacionesBronce < mediasMeta.bronce.reactivacion.minimo);
      if (totalOro !== parseInt(metasreactivaciones.oro)) {
        notification("No pueden sobrar ni exceder la meta de Oro: " + metasreactivaciones.oro);
        return;
      }
      if (totalBronce !== parseInt(metasreactivaciones.bronce)) {
        notification("No pueden sobrar ni exceder la meta de Bronce: " + metasreactivaciones.bronce);
        return;
      }
      if (totalPlata !== parseInt(metasreactivaciones.plata)) {
        notification("No pueden sobrar ni exceder la meta de Plata: " + metasreactivaciones.plata);
        return;
      }
      sinMotivoOro = tabla
        .filter((m) => m.bloquearSelectOro === false && !m.motivoReactivacionesOro );
      sinMotivoPlata = tabla
        .filter((m) => m.bloquearSelectPlata === false && !m.motivoReactivacionesPlata );
      sinMotivoBronce = tabla
        .filter((m) => m.bloquearSelectBronce === false && !m.motivoReactivacionesBronce );
    }
    if (categoria === 'migraciones') {
      menorMetas = tabla
        .filter((m) => m.bloquearSelectOro === true && m.migracionesOro < mediasMeta.oro.migracion.minimo
          || m.bloquearSelectPlata === true && m.migracionesPlata < mediasMeta.plata.migracion.minimo
          || m.bloquearSelectBronce === true && m.migracionesBronce < mediasMeta.bronce.migracion.minimo);

      if (totalOro !== parseInt(metasmigraciones.oro)) {
        notification("No pueden sobrar ni exceder la meta de Oro: " + metasmigraciones.oro);
        return;
      }
      if (totalBronce !== parseInt(metasmigraciones.bronce)) {
        notification("No pueden sobrar ni exceder la meta de Bronce: " + metasmigraciones.bronce);
        return;
      }
      if (totalPlata !== parseInt(metasmigraciones.plata)) {
        notification("No pueden sobrar ni exceder la meta de Plata: " + metasmigraciones.plata);
        return;
      }
      sinMotivoOro = tabla
        .filter((m) => m.bloquearSelectOro === false && !m.motivoMigracionesOro );
      sinMotivoPlata = tabla
        .filter((m) => m.bloquearSelectPlata === false && !m.motivoMigracionesPlata );
      sinMotivoBronce = tabla
        .filter((m) => m.bloquearSelectBronce === false && !m.motivoMigracionesBronce );
    }
    if (menorMetas.length > 0) {
      notification('La meta no puede ser menor al 50% de la meta dividida entre los asesores')
      return;
    }
    if (sinMotivoOro.length > 0) {
      notification('Selecciona un motivo de la meta de Oro')
      return;
    }
    if (sinMotivoPlata.length > 0) {
      notification('Selecciona un motivo de la meta de Plata')
      return;
    }
    if (sinMotivoBronce.length > 0) {
      notification('Selecciona un motivo de la meta de Bronce')
      return;
    }

    setloading(true);
    let metas = [];
    tabla.map((v) => {
      if (categoria === "reactivaciones") {
        const dict = {
          numeroEmpleado: v.numeroEmpleado,
          reactivacionesOro: v.reactivacionesOro,
          motivoReactivacionesOro: v.motivoReactivacionesOro,
          reactivacionesPlata: v.reactivacionesPlata,
          motivoReactivacionesPlata: v.motivoReactivacionesPlata,
          reactivacionesBronce: v.reactivacionesBronce,
          motivoReactivacionesBronce: v.motivoReactivacionesBronce,
        };
        metas.push(dict);
      } else {
        const dict = {
          numeroEmpleado: v.numeroEmpleado,
          migracionesOro: v.migracionesOro,
          motivoMigracionesOro: v.motivoMigracionesOro,
          migracionesPlata: v.migracionesPlata,
          motivoMigracionesPlata: v.motivoMigracionesPlata,
          migracionesBronce: v.migracionesBronce,
          motivoMigracionesBronce: v.motivoMigracionesBronce,
        };
        metas.push(dict);
      }
    });

    // Request para asignar metas a empleado
    instance
      .post(
        `/metas/asignarMetasEmpleado`,
        // Cuerpo del request
        {
          cadenaComercial: cadenaComercial,
          numeroTienda: IdTienda,
          tipoMeta: categoria,
          metas: metas,
        }
      )
      .then((res) => {
        // Si se encontró datos de la tienda
        if (res.status === 1)
          notification("Metas asignadas correctamente", "success");
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  // Función que actualiza las metas cada vez que hay un cambio
  const handleChange = () => {
    let sumOro = 0;
    let sumPlata = 0;
    let sumBronce = 0;
    tabla.forEach((row) => {
      if (categoria === "reactivaciones") {
        sumOro += parseInt(row.reactivacionesOro);
        sumPlata += parseInt(row.reactivacionesPlata);
        sumBronce += parseInt(row.reactivacionesBronce);
      } else {
        sumOro += parseInt(row.migracionesOro);
        sumPlata += parseInt(row.migracionesPlata);
        sumBronce += parseInt(row.migracionesBronce);
      }
      if (isNaN(sumOro)) settotalOro(0);
      if (isNaN(sumPlata)) settotalPlata(0);
      if (isNaN(sumBronce)) settotalBronce(0);
      settotalOro(sumOro);
      settotalPlata(sumPlata);
      settotalBronce(sumBronce);
    });
  };

  // Camia entre las categorías
  const toggleCategoria = () => {
    if (categoria === "reactivaciones") {
      settotal(parseInt(migraciones.totales));
      setcategoria("migraciones");
    }
    if (categoria === "migraciones") {
      settotal(parseInt(reactivaciones.totales));
      setcategoria("reactivaciones");
    }
  };

  // const data = useMemo(() => tabla, [tabla]);

  // Si aún no obtenemos los datos, regresamos null
  if (!reactivaciones || !migraciones || !tabla) return null;

  // Devolvemos nada mientras aún no se obtiene respuesta de la API
  if (status === null) return null;

  // Si todo sale bien, se devuelve este contenido con la tabla
  if (!tabla) return null;

  const checkIncapacidad = (row) => {
    return (
      row.motivoMigracionesOro === 'Incapacidad' ||
      row.motivoMigracionesPlata === 'Incapacidad' ||
      row.motivoMigracionesBronce === 'Incapacidad'
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="justify-content-center text-center py-1rem"
        key={categoria}
      >
        <div className="row justify-content-between align-items-center">

          {permiteAsignar === 1 && metas && (
            <>
              <div className='col-12'>
                <div className='alert alert-danger'>
                  <span>Puedes asignar la meta de tu equipo hasta el {diaPermiteAsignar} de cada mes.</span>
                </div>
              </div>
              <div className="col text-left">
                <div className="col d-flex text-left align-items-center">
                </div>
              </div>
            </>
          )}
          {permiteAsignar === 1 &&
            <div className="col text-center">
            </div>
          }
          {permiteAsignar === 0 &&
            <div
              className='alert alert-danger'
              role="alert"
            >
              La fecha límite para asignación de metas fue el {diaPermiteAsignar} de este mes
            </div>
          }
          <div
            className="col cursor-pointer text-capitalize text-right"
            onClick={() => toggleCategoria()}
          >
            <img
              src={IconBack}
              alt=""
              className="card-hover-big"
              data-tip="Cambiar de categoría"
            />
            <ReactTooltip />
            <span className="px-1rem fw-bold">{`${categoria}`}</span>
            <ReactTooltip />
            <img
              src={IconNext}
              alt=""
              className="card-hover-big"
              data-tip="Cambiar de categoría"
            />
          </div>
        </div>
        <div className="row text-left mt-1rem">
          <div className="col">
            {" "}
          </div>
        </div>
        {/* TABLA PRINCIPAL */}
        <div className="table-responsive">
          <table className="table table-striped table-hover table-sm mb-1rem">
            <thead className="thead-dark">
              <tr className='bg-white'>
                {metas && titulos.map((titulo, i) => {
                  return (
                    <th scope="col " key={titulo} className="px-1rem bg-white" style={{ borderTop: '0px' }}>
                      {i == 0 && <span className='text-white text-muted'>Metas</span>}
                      {titulo === 'Edita meta Oro' &&
                        <label
                          htmlFor="Oro"
                          className="cursor-pointer px-1rem my-0 d-flex flex-column justify-content-center text-center"
                        >
                          <p className="text-gold">{metas.oro}</p>
                          <img
                            src={IconGold}
                            alt="Estrella dorada"
                            style={{ height: "32px" }}
                            className="star"
                          />
                          <p className="text-gold">Oro</p>
                        </label>
                      }
                      {titulo === 'Edita meta Plata' &&
                        <label
                          htmlFor="Oro"
                          className="cursor-pointer px-1rem my-0 d-flex flex-column justify-content-center text-center"
                        >
                          <p className="text-silver">{metas.plata}</p>
                          <img
                            src={IconSilver}
                            alt="Estrella Plata"
                            style={{ height: "32px" }}
                            className="star"
                          />
                          <p className="text-silver">Plata</p>
                        </label>
                      }
                      {titulo === 'Edita meta Bronce' &&
                        <label
                          htmlFor="Oro"
                          className="cursor-pointer px-1rem my-0 d-flex flex-column justify-content-center text-center"
                        >
                          <p className="text-bronze">{metas.bronce}</p>
                          <img
                            src={IconBronze}
                            alt="Estrella Bronce"
                            style={{ height: "32px" }}
                            className="star"
                          />
                          <p className="text-bronze">Bronce</p>
                        </label>
                      }
                    </th>
                  );
                })}
              </tr>
              <tr>
                {titulos.map((titulo) => {
                  return (
                    <th scope="col" key={titulo} className="px-1rem">
                      {titulo}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tabla && tabla.length > 0 && tabla.map((row, i) => {
                
                if (categoria === "reactivaciones") {

                  if (parseInt(row.reactivacionesOro) < parseInt(mediasMeta.oro.reactivacion.minimo)
                    || parseInt(row.reactivacionesOro) >= parseInt(mediasMeta.oro.reactivacion.maximo)) {

                    row.bloquearSelectOro = true;
                    row.motivoReactivacionesOro = '';
                  } else {
                    row.bloquearSelectOro = false;
                  }
                  //Plata
                  if (parseInt(row.reactivacionesPlata) < parseInt(mediasMeta.plata.reactivacion.minimo)
                    || parseInt(row.reactivacionesPlata) >= parseInt(mediasMeta.plata.reactivacion.maximo)) {

                    row.bloquearSelectPlata = true;
                    row.motivoReactivacionesPlata = '';
                  } else {
                    row.bloquearSelectPlata = false;
                  }
                  //Bronce
                  if (parseInt(row.reactivacionesBronce) < parseInt(mediasMeta.bronce.reactivacion.minimo)
                    || parseInt(row.reactivacionesBronce) >= parseInt(mediasMeta.bronce.reactivacion.maximo)) {

                    row.bloquearSelectBronce = true;
                    row.motivoReactivacionesBronce = '';
                  } else {
                    row.bloquearSelectBronce = false;
                  }
                } else {
                  if (parseInt(row.migracionesOro) < parseInt(mediasMeta.oro.migracion.minimo)
                    || parseInt(row.migracionesOro) >= parseInt(mediasMeta.oro.migracion.maximo)) {
                    row.bloquearSelectOro = true;
                    row.motivoMigracionesOro = '';
                  } else {
                    row.bloquearSelectOro = false;
                  }
                  if (parseInt(row.migracionesPlata) < parseInt(mediasMeta.plata.migracion.minimo)
                    || parseInt(row.migracionesPlata) >= parseInt(mediasMeta.plata.migracion.maximo)) {
                    row.bloquearSelectPlata = true;
                    row.motivoMigracionesPlata = '';
                  } else {
                    row.bloquearSelectPlata = false;
                  }
                  if (parseInt(row.migracionesBronce) < parseInt(mediasMeta.bronce.migracion.minimo)
                    || parseInt(row.migracionesBronce) >= parseInt(mediasMeta.bronce.migracion.maximo)) {
                    row.bloquearSelectBronce = true;
                    row.motivoMigracionesBronce = '';
                  } else {
                    row.bloquearSelectBronce = false;
                  }
                }
                return (
                  <tr key={`row-${i}`} className={checkIncapacidad(row) ? 'bg-bl' : ''}>
                    <td className="px-1rem">{row.nombres}</td>
                    <td className="px-1rem">{row.apPat}</td>
                    <td className="px-1rem">{row.nombrePerfil}</td>
                    <td className="px-1rem">{row.numeroEmpleado}</td>
                    <td className="px-1rem">
                      <div className="form-label-group">
                        <input
                          type="number"
                          className="form-control text-center"
                          key={'inp-O' + i}
                          defaultValue={
                            categoria === "reactivaciones"
                              ? row.reactivacionesOro
                              : row.migracionesOro
                          }
                          name={row.numeroEmpleado + 'i' + i}
                          id={row.numeroEmpleado + 'i' + i}
                          onChange={(e) => {
                            if (categoria === "reactivaciones") {
                              row.reactivacionesOro = e.target.value;
                              console.log(row.reactivacionesOro)
                              console.log(mediasMeta.oro.reactivacion)
                              console.log(row.reactivacionesOro >= parseInt(mediasMeta.oro.reactivacion))
                              if (row.reactivacionesOro >= parseInt(mediasMeta.oro.reactivacion)) {
                                row.bloquearSelectOro = false;
                              } else {
                                row.bloquearSelectOro = true;
                                row.motivoReactivacionesOro = '';
                                const v = document.querySelector('#mmo-' + row.id);
                                v.value = '';
                              }
                            } else {
                              row.migracionesOro = e.target.value;
                              if (row.migracionesOro > mediasMeta.oro.migracion) {
                                row.bloquearSelectOro = true;
                                row.motivoMigracionesOro = '';
                              } else {
                                row.bloquearSelectOro = false;
                              }
                            }
                            handleChange(e);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <select name='motivoMigracionesOro' id={'mmo-' + row.id} className='form-control p-0 pl-1'
                        disabled={row.bloquearSelectOro}
                        defaultValue={
                          categoria === "reactivaciones"
                            ? row.motivoReactivacionesOro :
                            row.motivoMigracionesOro
                        }
                        onChange={(e) => {
                          if (categoria === "reactivaciones") {
                            row.motivoReactivacionesOro = e.target.value;
                          } else {
                            row.motivoMigracionesOro = e.target.value;
                          }
                          
                          const newRow = { ...row, motivoMigracionesOro: e.target.value };
                          const newTabla = tabla.map(r => r.id === row.id ? newRow : r);
                          settabla(newTabla);

                        }}>
                        <option value =''
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivacionesOro == '') ? 'selected' : false :
                                (row.motivoMigracionesOro == '') ? 'selected' : false
                            }
                        >seleccionar un motivo</option>
                        {motivosMetas && motivosMetas.length > 0 && motivosMetas.map((r) => {
                          return (<option key={r.motivo} value={r.motivo}
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivacionesOro == r.motivo) ? 'selected' : false :
                                (row.motivoMigracionesOro == r.motivo) ? 'selected' : false
                            }
                          >{r.motivo}</option>)

                        })
                        }
                      </select>
                    </td>
                    <td className="px-1rem">
                      <div className="form-label-group">
                        <input
                          type="number"
                          className="form-control text-center"
                          key={'inp-P' + i}
                          defaultValue={
                            categoria === "reactivaciones"
                              ? row.reactivacionesPlata
                              : row.migracionesPlata
                          }
                          name={row.numeroEmpleado}
                          id={row.numeroEmpleado}
                          onChange={(e) => {
                            if (categoria === "reactivaciones") {
                              row.reactivacionesPlata = e.target.value;
                              if (row.reactivacionesPlata < mediasMeta.plata.reactivacion) {
                                row.bloquearSelectPlata = true;
                                row.motivoReactivacionesPlata = '';
                                const v = document.querySelector('#mmp-' + row.id);
                                v.value = '';

                              } else {
                                row.bloquearSelectPlata = false;
                              }
                            } else {
                              row.migracionesPlata = e.target.value;
                              if (row.migracionesPlata < mediasMeta.plata.migracion) {
                                row.bloquearSelectPlata = true;
                                row.motivoMigracionesPlata = '';
                              } else {
                                row.bloquearSelectPlata = false;
                              }
                            }
                            handleChange(e);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <select name='motivoMigracionesPlata' id={'mmp-' + row.id} className='form-control p-0 pl-1'
                        disabled={row.bloquearSelectPlata}
                        onChange={(e) => {
                          if (categoria === "reactivaciones") {
                            row.motivoReactivacionesPlata = e.target.value;
                          } else {
                            row.motivoMigracionesPlata = e.target.value;
                          }

                          const newRow = { ...row, motivoMigracionesPlata: e.target.value };
                          const newTabla = tabla.map(r => r.id === row.id ? newRow : r);
                          settabla(newTabla);

                        }}>
                        <option value =''
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivacionesPlata == '') ? 'selected' : false :
                                (row.motivoMigracionesPlata == '') ? 'selected' : false
                            }
                        >seleccionar un motivo</option>
                        {motivosMetas && motivosMetas.length > 0 && motivosMetas.map((r) => {
                          return (<option value={r.motivo}
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivacionesPlata == r.motivo) ? 'selected' : false :
                                (row.motivoMigracionesPlata == r.motivo) ? 'selected' : false
                            }
                          >{r.motivo}</option>)
                              
                        })
                        }
                      </select>
                    </td>
                    <td className="px-1rem">
                      <div className="form-label-group">
                        <input
                          type="number"
                          key={'inp-B' + i}
                          className="form-control text-center"
                          ref={register({ required: true })}
                          defaultValue={
                            categoria === "reactivaciones"
                              ? row.reactivacionesBronce
                              : row.migracionesBronce
                          }
                          name={row.numeroEmpleado}
                          id={row.numeroEmpleado}
                          onChange={(e) => {
                            if (categoria === "reactivaciones") {
                              row.reactivacionesBronce = e.target.value;
                              if (row.reactivacionesBronce < mediasMeta.plata.reactivacion) {
                                row.bloquearSelectBronce = true;
                                row.motivoReactivacionesBronce = '';
                                const v = document.querySelector('#mmb-' + row.id);
                                v.value = '';

                              } else {
                                row.bloquearSelectBronce = false;
                              }
                            } else {
                              row.migracionesBronce = e.target.value;
                              if (row.migracionesBronce < mediasMeta.bronce.migracion) {
                                row.bloquearSelectBronce = true;
                                row.motivoMigracionesBronce = '';
                              } else {
                                row.bloquearSelectBronce = false;
                              }
                            }
                            handleChange(e);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <select name='motivoMigracionesBronce' id={'mmb-' + row.id} className='form-control p-0 pl-1'
                        disabled={row.bloquearSelectBronce}
                        onChange={(e) => {
                          if (categoria === "reactivaciones") {
                            row.motivoReactivacionesBronce = e.target.value;
                          } else {
                            row.motivoMigracionesBronce = e.target.value;
                          }

                          const newRow = { ...row, motivoMigracionesBronce: e.target.value };
                          const newTabla = tabla.map(r => r.id === row.id ? newRow : r);
                          settabla(newTabla);

                        }}>
                        <option value =''
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivacionesBronce == '') ? 'selected' : false :
                                (row.motivoMigracionesBronce == '') ? 'selected' : false
                            }
                        >seleccionar un motivo</option>
                        {motivosMetas && motivosMetas.length > 0 && motivosMetas.map((r) => {
                          return (<option value={row.motivo}
                            selected={
                              categoria === "reactivaciones"
                                ? (row.motivoReactivacionesBronce == r.motivo) ? 'selected' : false :
                                (row.motivoMigracionesBronce == r.motivo) ? 'selected' : false
                            }
                          >{r.motivo}</option>)
                          
                        })
                        }
                      </select>
                    </td>
                    <td className="px-1rem">
                      {
                        row.status}
                    </td>
                    <td>
                      {permiteAsignar === 1 &&
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            onChange={() => {
                              toggleUsuario(row.id, 'Inactivo');
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      }
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <p>{totalOro}</p>
                </td>
                <td></td>
                <td>
                  <p>{totalPlata}</p>
                </td>
                <td></td>
                <td>
                  <p>{totalBronce}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* FIN TABLA PRINCIPAL */}
        <div
          className={`alert alert-danger ${total > maximo ? `` : `d-none`}`}
          role="alert"
        >
          La meta máxima que puedes dividir es la de oro.
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col">
            {permiteAsignar === 1 &&
              <button className="btn btn-primary my-4" type="submit">
                Guardar
              </button>
            }
          </div>
          <div className="col"></div>
        </div>
      </form>
      {tablaInactivo && tablaInactivo.length > 0 &&
        <div className='col-md-12 my-3'>
          <h2 className='text-center my-5'>Usuarios Inactivos del Mes</h2>
          <div className="table-responsive">
            <table className="table table-striped table-hover table-sm mb-1rem">
              <thead className="thead-dark">
                <tr>
                  {titulosInactivos.map((titulo) => {
                    return (
                      <th scope="col" key={titulo} className="px-1rem text-center">
                        {titulo}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tablaInactivo && tablaInactivo.length > 0 && tablaInactivo.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className='px-1rem text-center'>{v.nombres}</td>
                      <td className='px-1rem text-center'>{v.apPat}</td>
                      <td className='px-1rem text-center'>{v.nombrePerfil}</td>
                      <td className='px-1rem text-center'>{v.numeroEmpleado}</td>
                      <td className='px-1rem text-center'>{v.status}</td>
                      <td className='px-1rem text-center'>
                        {permiteAsignar === 1 &&
                          <label className="switch text-center">
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              onChange={() => {
                                toggleUsuario(v.id, 'Activo');
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  );
};

export default TablaAsesores;
