// Este documento se debe actualizar cada vez que se actualicen los perfiles o sus permisos

const asignarPermisos = (clavePerfil) => {
  const usuario = {
    Administrador: {
      esAdmin: true,
      tipo: 1,
      tiene: {
        nombres: true,
        apPat: true,
        apMat: true,
        correo: true,
        celular: false,
        numeroEmpleado: true,
        cadenaComercial: true,
        zona: false,
        tienda: false,
        genero: true,
        dashboard: false,
        avances: false,
        inicialesExtra: true,
      },
      puede: {
        ver: {
          estadisticas: {
            zonas: true,
            tiendas: true,
            sutienda: true,
            otrastiendas: true,
            otrosasesores: true,
            suzona: true,
            otraszonas: true,
            otros: true,
          },
          usuarios: {
            todos: true,
            otros: true,
            administrador: true,
            jefe_de_credito: true,
            asesor: true,
            css: true,
          },
          administracionDeMetas: {
            todos: true,
            sutienda: true,
          },
          medallero: {
            todo: true,
            zonas: true,
            tiendas: true,
            sutienda: true,
            otros: true,
          },
          cargaDeArchivos: true,
          home: true,
        },
        modificar: {
          estadisticas: false,
          usuarios: {
            todos: true,
            otros: true,
            administrador: true,
            jefe_de_credito: true,
            asesor: true,
            css: true,
          },
          administracionDeMetas: {
            todos: true,
            sutienda: true,
          },
          medallero: true,
          cargaDeArchivos: true,
          home: true,
          perfil: true,
        },
        entrarA: {
          home: true,
          estadisticas: true,
          medallero: true,
          editarMedallero: true,
          administracionDeMetas: true,
          usuarios: true,
          cargaDeArchivos: true,
          perfil: true,
        },
      },
    },
    JefeDeCredito: {
      esAdmin: false,
      tipo: 2,
      tiene: {
        nombres: true,
        apPat: true,
        apMat: true,
        correo: true,
        celular: true,
        numeroEmpleado: true,
        cadenaComercial: true,
        zona: true,
        tienda: true,
        genero: true,
        dashboard: true,
      },
      puede: {
        ver: {
          estadisticas: {
            zonas: false,
            tiendas: false,
            sutienda: true,
            otrastiendas: false,
            otrosasesores: true,
            suzona: true,
            otraszonas: false,
            otros: false,
          },
          usuarios: {
            todos: false,
            otros: true,
            administrador: false,
            jefe_de_credito: false,
            asesor: true,
            css: true,
          },
          administracionDeMetas: {
            todos: false,
            sutienda: true,
          },
          medallero: {
            todo: false,
            zonas: true,
            tiendas: true,
            sutienda: true,
            suzona: true,
            otros: false,
          },
          cargaDeArchivos: false,
          home: true,
        },
        modificar: {
          estadisticas: false,
          usuarios: {
            todos: false,
            otros: true,
            sususuarios: true,
            administrador: false,
            jefe_de_credito: false,
            asesor: true,
            css: true,
          },
          administracionDeMetas: {
            todos: false,
            sutienda: true,
          },
          medallero: false,
          cargaDeArchivos: false,
          home: false,
          perfil: true,
        },
        entrarA: {
          home: true,
          estadisticas: true,
          medallero: true,
          editarMedallero: false,
          administracionDeMetas: true,
          usuarios: true,
          cargaDeArchivos: false,
          perfil: true,
        },
      },
    },
    Asesor: {
      esAdmin: false,
      tipo: 3,
      tiene: {
        nombres: true,
        apPat: true,
        apMat: true,
        correo: true,
        celular: true,
        numeroEmpleado: true,
        cadenaComercial: true,
        zona: true,
        tienda: true,
        genero: true,
        dashboard: true,
      },
      puede: {
        ver: {
          estadisticas: {
            zonas: false,
            tiendas: false,
            sutienda: true,
            otrastiendas: false,
            otraszonas: false,
            otrosasesores: false,
            suzona: false,
            suyas: true,
            otros: false,
          },
          usuarios: {
            todos: false,
            otros: false,
            administrador: false,
            jefe_de_credito: false,
            asesor: true,
            css: true,
          },
          administracionDeMetas: {
            todos: false,
            sutienda: false,
          },
          medallero: {
            todo: false,
            zonas: false,
            tiendas: false,
            sutienda: true,
            suzona: true,
            otros: false,
          },
          cargaDeArchivos: false,
          home: true,
        },
        modificar: {
          estadisticas: false,
          usuarios: {
            todos: false,
            otros: false,
            sususuarios: false,
            administrador: false,
            jefe_de_credito: false,
            asesor: false,
            css: false,
          },
          administracionDeMetas: {
            todos: false,
            sutienda: false,
          },
          medallero: false,
          cargaDeArchivos: false,
          home: false,
          perfil: true,
        },
        entrarA: {
          home: true,
          estadisticas: true,
          medallero: true,
          editarMedallero: false,
          administracionDeMetas: false,
          usuarios: false,
          cargaDeArchivos: false,
          perfil: true,
        },
      },
    },
    Consultor: {
      esAdmin: false,
      tipo: 1,
      tiene: {
        nombres: true,
        apPat: true,
        apMat: true,
        correo: true,
        celular: false,
        numeroEmpleado: true,
        cadenaComercial: true,
        zona: false,
        tienda: false,
        genero: true,
        dashboard: true,
      },
      puede: {
        ver: {
          estadisticas: {
            zonas: true,
            tiendas: true,
            sutienda: true,
            otrastiendas: true,
            otraszonas: true,
            otrosasesores: true,
            suzona: true,
            suyas: true,
            otros: true,
          },
          usuarios: {
            todos: false,
            otros: false,
            administrador: false,
            jefe_de_credito: false,
            asesor: false,
            css: false,
          },
          administracionDeMetas: {
            todos: false,
            sutienda: false,
          },
          medallero: {
            todo: true,
            zonas: true,
            tiendas: true,
            sutienda: true,
            suzona: true,
            otros: true,
          },
          cargaDeArchivos: false,
          home: true,
        },
        modificar: {
          estadisticas: false,
          usuarios: {
            todos: false,
            otros: false,
            sususuarios: false,
            administrador: false,
            jefe_de_credito: false,
            asesor: false,
            css: false,
          },
          administracionDeMetas: {
            todos: false,
            sutienda: false,
          },
          medallero: false,
          cargaDeArchivos: false,
          home: false,
          perfil: true,
        },
        entrarA: {
          home: true,
          estadisticas: true,
          medallero: true,
          editarMedallero: false,
          administracionDeMetas: false,
          usuarios: false,
          cargaDeArchivos: false,
          perfil: true,
        },
      },
    },
    Coordinador: {
      esAdmin: false,
      tipo: 1,
      tiene: {
        nombres: true,
        apPat: true,
        apMat: true,
        correo: true,
        celular: true,
        numeroEmpleado: true,
        cadenaComercial: true,
        zona: true,
        zonas: true,
        otraszonas: true,
        tienda: false,
        genero: true,
        dashboard: false,
        avances: false,
        inicialesExtra: true,
      },
      puede: {
        ver: {
          estadisticas: {
            zonas: true,
            tiendas: true,
            sutienda: true,
            otrastiendas: true,
            otraszonas: false,
            otrosasesores: true,
            suzona: true,
            otros: true,
          },
          usuarios: {
            todos: false,
            otros: true,
            administrador: false,
            jefe_de_credito: true,
            asesor: true,
            css: true,
          },
          administracionDeMetas: {
            todos: false,
            sutienda: false,
          },
          medallero: {
            todo: false,
            zonas: true,
            tiendas: true,
            sutienda: true,
            otros: true,
          },
          cargaDeArchivos: false,
          home: true,
        },
        modificar: {
          estadisticas: false,
          usuarios: {
            todos: false,
            otros: true,
            administrador: false,
            jefe_de_credito: true,
            asesor: true,
            css: true,
          },
          administracionDeMetas: {
            todos: true,
            sutienda: true,
          },
          medallero: false,
          cargaDeArchivos: true,
          home: true,
          perfil: true,
        },
        entrarA: {
          home: true,
          estadisticas: true,
          medallero: true,
          editarMedallero: false,
          administracionDeMetas: false,
          usuarios: true,
          cargaDeArchivos: false,
          perfil: true,
        },
      },
    },
  };

  // Validaciones
  if (clavePerfil === "Administrador_PIF" || clavePerfil === "Administrador_PIF_suburbia") {
    let u = { ...usuario.Administrador };
    u.puede.ver.administracionDeMetas['csscredito'] = true;
    u.puede.ver.administracionDeMetas['credito'] = true;
    return u
  }
  if (
    clavePerfil === "Jefe_credito_liverpool" ||
    clavePerfil === "Jefe_credito_css_liverpool" ||
    clavePerfil === "Jefe_credito_suburbia" ||
    clavePerfil === "Jefe_credito_css_suburbia" ||
    clavePerfil === "Supervisor_credito_liverpool" ||
    clavePerfil === "Supervisor_credito_suburbia" ||
    clavePerfil === "Supervisor_credito_css_liverpool"
  ) {
    let u = { ...usuario.JefeDeCredito };
    u.puede.ver.administracionDeMetas['csscredito'] = false;
    if (
      clavePerfil === "Jefe_credito_liverpool" ||
      clavePerfil === "Jefe_credito_suburbia" ||
      clavePerfil === "Supervisor_credito_liverpool" ||
      clavePerfil === "Supervisor_credito_suburbia"
    ) {
      u.puede.ver.administracionDeMetas['credito'] = true;
    } else {
      u.puede.ver.administracionDeMetas['credito'] = false;
    }
    return u
  }
  if (
    clavePerfil === "Asesor_tienda_liverpool" ||
    clavePerfil === "Asesor_tienda_css_liverpool" ||
    clavePerfil === "Asesor_tienda_suburbia" ||
    clavePerfil === "Asesor_tienda_css_suburbia"
  ) {
    let u = { ...usuario.Asesor };
    u.puede.ver.administracionDeMetas['csscredito'] = false;
    if (
      clavePerfil === "Asesor_tienda_liverpool" ||
      clavePerfil === "Asesor_tienda_suburbia"
    ) {
      u.puede.ver.administracionDeMetas['credito'] = true;
    } else {
      u.puede.ver.administracionDeMetas['credito'] = false;
    }
    return u;
  }
  if (
    clavePerfil === "Consultor_informacion_liverpool" ||
    clavePerfil === "Consultor_informacion_suburbia"
  )
    return usuario.Consultor;
  if (
    clavePerfil === "Coordinador_zona_suburbia" ||
    clavePerfil === "Coordinador_zona_css_suburbia" ||
    clavePerfil === "Coordinador_zona_liverpool" ||
    clavePerfil === "Territorial_credito_suburbia" ||
    clavePerfil === "Territorial_credito_css_suburbia"
  )
    return usuario.Coordinador;
};

export default asignarPermisos;
