import { useEffect, useState } from "react"
import instance from "../../../helpers/api.instance";
import flechaVerde from "../../../assets/icons/flechaVerde.svg";
import flechaRoja from "../../../assets/icons/flechaRoja.svg";
import flechaAmarilla from "../../../assets/icons/flechaAmarilla.svg";
import DownloadButton from '../../../components/general/DownloadButton';

export const ModalHistorico = ({ cadenaComercial, numeroTienda, tienda }) => {
  const [cargando, setCargando] = useState(true);
  const [abierto, setAbierto] = useState(false);
  const [historico, setHistorico] = useState([]);
  useEffect(() => {
    instance
      .post(
        "/avances/historicoTienda",
        { cadenaComercial, numeroTienda }
      )
      .then((res) => {
        if (res.status === 1) {
          setHistorico(res.data.historico)
        }
        if (res.status === 0) setHistorico([]);
      })
      .finally(() => setCargando(false));

  }, [0])

  const flecha = {
    width: '25px',
    height: '25px'
  }
  const modalStylo = {
    position: 'fixed',
    zIndex: '99',
    backgroundColor: 'rgba(1,1,1,.4)',
    width: '98.5%',
    height: '100%',
    left: '0',
    height: '100%',
    top: '0'
  }
  const modalContentStylo = {
    backgroundColor: '#fefefe',
    margin: '8% auto', // auto center
    padding: '20px',
    border: '1px solid #888',
    width: '95%',
    borderRadius: '10px'
  }
  const modalClose = {
    color: '#aaa',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  }

  const normalizePercentage = (number) => {
    if (isNaN(number)) {
      return `${number}`;
    } else {
      return `${Number(number).toFixed(2)}%`;
    }
  };

  return (
    <>
      <div className='row justify-content-end'>
        <div className='col-4'>
          <button id="ranking-tienda-historico" onClick={() => { setAbierto(true) }} className="btn btn-primary mt-2 mb-2" type="button">Desempeño histórico de la tienda </button>
        </div>
      </div>
      {abierto &&
        <div style={modalStylo} >
          <div className='row justify-content-center align-item-center'>

            <div style={modalContentStylo}>
              <span style={modalClose} onClick={() => { setAbierto(false) }}>&times;</span>
              <div className='row'>
                <div className='col-6'>
                  <h6>Últimos 12 meses {tienda}</h6>
                </div>

                <div className='col-6 text-center'>
                  <DownloadButton id={'historicoTienda'} />
                </div>
              </div>
              <div className="table-responsive scroll-x">
                <table id='historicoTienda' className='table table-hover table-sm'>
                  <thead className=''>
                    <tr>
                      <th className='align-middle table-dark'></th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.nombreMes + h.anio}>{h.nombreMes.substring(0, 3)} - {h.anio}</td>)
                      })}
                    </tr>
                    <tr>
                      <th className='align-middle table-dark'>Reactivaciones</th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.totalreactivaciones + h.mes}>{h.totalreactivaciones}</td>)
                      })}
                    </tr>
                    <tr>
                      <th className='align-middle table-dark'>Migraciones</th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.totalMigraciones + h.mes}>{h.totalMigraciones}</td>)
                      })}
                    </tr>
                    <tr>
                      <th className='align-middle table-dark'>IPPF</th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.ippf + h.mes}>{h.ippf}%</td>)
                      })}
                    </tr>
                    <tr>
                      <th className='align-middle table-dark'>Rechazo Inicial</th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.rechazo + h.mes}>{h.rechazo}%</td>)
                      })}
                    </tr>
                    <tr>
                      <th className='align-middle table-dark'>Estrellas</th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.nombreEstrella + h.mes}>{h.nombreEstrella}
                          {h.flecha && h.flecha === 'Verde' && (<img src={flechaVerde} style={flecha} alt='flecha verde' />)}
                          {h.flecha && h.flecha === 'Roja' && (<img src={flechaRoja} style={flecha} alt='flecha roja' />)}
                          {h.flecha && h.flecha === 'Amarilla' && (<img src={flechaAmarilla} style={flecha} alt='flecha amarilla' />)}
                        </td>)
                      })}
                    </tr>
                    <tr>
                      <th className='align-middle table-dark'>% Básico Total</th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.basicoTotalPorcentaje + h.mes}>{normalizePercentage(h.basicoTotalPorcentaje)}</td>)
                      })}
                    </tr>
                    <tr>
                      <th className='align-middle table-dark'>Comisiones pagadas</th>
                      {!cargando && historico.length > 0 && historico.map(h => {
                        return (<td key={h.totalComisionesPagadas + h.mes}>{ (h.totalComisionesPagadas).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) }</td>)
                      })}
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
