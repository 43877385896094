// Libraries
import React, { useState, useEffect } from "react";
import IconProfile from "../../assets/icons/profile_black.svg";
import { useForm } from "react-hook-form";
import swal from "@sweetalert/with-react";
// Helpers
import instance from "../../helpers/api.instance";
import asignarPermisos from "../../helpers/permissions";
// Components
import AlertForm from "../../components/general/AlertForm";
import { notification } from "../../helpers/Helpers";

const EditarUsuario = ({ usuario, setloading, userInfo, masterUser }) => {
  // Hook para saber si los campos son editables
  const [readOnly, setreadOnly] = useState(true);
  // React Form Hook
  const { register, handleSubmit, errors } = useForm();
  // Perfiles que se guardarán en el hook
  const [perfiles, setperfiles] = useState(null);
  // zonas
  const [zonas, setZonas] = useState(null);
  // Tiendas a mostrar
  const [tiendas, settiendas] = useState(null);
  // Form hooks
  const [perfilSeleccionado, setperfilSeleccionado] = useState(null);
  // Clave de perfil del usuario
  const [usuarioAEditar, setUsuarioAEditar] = useState(
    asignarPermisos(usuario.clavePerfil)
  );
  // Cadena comercial (en caso de que necesite cambiarse)
  const [cadenaComercial, setCadenaComercial] = useState(
    usuario.cadenaComercial
  );

  // Hook para obtener el listado de perfiles de una tienda
  useEffect(() => {
    setloading(true);
    if (masterUser.tipo === 1) {
      // Obtenemos el listado de perfiles
      instance
        .get(`/usuario/listadoPerfiles/${cadenaComercial}`)
        .then((res) => {
          // Guardamos los perfiles en el hook
          setperfiles(res.data.perfiles);
        })
        // Desactivamos el loader una vez hecho el request
        .finally(() => setloading(false));
    } else if (masterUser.tipo === 2) {
      // Obtenemos el listado de perfiles
      instance
        .post(
          `/usuario/perfilesJefeCredito`, //Cuerpo del request
          {
            cadenaComercial: userInfo.cadenaComercial,
          }
        )
        .then((res) => {
          // Guardamos los perfiles en el hook
          setperfiles(res.data.perfiles);
        })
        // Desactivamos el loader una vez hecho el request
        .finally(() => setloading(false));
    }
  }, [cadenaComercial, userInfo]);

  // Hook para obtener la lista de tiendas de una cadena comercial
  useEffect(() => {
    if (cadenaComercial !== null) {
      setloading(true);
      instance
        .get(`/tiendas/listaTiendas/${cadenaComercial}`)
        .then((response) => {
          settiendas(response.data.tiendas);
        })
        .finally(() => setloading(false));
    }
  }, [cadenaComercial]);

  useEffect(() => {
    if (usuarioAEditar && usuarioAEditar.tiene.zonas) {
      setloading(true);
      instance
        .get(`tiendas/catalogoZonas/${cadenaComercial}`)
        .then((res) => setZonas(res.data.zonas))
        .finally(() => setloading(false));
    }
  }, [usuarioAEditar]);

  // Función para resetear la contraseña
  const resetPassword = () => {
    return swal("¿Está seguro de restablecer la contraseña?", {
      buttons: {
        cancel: "Cancelar",
        restablecer: {
          text: "Restablecer",
          closeModal: false,
        },
      },
      icon: "warning",
    }).then((value) => {
      if (value === "restablecer") {
        instance
          .post(`/usuario/reiniciaPassword`, {
            idUsuario: usuario.idUsuario,
          })
          .then((res) => {
            if (res.status === 1) {
              swal({
                title: "Contraseña reestablecida exitosamente",
                closeOnEsc: false,
                closeOnClickOutside: false,
                text: `Nueva contraseña: ${res.data.password}`,
                icon: "success",
              });
            } else {
              swal({
                title: "Ocurrió un error al intentar cambiar la contraseña.",
                text: "Por favor, intenta de nuevo más tarde.",
                icon: "error",
              });
            }
          });
      }
    });
  };

  // Función para enviar la información a la api
  const onSubmit = (data) => {
    console.log(usuario);
    setloading(true);
    if (!readOnly) {
      if (masterUser.tipo !== 1) {
        data.cadenaComercial = usuario.cadenaComercial;
      }
      data.idUsuario = usuario.idUsuario;
      instance
        .post(`/usuario/actualizar`, data)
        .then((res) => {
          if (res.status === 1) notification(res.msg, "success");
        })
        .finally(() => setloading(false));
    }
  };

  // Cambia el perfil seleccionado y le asigna permisos
  const handleUsuario = (usuario) => {
    setperfilSeleccionado(usuario);
    setUsuarioAEditar(asignarPermisos(usuario));
  };

  return (
    <div className="card rounded">
      <div className="row text-center">
        <div className="col">
          <img src={IconProfile} alt="Icono de usuario" />
        </div>
      </div>
      <h3 className="mb-3rem text-center mt-1rem">
        Editar información de {usuario.nombres}
      </h3>
      <div className="row">
        <div className="col-3 d-none d-lg-block"></div>
        <div className="col">
          <form onSubmit={handleSubmit(onSubmit)} className="text-center">
            {/* Cadena comercial */}
            {masterUser.tipo === 1
              ? usuarioAEditar.tiene.cadenaComercial && (
                  <div className="row form-label-group">
                    <div className="col-6">
                      <label htmlFor="cadenaComercial">
                        Línea de negocio*:
                      </label>
                    </div>
                    <div className="col-6">
                      <select
                        name="cadenaComercial"
                        id="cadenaComercial"
                        className="form-control mb-rem5"
                        ref={register({ required: true })}
                        onChange={(event) => {
                          setCadenaComercial(event.target.value);
                        }}
                        readOnly={readOnly}
                      >
                        <option value="">Selecciona</option>
                        <option
                          value="1"
                          selected={
                            usuario.cadenaComercial === "1" ? true : false
                          }
                        >
                          Liverpool
                        </option>
                        <option
                          value="2"
                          selected={
                            usuario.cadenaComercial === "2" ? true : false
                          }
                        >
                          Suburbia
                        </option>
                      </select>
                      {errors.cadenaComercial && <AlertForm />}
                    </div>
                  </div>
                )
              : null}
            {/* END OF: Cadena comercial */}

            {/* Tipo de usuario */}
            {perfiles && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="clavePerfil">Tipo de usuario*:</label>
                </div>
                <div className="col-6">
                  <select
                    name="clavePerfil"
                    className="form-control mb-rem5"
                    ref={register({ required: true })}
                    onChange={(e) => {
                      handleUsuario(e.target.value);
                    }}
                    readOnly={readOnly}
                  >
                    <option value="">Selecciona</option>
                    {perfiles.map((perfil) => {
                      return (
                        <option
                          value={perfil.clavePerfil}
                          key={perfil.id}
                          selected={
                            usuario.clavePerfil === perfil.clavePerfil
                              ? true
                              : false
                          }
                        >
                          {perfil.nombrePerfil}
                        </option>
                      );
                    })}
                  </select>
                  {errors.clavePerfil && <AlertForm />}
                </div>
              </div>
            )}
            {/* end of: tipo de usuario */}

            {zonas
              ? usuarioAEditar.tiene.zonas && (
                  <div className="row form-label-group">
                    <div className="col-6">
                      <label htmlFor="Zonas">Zonas*:</label>
                    </div>
                    <div className="col-6">
                      <select
                        name="zonas"
                        id="zonas"
                        className="form-control mb-rem5"
                        ref={register({ required: true })}
                        multiple={true}
                        data-style="bg-white rounded-pill px-4 py-3 shadow-sm "
                      >
                        <option value="">Selecciona</option>
                        {tiendas
                          ? zonas.map((zona) => {
                              return (
                                <option value={zona.zona} key={zona.zona}>
                                  {zona.zona}
                                </option>
                              );
                            })
                          : null}
                      </select>
                      {errors.tienda && <AlertForm />}
                    </div>
                  </div>
                )
              : null}

            {/* Tienda */}
            {usuarioAEditar.tiene.tienda && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="numeroTienda">Tienda*:</label>
                </div>
                <div className="col-6">
                  <select
                    name="numeroTienda"
                    id="numeroTienda"
                    className="form-control mb-rem5"
                    ref={register({ required: true })}
                    readOnly={readOnly}
                  >
                    <option value="">Selecciona</option>
                    {tiendas &&
                      tiendas.map((tienda) => {
                        return (
                          <option
                            value={tienda.numeroTienda}
                            key={tienda.id}
                            selected={
                              usuario.numeroTienda === tienda.numeroTienda
                                ? true
                                : false
                            }
                          >
                            {tienda.numeroTienda} - {tienda.nombreTienda}
                          </option>
                        );
                      })}
                  </select>
                  {errors.tienda && <AlertForm />}
                </div>
              </div>
            )}
            {/* END OF: Tienda */}

            {/* Nombre */}
            {usuarioAEditar.tiene.nombres && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="nombres">Nombre(s)*:</label>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    name="nombres"
                    className="form-control mb-rem5"
                    defaultValue={usuario.nombres && usuario.nombres}
                    ref={register({ required: true })}
                    readOnly={readOnly}
                  />
                  {errors.nombres && <AlertForm />}
                </div>
              </div>
            )}
            {/* END OF: Nombre */}

            {/* Apellido paterno */}
            {usuarioAEditar.tiene.apPat && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="apPat">Apellido paterno*:</label>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    name="apPat"
                    className="form-control mb-rem5"
                    defaultValue={usuario.apPat && usuario.apPat}
                    ref={register({ required: true })}
                    readOnly={readOnly}
                  />
                  {errors.apPat && <AlertForm />}
                </div>
              </div>
            )}
            {/* END OF: Apellido paterno */}

            {/* Apellido materno */}
            {usuarioAEditar.tiene.apMat && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="apMat">Apellido materno*:</label>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    name="apMat"
                    id="apMat"
                    className="form-control mb-rem5"
                    defaultValue={usuario.apMat && usuario.apMat}
                    ref={register({ required: true })}
                    readOnly={readOnly}
                  />
                  {errors.apMat && <AlertForm />}
                </div>
              </div>
            )}
            {/* END OF: Apellido materno */}

            {/* No. Empleado */}
            {usuarioAEditar.tiene.numeroEmpleado && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="numeroEmpleado">No. de empleado*:</label>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    name="numeroEmpleado"
                    className="form-control mb-rem5"
                    defaultValue={
                      usuario.numeroEmpleado && usuario.numeroEmpleado
                    }
                    ref={register({ required: true })}
                    readOnly={readOnly}
                  />
                  {errors.numeroEmpleado && <AlertForm />}
                </div>
              </div>
            )}
            {/* END OF: No. Empleado */}

            {/* Correo */}
            {usuarioAEditar.tiene.correo && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="correo">Correo:</label>
                </div>
                <div className="col-6">
                  <input
                    type="email"
                    name="correo"
                    className="form-control mb-rem5"
                    ref={register({ required: false })}
                    defaultValue={usuario.correo && usuario.correo}
                    readOnly={readOnly}
                  />
                  {errors.correo && <AlertForm />}
                </div>
              </div>
            )}
            {/* END OF: Correo */}

            {/* Celular */}
            {usuarioAEditar.tiene.celular && (
              <div className="row form-label-group">
                <div className="col-6">
                  <label htmlFor="celular">Celular:</label>
                </div>
                <div className="col-6">
                  <input
                    type="tel"
                    name="celular"
                    className="form-control mb-rem5"
                    defaultValue={usuario.celular && usuario.celular}
                    ref={register({ required: false })}
                    readOnly={readOnly}
                  />
                  {errors.celular && <AlertForm />}
                </div>
              </div>
            )}
            {/* END OF: Celular */}

            {/* Género */}
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="genero">Género:</label>
              </div>
              <div className="col-6">
                <select
                  name="genero"
                  className="form-control mb-rem5"
                  ref={register({ required: false })}
                  readOnly={readOnly}
                >
                  <option value="">Selecciona</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otro">Otro</option>
                </select>
                {errors.genero && <AlertForm />}
              </div>
            </div>
            {/* END OF: Género */}

            {!readOnly && (
              <button className="btn btn-outline-info mt-1rem5">
                Actualizar usuario
              </button>
            )}
          </form>
          {readOnly && (
            <div className="text-center">
              {" "}
              <button
                className="btn btn-info mt-1rem5"
                onClick={() => setreadOnly(false)}
              >
                Editar usuario
              </button>
            </div>
          )}
          <div className="text-center">
            {masterUser.puede.modificar.usuarios.otros && (
              <button
                className="btn mt-1rem btn btn-danger"
                onClick={() => resetPassword()}
              >
                Restablecer contraseña
              </button>
            )}
          </div>
        </div>
        <div className="col-3 d-none d-lg-block"></div>
      </div>
    </div>
  );
};

export default EditarUsuario;
