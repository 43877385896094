import React, { useState, useEffect, useMemo } from "react";
// Libraries
import instance from "../../helpers/api.instance";
import { useHistory, Link } from "react-router-dom";
// Components
import DataTableUsuarios from "../../components/general/DataTableUsuarios";
import { notification } from "../../helpers/Helpers";
import EditarUsuario from "./EditarUsuario";

const ListaUsuarios = ({ setloading, userInfo, usuario }) => {
  // Hook para acceder al historial de la app
  let history = useHistory();
  // Hook para saber cuál componente cargar
  const [view, setview] = useState(1);
  // Trigger para que se vuelva a detonar el useEffect al actualizar datos
  const [triggerEffect, settriggerEffect] = useState(0);
  // Lista de usuarios a mostrar
  const [listaUsuarios, setlistaUsuarios] = useState(null);
  // Listado de perfiles en los que se puede buscar usuarios
  const [listaPerfiles, setlistaPerfiles] = useState(null);
  // Tipo de usuarios a mostrar
  const [clavePerfil, setclavePerfil] = useState(
    userInfo.tipo === 1
      ? userInfo.clavePerfil
      : userInfo.cadenaComercial === "1"
        ? "Asesor_tienda_liverpool"
        : "Asesor_tienda_suburbia"
  );
  // Perfil al que vamos a editar
  const [perfil, setperfil] = useState(null);
  // Cadena comercial entre la que se eligirán los tipos de perfiles
  const [cadenaComercial, setCadenaComercial] = useState(
    userInfo.cadenaComercial
  );

  const [permiteAsignar, setpermiteAsignar] = useState(1);

  // UseEffect para obtener los tipos de perfiles que se pueden tener
  useEffect(() => {
    setloading(true);
    instance
      .get(`/usuario/listadoPerfiles/${cadenaComercial}`)
      .then((res) => setlistaPerfiles(res.data.perfiles))
      // Apagamos el loader
      .finally(() => setloading(false));
  }, [cadenaComercial]);


  // Use Effect que trae la lista de usuarios
  useEffect(() => {
    setloading(true);
    // Obtenemos la lista de ususarios disponibles
    if (usuario.puede.ver.usuarios.todos) {
      instance
        .post(
          `/usuario/listaUsuarios`,
          // Cuerpo del request
          {
            clavePerfil: clavePerfil,
            cadenaComercial: cadenaComercial,
            numeroTienda: userInfo.numeroTienda,
          }
        )
        .then((res) => {
          if (res.status === 1) {
            setlistaUsuarios(res.data.usuarios);
            setpermiteAsignar(res.data.banderaMenu);
          } else {
            setlistaUsuarios(0);
          }
        })
        // Apagamos el loader
        .finally(() => setloading(false));
    } else {
      instance
        .post(
          `/usuario/listaUsuariosTienda`,
          // Cuerpo del request
          {
            clavePerfil: clavePerfil,
            cadenaComercial: cadenaComercial,
            numeroTienda: userInfo.numeroTienda,
          }
        )
        .then((res) => {
          if (res.status === 1) {
            setlistaUsuarios(res.data.usuarios);
          } else {
            setlistaUsuarios(0);
          }
        })
        // Apagamos el loader
        .finally(() => setloading(false));
    }
  }, [clavePerfil, triggerEffect]);

  // Damos de baja/alta un usuario
  const toggleUsuario = (usuario, status) => {
    setloading(true);
    instance
      .post(
        `/usuario/estatusUsuario`,
        // Cuerpo del request
        {
          idUsuario: usuario.idUsuario,
          status: status,
        }
      )
      .then((res) => {
        // Si la respuesta es exitosa
        console.log(res.status)
        if (res.status === 1) {
          notification(res.msg, "warning");
          // Activamos el trigger para que el useEffect se actualice
          settriggerEffect(usuario.idUsuario + '-' + status);
        }
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  // Al presionarse el botón de añadir usuario, lo llevamos a la página para añadir usuarios
  const onAnadir = () => {
    history.push(`${process.env.PUBLIC_URL}/anadir-usuario`);
  };

  // Funciones para manejar cambio de filtros
  const handlePerfil = (e) => setclavePerfil(e.target.value);
  const handleCadenaComercial = (e) => setCadenaComercial(e.target.value);
  const handleEditar = (usuario) => {
    setview(2);
    setperfil(usuario);
  };


  if (view === 1)
    return (
      <div className="card pt-1rem">
        <h3 className="text-center mb-3rem">Lista de usuarios</h3>
        <div className="justify-content-center">
          <div className="row justify-content-between">
            {
              // Si el usuario es administrador, mostramos filtros de tienda y tipo de usuario
              usuario.puede.ver.usuarios.todos && (
                <>
                  <div className="col">
                    <div className="form-label-group">
                      Tipo de usuario:
                      <select
                        name="tipoUsuario"
                        className="form-control mb-rem5"
                        onChange={(e) => handlePerfil(e)}
                      >
                        <option value="">Selecciona</option>
                        {listaPerfiles &&
                          listaPerfiles.map((perfil) => (
                            <option
                              value={perfil.clavePerfil}
                              key={perfil.clavePerfil}
                            >
                              {perfil.nombrePerfil}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-label-group">
                      Línea de negocio:
                      <select
                        name="cadenaComercial"
                        className="form-control mb-rem5"
                        onChange={(e) => handleCadenaComercial(e)}
                      >
                        <option
                          value="1"
                          selected={
                            userInfo.cadenaComercial === 1 ? true : false
                          }
                        >
                          Liverpool
                        </option>
                        <option value="2">Suburbia</option>
                      </select>
                    </div>
                  </div>
                </>
              )
            }
            <div className="text-right col">
              <button
                className="btn btn-primary my-1rem"
                onClick={() => onAnadir()}
              >
                Añadir nuevo usuario
              </button>
            </div>
          </div>
          {
            // Verificamos si hay usuarios, de lo contrario, devolvemos un warning para dirigir el usuario a añadir usuarios
            listaUsuarios === 0 ? (
              <div
                className="text-center alert alert-warning my-1rem"
                role="alert"
              >
                Aún no hay usuarios de este tipo. Si te gustaría añadir un
                empleado, haz click{" "}
                <Link
                  to={`${process.env.PUBLIC_URL}/anadir-usuario`}
                  className="alert-link"
                >
                  aquí.
                </Link>
              </div>
            ) : // Tabla
              listaUsuarios ? (
                <div className="text-center table-responsive scroll-x">
                  <div className="">
                    <Tabla
                      listaUsuarios={listaUsuarios}
                      handleEditar={handleEditar}
                      toggleUsuario={toggleUsuario}
                      permiteAsignar={permiteAsignar}
                    />
                  </div>
                </div>
              ) : (
                <p>Cargando la tabla...</p>
              )
          }
        </div>
      </div>
    );

  // Editar usuario
  if (view === 2)
    return (
      <EditarUsuario
        usuario={perfil}
        setloading={setloading}
        userInfo={userInfo}
        masterUser={usuario}
      />
    );
};

const TablaUsuarios = ({ data }) => {
  const columns = useMemo(
    () => [
      { Header: "Tipo de usuario", accessor: "nombrePerfil" },
      {
        Header: "Nombre(s)",
        accessor: "nombres",
      },
      { Header: "Apellido paterno", accessor: "apPat" },
      { Header: "Apellido materno", accessor: "apMat" },
    ],
    []
  );

  return <DataTableUsuarios data={data} columns={columns} />;
};

const Tabla = ({ listaUsuarios, handleEditar, toggleUsuario, permiteAsignar }) => {

  const mostrarSubmenu = (e, ne) => {
    if(permiteAsignar === 0){
      return ;
    }
    let submenu = document.querySelectorAll('.dropdown-menu');
    let menu = document.querySelector('.dp-' + ne);
    let tiene = false;
    if (menu.classList.contains('show')) {
      tiene = true;
    }
    submenu.forEach(e => e.classList.remove('show'))
    if (tiene) {
      menu.classList.remove('show');
    } else {
      menu.classList.add('show');
    }
  }
  return (
    <table className="table table-striped table-hover">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Nombre(s)</th>
          <th scope="col">Apellidos</th>
          <th scope="col">No. Empleado</th>
          <th scope="col">Tipo de usuario</th>
          <th scope="col">Activo</th>
          <th scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
        {listaUsuarios.map((empleado) => {
          return (
            <tr key={empleado.numeroEmpleado}>
              <td>{empleado.nombres}</td>
              <td>{`${empleado.apMat} ${empleado.apPat}`}</td>
              <td>{empleado.numeroEmpleado}</td>
              <td>{empleado.nombrePerfil}</td>
              <td>
                <div className="btn-group" onClick={(e) => mostrarSubmenu(e, empleado.numeroEmpleado)}>
                  {permiteAsignar === 0 &&

                    empleado.status

                  }

                  {permiteAsignar === 1 &&
                    (
                      <>
                        <div className="dropdown-toggle">
                          {empleado.status}
                        </div>
                        <ul className={"dropdown-menu dp-" + empleado.numeroEmpleado}>
                          <li><div className="dropdown-item" onClick={(e) => { toggleUsuario(empleado, 'Eliminado'); }}>Eliminar</div></li>
                          {empleado.status !== 'Inactivo' && <li><div className="dropdown-item" onClick={(e) => { toggleUsuario(empleado, 'Inactivo'); }} >Inactivar</div></li>}
                          {empleado.status !== 'Activo' && <li><div className="dropdown-item" onClick={(e) => { toggleUsuario(empleado, 'Activo'); }}>Activar</div></li>}
                        </ul>
                      </>
                    )
                  }
                </div>
              </td>
              <th>
                <button
                  type="button"
                  className="btn btn-secondary py-rem5"
                  onClick={() => handleEditar(empleado)}
                >
                  Editar
                </button>
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ListaUsuarios;
