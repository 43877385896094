// Libraries
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
// Helpers
import instance from "../../../helpers/api.instance";
import {
  colorSemaphore,
  normalizePercentage,
  normalizePercentageAbs,
} from "../../../helpers/Helpers";
// Icons
import IconGold from "../../../assets/icons/stars/gold.svg";
import IconGoldOutlined from "../../../assets/icons/stars/gold_outlined.svg";
import IconSilver from "../../../assets/icons/stars/silver.svg";
import IconSilverOutlined from "../../../assets/icons/stars/silver_outlined.svg";
import IconBronze from "../../../assets/icons/stars/bronze.svg";
import IconBronzeOutlined from "../../../assets/icons/stars/bronze_outlined.svg";

const Graficas = ({
  setloading,
  view,
  zona,
  numeroTienda,
  cadenaComercial,
  mes,
  anio,
}) => {
  // Estadísticas a mostrar
  const [graficas, setgraficas] = useState(null);
  // Cadena comercial
  console.log(mes, anio);
  // UseEffect para obtener estadísticas y avances
  useEffect(() => {
    setloading(true);
    if (mes && anio) {
      if (view === 1) {
        // Request de las gráficas
        instance
          .post(
            `/avances/globalZonasGraficas`,
            // Cuerpo del request
            {
              cadenaComercial: cadenaComercial,
            }
          )
          .then((res) => {
            if (res.status === 1) setgraficas(res.data);
          })
          // Apagamos el loader
          .finally(() => setloading(false));
      } else if (view === 2) {
        // Request de las gráficas
        instance
          .post(
            `/avances/globalPorZonasGraficas`,
            // Cuerpo del request
            {
              cadenaComercial: cadenaComercial,
              zona: zona,
            }
          )
          .then((res) => {
            if (res.status === 1) setgraficas(res.data);
          })
          // Apagamos el loader
          .finally(() => setloading(false));
      } else if (view >= 3) {
        instance
          .post(
            `/avances/globalPorTiendasGraficas`,
            // Cuerpo del request
            {
              meta: "Oro",
              cadenaComercial,
              numeroTienda,
              mes,
              anio,
            }
          )
          .then((res) => {
            if (res.status === 1) setgraficas(res.data);
          })
          // Apagamos el loader
          .finally(() => setloading(false));
      }
    }
  }, [zona, view, numeroTienda, cadenaComercial, mes, anio]);

  if (!graficas) return null;

  return (
    <Fragment>
      {graficas ? (
        <>
          {/* Reactivaciones */}
          <div className="row justify-content-around h-1rem5">
            {/* Reactivaciones */}
            <div className="col">
              <h6 className="text-center text-lead fw-bold mt-rem5">
                Reactivaciones
              </h6>
              {
                // Estrellas
                graficas.reactivaciones.metas ? (
                  <div className="text-center row pb-1rem">
                    <div className="col">
                      <p className="text-gold">Oro</p>
                      <img
                        src={
                          parseInt(graficas.reactivaciones.avance) >
                          graficas.reactivaciones.metas.oro
                            ? IconGold
                            : IconGoldOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-gold">
                        {`${graficas.reactivaciones.metas.oro}`}{" "}
                      </p>
                    </div>
                    <div className="col">
                      <p className="text-silver">Plata</p>
                      <img
                        src={
                          parseInt(graficas.reactivaciones.avance) >
                          graficas.reactivaciones.metas.plata
                            ? IconSilver
                            : IconSilverOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-silver">
                        {`${graficas.reactivaciones.metas.plata}`}{" "}
                      </p>
                    </div>
                    <div className="col">
                      <p className="text-bronze">Bronce</p>
                      <img
                        src={
                          parseInt(graficas.reactivaciones.avance) >
                          graficas.reactivaciones.metas.bronce
                            ? IconBronze
                            : IconBronzeOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-bronze">
                        {`${graficas.reactivaciones.metas.bronce}`}{" "}
                      </p>
                    </div>
                  </div>
                ) : null
              }
              <div className="mx-auto max-width-256">
                <CircularProgressbar
                  value={normalizePercentageAbs(
                    graficas.reactivaciones.porcentaje
                  )}
                  text={normalizePercentage(graficas.reactivaciones.porcentaje)}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `${colorSemaphore(
                        "reactivacion",
                        normalizePercentageAbs(
                          graficas.reactivaciones.porcentaje
                        )
                      )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "reactivacion",
                        normalizePercentageAbs(
                          graficas.reactivaciones.porcentaje
                        )
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
              <div className="row mt-1rem">
                <div className="text-left col">
                  <p className="text-lead">Avance:</p>
                </div>
                <div className="text-right col">
                  <p className="text-lead">
                    {`${graficas.reactivaciones.avance}/${graficas.reactivaciones.total}`}
                  </p>
                </div>
              </div>
              <div className="row mt-rem5">
                <div className="text-left col">
                  <p className="text-lead">Faltan:</p>
                </div>
                <div className="text-right col">
                  <p className="text-lead">{`${graficas.reactivaciones.faltan}`}</p>
                </div>
              </div>
            </div>
          </div>
          {/* Migraciones */}
          <div className="row justify-content-around h-1rem5 mt-1rem">
            <div className="col">
              <h6 className="text-center text-lead fw-bold mt-rem5">
                Migraciones
              </h6>
              {
                // Estrellas
                graficas.migraciones.metas ? (
                  <div className="text-center row pb-1rem">
                    <div className="col">
                      <p className="text-gold">Oro</p>
                      <img
                        src={
                          parseInt(graficas.migraciones.avance) >
                          graficas.migraciones.metas.oro
                            ? IconGold
                            : IconGoldOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-gold">
                        {`${graficas.migraciones.metas.oro}`}{" "}
                      </p>
                    </div>
                    <div className="col">
                      <p className="text-silver">Plata</p>
                      <img
                        src={
                          parseInt(graficas.migraciones.avance) >
                          graficas.migraciones.metas.plata
                            ? IconSilver
                            : IconSilverOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-silver">
                        {`${graficas.migraciones.metas.plata}`}{" "}
                      </p>
                    </div>
                    <div className="col">
                      <p className="text-bronze">Bronce</p>
                      <img
                        src={
                          parseInt(graficas.migraciones.avance) >
                          graficas.migraciones.metas.bronce
                            ? IconBronze
                            : IconBronzeOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-bronze">
                        {`${graficas.migraciones.metas.bronce}`}{" "}
                      </p>
                    </div>
                  </div>
                ) : null
              }
              <div className="mx-auto max-width-256">
                <CircularProgressbar
                  value={normalizePercentageAbs(
                    graficas.migraciones.porcentaje
                  )}
                  text={normalizePercentage(graficas.migraciones.porcentaje)}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `${colorSemaphore(
                        "migracion",
                        normalizePercentageAbs(graficas.migraciones.porcentaje)
                      )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "migracion",
                        normalizePercentageAbs(graficas.migraciones.porcentaje)
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>

              <div className="row mt-1rem">
                <div className="text-left col">
                  <p className="text-lead">Avance:</p>
                </div>
                <div className="text-right col">
                  <p className="text-lead">
                    {`${graficas.migraciones.avance}/${graficas.migraciones.total}`}
                  </p>
                </div>
              </div>
              <div className="row mt-rem5">
                <div className="text-left col">
                  <p className="text-lead">Faltan:</p>
                </div>
                <div className="text-right col">
                  <p className="text-lead">{`${graficas.migraciones.faltan}`}</p>
                </div>
              </div>
            </div>
          </div>
          {/* IPPF */}
          <div className="row justify-content-center mt-1rem5">
            <div className="col">
              <h6 className="text-center text-lead fw-bold mt-rem5">IPPF</h6>
              {
                // Estrellas
                graficas.ippf.metas ? (
                  <div className="text-center row pb-1rem">
                    <div className="col">
                      <p className="text-gold">Oro</p>
                      <img
                        src={
                          parseInt(graficas.ippf.calculado) >
                          graficas.ippf.metas.oro
                            ? IconGold
                            : IconGoldOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-gold">
                        {`${graficas.ippf.metas.oro}`}{" "}
                      </p>
                    </div>
                    <div className="col">
                      <p className="text-silver">Plata</p>
                      <img
                        src={
                          parseInt(graficas.ippf.calculado) >
                          graficas.ippf.metas.plata
                            ? IconSilver
                            : IconSilverOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-silver">
                        {`${graficas.ippf.metas.plata}`}{" "}
                      </p>
                    </div>
                    <div className="col">
                      <p className="text-bronze">Bronce</p>
                      <img
                        src={
                          parseInt(graficas.ippf.calculado) >
                          graficas.ippf.metas.bronce
                            ? IconBronze
                            : IconBronzeOutlined
                        }
                        alt="Estrella dorada"
                      />
                      <p className="text-bronze">
                        {`${graficas.ippf.metas.bronce}`}{" "}
                      </p>
                    </div>
                  </div>
                ) : null
              }
              <div className="mx-auto max-width-256">
                <CircularProgressbar
                  value={normalizePercentageAbs(graficas.ippf.calculado)}
                  text={normalizePercentage(graficas.ippf.calculado)}
                  maxValue={graficas.ippf.meta}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `${colorSemaphore(
                        "reactivacion",
                        normalizePercentageAbs(graficas.ippf.calculado)
                      )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "reactivacion",
                        normalizePercentageAbs(graficas.ippf.calculado)
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
              <div className="row mt-1rem">
                <div className="text-left col">
                  <p className="text-lead">Meta:</p>
                </div>
                <div className="text-right col">
                  <p className="text-lead">
                    {normalizePercentage(graficas.ippf.meta)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Rechazo inicial */}
          <div className="row justify-content-center mt-1rem5">
            <div className="col">
              <h6 className="text-center text-lead fw-bold mt-rem5">
                Rechazo inicial
              </h6>
              <div className="mx-auto max-width-256">
                <CircularProgressbar
                  value={normalizePercentageAbs(graficas.rechazoInicial.actual)}
                  text={normalizePercentage(graficas.rechazoInicial.actual)}
                  maxValue={graficas.rechazoInicial.meta}
                  styles={{
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `${colorSemaphore(
                        "rechazo",
                        normalizePercentageAbs(graficas.rechazoInicial.actual)
                      )}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: "#f5f5f5",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: `${colorSemaphore(
                        "rechazo",
                        normalizePercentageAbs(graficas.rechazoInicial.actual)
                      )}`,
                      // Text size
                      fontSize: "1rem",
                    },
                  }}
                />
              </div>
              <div className="row mt-1rem">
                <div className="text-left col">
                  <p className="text-lead">Límite:</p>
                </div>
                <div className="text-right col">
                  <p className="text-lead">
                    {normalizePercentage(graficas.rechazoInicial.meta)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Fragment>
  );
};

export default Graficas;
