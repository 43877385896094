import React from "react";
import { useTable, useSortBy } from "react-table";
import { colorSemaphoreHex } from "../../helpers/Helpers";

const defaultPropGetter = () => ({});
// Bloques auxiliares para filtros.

const Tabla = ({
  data,
  columns,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      {/*Inicio de tabla principal*/}
      <table id="tabla-principal"
        {...getTableProps()}
        className="table mt-1rem table-hover table-bordered"
      >
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      style: column.style,
                    },
                    getColumnProps(column),
                    getHeaderProps(column),
                    column.getSortByToggleProps(),
                  ])}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const color = colorSemaphoreHex(
                    cell.column.id ? cell.column.id : "",
                    cell.value
                  );

                  return (
                    <td
                      // Regresa null en caso de no existir color para el valor de la clase 
                      className={color ? `bg-${color}` : null}
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render("Cell")}
                      {cell.column.Header === "Tienda" ? row.original.ayuda === 1 && <span className="text-primary">*</span> : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Tabla;
