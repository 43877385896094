// Libraries
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Icons
import IconGold from "../assets/icons/stars/gold.svg";
import IconSilver from "../assets/icons/stars/silver.svg";
import IconSilverOutlined from "../assets/icons/stars/silver_outlined.svg";
import IconBronze from "../assets/icons/stars/bronze.svg";

export const isLogged = () => {
  if (localStorage.getItem("logged") === "true") {
    return true;
  } else {
    return false;
  }
};

export const notification = (msg, type = "error") => {
  if (type === "error") {
    toast.error(msg, {
      position: "top-center",
      autoClose: 4800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "warning") {
    toast.warn(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "success") {
    toast.success(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.info(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

/*
 * Semaforo para reactivacion y migracion de acuerdo a los puntos porcentuales
 *  de avance de la tabla.
 */
export const colorSemaphoreAvance = (porcentajeReal, porcentajeIdeal) => {
  // Definimos nuestros colores
  const red = "redsemaphore";
  const yellow = "yellowsemaphore";
  const green = "greensemaphore";
  if (porcentajeReal >= porcentajeIdeal) {
    return green;
  } else if (porcentajeIdeal - porcentajeReal <= 3) {
    return yellow;
  } else return red;
};

export const colorSemaphore = (
  categoria,
  porcentaje,
  cadenaComercial,
  segundoPorcentaje = 0,
  hex = true,
  valorRef
) => {
  // Definimos nuestros colores
  let red;
  let yellow;
  let green;

  // Cambiamos las variables dependiendo de is ex hex o no
  if (hex) {
    red = "redsemaphore";
    yellow = "yellowsemaphore";
    green = "greensemaphore";
  } else {
    red = "rgb(240, 100, 120)";
    yellow = "rgb(247, 198, 100)";
    green = "rgb(182, 238, 166)";
  }

  // Reactivación
  let minReact;
  let maxReact;
  // Rechazo
  let minRechazo;
  let maxRechazo;
  // IPPF
  let minIppf;
  let maxIppf;

  if (cadenaComercial === 1 || cadenaComercial === "1") {
    // Reactivación
    minReact = 40;
    maxReact = 70;
    // Rechazo
    minRechazo = 10;
    maxRechazo = 14.99;
    // IPPF
    minIppf = 3;
    maxIppf = 6;
  } else {
    // Reactivación
    minReact = 40;
    maxReact = 70;
    // Rechazo
    minRechazo = 7;
    maxRechazo = 11.9;
    // IPPF
    minIppf = 3;
    maxIppf = 6;
  }

  // Reactivación o migración
  if (categoria === "reactivacion" || categoria === "migracion") {
    if (valorRef) {
      porcentaje = Number(porcentaje);
      valorRef = Number(valorRef);
      if (porcentaje >= valorRef) {
        return green;
      } else {
        let valorRefn3 = valorRef - 3;
        if (porcentaje >= valorRefn3) {
          return yellow;
        } else {
          return red;
        }
      }
    }
    if (porcentaje <= minReact) return red;
    if (porcentaje > minReact && porcentaje <= maxReact) return yellow;
    return green;
  }

  if (categoria === "ippf") {
    if (valorRef) {
      porcentaje = Number(porcentaje);
      valorRef = Number(valorRef);
      if (porcentaje >= valorRef) {
        return green;
      }
      return red;
    }
  }

  // Rechazo inicial
  else if (categoria === "rechazo") {
    if (porcentaje <= minRechazo) return green;
    if (porcentaje > minRechazo && porcentaje <= maxRechazo) return yellow;
    return red;
  }

  /*
   * IPPF: Seleccion de color de background
   * Se quita color amarillo por decisión de Liverpool
   * Si el porcentaje es mayor a 0 significa que está fuera de objetivo.
   * Por lo que se usa color rojo. vcruz
   */
  // else if (categoria === "ippf") {
  //   return porcentaje > 0 ? red : green;
  // }

  // Mayor o menor
  else if (categoria === "mayorOMenor") {
    return porcentaje >= segundoPorcentaje ? green : red;
  } else if (categoria === "ippftotal") {
    /*
     * IPPF total: Seleccion de color de background
     * TODO: reemplazar nombre de cadenaComercial en funcion -> en este caso recibe la meta y se
     * usa ese porcentaje para saber qué color de semaforo usar rojo o verde.
     * !TODO: porcentaje mayor a valor meta elegida.
     * De acuerdo a la reunión con Liverpool se quitó amarillo de IPPF. vcruz
     */
    return porcentaje <= 0 ? green : red;
  }
  return "";
};

export const colorSemaphoreHex = (
  categoria,
  porcentaje,
  segundoporcentaje = 0
) => {
  // Definimos nuestros colores
  const red = "redsemaphore";
  const yellow = "yellowsemaphore";
  const green = "greensemaphore";

  // Validamos qué categoría es, qué porcentaje tieen y regresamos su respectivo color como cadena
  if (categoria === "reactivacion" || categoria === "migracion") {
    if (porcentaje <= 40) return red;
    if (porcentaje > 40 && porcentaje <= 70) return yellow;
    if (porcentaje > 70) return green;
  } else if (categoria === "rechazo") {
    if (porcentaje <= 10) return green;
    if (porcentaje > 10 && porcentaje <= 14.99) return yellow;
    if (porcentaje >= 15) return red;
  } else if (categoria === "ippf") {
    if (porcentaje <= 3) return green;
    if (porcentaje > 3 && porcentaje < 6) return yellow;
    if (porcentaje >= 6) return red;
  } else if (categoria === "mayorOMenor") {
    if (porcentaje >= segundoporcentaje) {
      return green;
    } else {
      return red;
    }
  } else if (categoria === "ippftotal") {
    if (porcentaje < 60) return red;
    if (porcentaje >= 60 && porcentaje < 70) return yellow;
    if (porcentaje >= 70) return green;
  } else {
    return "";
  }
};

// Helper que me ayuda a formatear los porcentajes a dos decimales o devolver el dato normal en caso de que no sea un número
export const normalizePercentage = (number) => {
  if (isNaN(number)) {
    return `${number}`;
  } else {
    return `${Number(number).toFixed(2)}%`;
  }
};

export const normalizePercentageAbs = (number) => {
  if (isNaN(number)) {
    return 0;
  } else {
    return Number(number).toFixed(2);
  }
};

/*
 * Función que regresa color de medalla y nombre para encabezado
 * Se coloca p tag para poder descargarlo a Excel en encabezados
 * Se coloca display: none inline para evitar que se muestre en
 * la vista, sólo para descarga en excel.
 * TODO: Mejorar funcion para reusar elementos.
 */
export const medalHeader = (color) => {
  if (color === "gold") {
    return (
      <>
        <img src={IconGold} alt="Icon Gold" />
        <p style={{ display: "none" }}>Oro</p>
      </>
    );
  } else if (color === "silver") {
    return (
      <>
        <img src={IconSilver} alt="Icon Silver" />
        <p style={{ display: "none" }}>Plata</p>
      </>
    );
  } else {
    return (
      <>
        <img src={IconBronze} alt="Icon Bronze" />
        <p style={{ display: "none" }}>Bronce</p>
      </>
    );
  }
};

export const returnStar = (value) => {
  if (value === "3") {
    return medalHeader("gold");
  } else if (value === "2") {
    return medalHeader("silver");
  } else if (value === "1") {
    return medalHeader("bronze");
  } else {
    return (
      <>
        <img src={IconSilverOutlined} alt="" />
        <p style={{ display: "none" }}>-</p>
      </>
    );
  }
};

export function findClosest(arr, target) {
  let n = arr.length;

  // Corner cases
  if (target <= arr[0]) return arr[0];
  if (target >= arr[n - 1]) return arr[n - 1];

  // Doing binary search
  let i = 0,
    j = n,
    mid = 0;
  while (i < j) {
    mid = (i + j) / 2;

    if (arr[mid] === target) return arr[mid];

    // If target is less than array
    // element,then search in left
    if (target < arr[mid]) {
      // If target is greater than previous
      // to mid, return closest of two
      if (mid > 0 && target > arr[mid - 1])
        return getClosest(arr[mid - 1], arr[mid], target);

      // Repeat for left half
      j = mid;
    }

    // If target is greater than mid
    else {
      if (mid < n - 1 && target < arr[mid + 1])
        return getClosest(arr[mid], arr[mid + 1], target);
      i = mid + 1; // update i
    }
  }

  // Only single element left after search
  return arr[mid];
}

function getClosest(val1, val2, target) {
  if (target - val1 >= val2 - target) return val2;
  else return val1;
}

export function closest(arr, val) {
  return Math.max.apply(
    null,
    arr.filter(function(v) {
      return v <= val;
    })
  );
}
export const formatoMiles = (number) => {
  if (isNaN(number)) {
    return 0;
  }
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';
  let arr = number.toString().split('.');
  arr[0] = arr[0].replace(exp, rep);
  return arr[1] ? arr.join('.') : arr[0];
}
