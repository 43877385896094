import React, { useState } from 'react';
import instance from '../../helpers/api.instance';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { normalizePercentage } from '../../helpers/Helpers';

const ReporteTiendasAll = ({ cadenaComercial, meta, mes, anio, tipo, setloading }) => {
  const [reportes, setReportes] = useState(null);

  const handleDownloadExcel = async () => {
    setloading(true);
    try {
      const res = await instance.get(`/reportes/avances?cadenaComercial=${cadenaComercial}&meta=${meta}&mes=${mes}&anio=${anio}&tipo=${tipo}`);
      if (res.status === 1) {
        setReportes(res.data.avances);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Reporte');

        worksheet.views = [{ state: 'frozen', ySplit: 4 }];

        // estilos de título general
        const titleStyle = {
          font: { bold: true, size: 14, color: { argb: 'FFFFFFFF' } },
          alignment: { vertical: 'middle', horizontal: 'center' },
          fill: { type: 'pattern', pattern:'solid', fgColor:{ argb:'555555' } }
        };

        // estilos subtitulos
        const subtitleStyle = { 
          font: { size: 12, bold: false, color: { argb: '3333333' } },
          alignment: { vertical: 'middle', horizontal: 'center' },
          fill: { type: 'pattern', pattern:'solid', fgColor:{ argb:'ECECEC' } }
        };

        const cellStyle = {
          font: { size: 12 },
          alignment: { vertical: 'middle', horizontal: 'center' },
          border: {
            top: { style: 'hair' },
            left: { style: 'hair' },
            bottom: { style: 'hair' },
            right: { style: 'hair' }
          },
        };

        if (cadenaComercial === '1') {
          // Liverpool
          // títulos principales
          worksheet.mergeCells('A1:A4');
          worksheet.getCell('A1').value = 'Rank';
          worksheet.getCell('A1').style = titleStyle;

          worksheet.mergeCells('B1:B4');
          worksheet.getCell('B1').value = 'Número de tienda';
          worksheet.getCell('B1').style = titleStyle;

          worksheet.mergeCells('C1:C4');
          worksheet.getCell('C1').value = 'Tienda';
          worksheet.getCell('C1').style = titleStyle;

          worksheet.mergeCells('D1:D4');
          worksheet.getCell('D1').value = 'Zona';
          worksheet.getCell('D1').style = titleStyle;

          worksheet.mergeCells('E1:K1');
          worksheet.getCell('E1').value = 'Reactivaciones';
          worksheet.getCell('E1').style = titleStyle;

          worksheet.mergeCells('L1:R1');
          worksheet.getCell('L1').value = 'Migraciones';
          worksheet.getCell('L1').style = titleStyle;

          worksheet.mergeCells('S1:AE1');
          worksheet.getCell('S1').value = 'Cuentas nuevas';
          worksheet.getCell('S1').style = titleStyle;

          worksheet.mergeCells('AF1:AF4');
          worksheet.getCell('AF1').value = '% DE RETENCIÓN';
          worksheet.getCell('AF1').style = titleStyle;

          worksheet.mergeCells('AG1:AP1');
          worksheet.getCell('AG1').value = 'Rechazo inicial';
          worksheet.getCell('AG1').style = titleStyle;

          worksheet.mergeCells('AQ1:AQ4');
          worksheet.getCell('AQ1').value = '¿Si hoy fuera cierre de mes, ganarías estrella?';
          worksheet.getCell('AQ1').style = titleStyle;

          worksheet.mergeCells('AR1:AT1');
          worksheet.getCell('AR1').value = 'IPPF';
          worksheet.getCell('AR1').style = titleStyle;

          // SUBTITULOS REACTIVACIONES
          worksheet.mergeCells('E2:E4');
          worksheet.getCell('E2').value = 'Meta Global (C + CS)';
          worksheet.getCell('E2').style = subtitleStyle;

          worksheet.mergeCells('F2:F4');
          worksheet.getCell('F2').value = 'Avance Global (C + CS)';
          worksheet.getCell('F2').style = subtitleStyle;

          worksheet.mergeCells('G2:G4');
          worksheet.getCell('G2').value = 'Avance Crédito';
          worksheet.getCell('G2').style = subtitleStyle;

          worksheet.mergeCells('H2:H4');
          worksheet.getCell('H2').value = 'Meta CS';
          worksheet.getCell('H2').style = subtitleStyle;

          worksheet.mergeCells('I2:I4');
          worksheet.getCell('I2').value = 'Avance CS';
          worksheet.getCell('I2').style = subtitleStyle;

          worksheet.mergeCells('J2:J4');
          worksheet.getCell('J2').value = 'Faltan';
          worksheet.getCell('J2').style = subtitleStyle;

          worksheet.mergeCells('K2:K4');
          worksheet.getCell('K2').value = '% Avance';
          worksheet.getCell('K2').style = subtitleStyle;

          // SUBTITULOS MIGRACIONES
          worksheet.mergeCells('L2:L4');
          worksheet.getCell('L2').value = 'Meta Global (C + CS)';
          worksheet.getCell('L2').style = subtitleStyle;

          worksheet.mergeCells('M2:M4');
          worksheet.getCell('M2').value = 'Avance Global (C + CS)';
          worksheet.getCell('M2').style = subtitleStyle;

          worksheet.mergeCells('N2:N4');
          worksheet.getCell('N2').value = 'Avance Crédito';
          worksheet.getCell('N2').style = subtitleStyle;

          worksheet.mergeCells('O2:O4');
          worksheet.getCell('O2').value = 'Meta CS';
          worksheet.getCell('O2').style = subtitleStyle;

          worksheet.mergeCells('P2:P4');
          worksheet.getCell('P2').value = 'Avance CS';
          worksheet.getCell('P2').style = subtitleStyle;

          worksheet.mergeCells('Q2:Q4');
          worksheet.getCell('Q2').value = 'Faltan';
          worksheet.getCell('Q2').style = subtitleStyle;

          worksheet.mergeCells('R2:R4');
          worksheet.getCell('R2').value = '% Avance';
          worksheet.getCell('R2').style = subtitleStyle;

          // SUBTITULOS CUENTAS NUEVAS
          worksheet.mergeCells('S2:U3');
          worksheet.getCell('S2').value = 'CN';
          worksheet.getCell('S2').style = subtitleStyle;

          worksheet.mergeCells('V2:X3');
          worksheet.getCell('V2').value = 'CN CP';
          worksheet.getCell('V2').style = subtitleStyle;

          worksheet.mergeCells('Y2:AA3');
          worksheet.getCell('Y2').value = 'CN CP SF';
          worksheet.getCell('Y2').style = subtitleStyle;

          worksheet.mergeCells('AB2:AD3');
          worksheet.getCell('AB2').value = 'CN TOTAL';
          worksheet.getCell('AB2').style = subtitleStyle;

          worksheet.mergeCells('AE2:AE4');
          worksheet.getCell('AE2').value = 'Cuentas totales canceladas en el mismo mes';
          worksheet.getCell('AE2').style = subtitleStyle;

          // SUBTITULOS RECHAZO INICIAL
          worksheet.mergeCells('AG2:AH3');
          worksheet.getCell('AG2').value = 'CN';
          worksheet.getCell('AG2').style = subtitleStyle;

          worksheet.mergeCells('AI2:AJ3');
          worksheet.getCell('AI2').value = 'CN CP';
          worksheet.getCell('AI2').style = subtitleStyle;

          worksheet.mergeCells('AK2:AL3');
          worksheet.getCell('AK2').value = 'CN CP SF';
          worksheet.getCell('AK2').style = subtitleStyle;

          worksheet.mergeCells('AM2:AM4');
          worksheet.getCell('AM2').value = '% Cancelación';
          worksheet.getCell('AM2').style = subtitleStyle;

          worksheet.mergeCells('AN2:AN4');
          worksheet.getCell('AN2').value = 'Cuentas totales rechazadas';
          worksheet.getCell('AN2').style = subtitleStyle;

          worksheet.mergeCells('AO2:AO4');
          worksheet.getCell('AO2').value = 'Anterior';
          worksheet.getCell('AO2').style = subtitleStyle;

          worksheet.mergeCells('AP2:AP4');
          worksheet.getCell('AP2').value = 'Actual';
          worksheet.getCell('AP2').style = subtitleStyle;

          // SUBTITULOS IPPF
          worksheet.mergeCells('AR2:AR4');
          worksheet.getCell('AR2').value = 'Meta';
          worksheet.getCell('AR2').style = subtitleStyle;

          worksheet.mergeCells('AS2:AS4');
          worksheet.getCell('AS2').value = 'Al mes anterior';
          worksheet.getCell('AS2').style = subtitleStyle;

          worksheet.mergeCells('AT2:AT4');
          worksheet.getCell('AT2').value = 'Faltante';
          worksheet.getCell('AT2').style = subtitleStyle;

          // SUB SUBTITULOS
          // CN
          worksheet.mergeCells('S4:S4');
          worksheet.getCell('S4').value = 'Total';
          worksheet.getCell('S4').style = subtitleStyle;

          worksheet.mergeCells('T4:T4');
          worksheet.getCell('T4').value = 'Básico #';
          worksheet.getCell('T4').style = subtitleStyle;

          worksheet.mergeCells('U4:U4');
          worksheet.getCell('U4').value = 'Básico %';
          worksheet.getCell('U4').style = subtitleStyle;

          // CN CP
          worksheet.mergeCells('V4:V4');
          worksheet.getCell('V4').value = 'Total';
          worksheet.getCell('V4').style = subtitleStyle;

          worksheet.mergeCells('W4:W4');
          worksheet.getCell('W4').value = 'Básico #';
          worksheet.getCell('W4').style = subtitleStyle;

          worksheet.mergeCells('X4:X4');
          worksheet.getCell('X4').value = 'Básico %';
          worksheet.getCell('X4').style = subtitleStyle;

          // CN CP SF
          worksheet.mergeCells('Y4:Y4');
          worksheet.getCell('Y4').value = 'Total';
          worksheet.getCell('Y4').style = subtitleStyle;

          worksheet.mergeCells('Z4:Z4');
          worksheet.getCell('Z4').value = 'Básico #';
          worksheet.getCell('Z4').style = subtitleStyle;

          worksheet.mergeCells('AA4:AA4');
          worksheet.getCell('AA4').value = 'Básico %';
          worksheet.getCell('AA4').style = subtitleStyle;

          // CN TOTAL
          worksheet.mergeCells('AB4:AB4');
          worksheet.getCell('AB4').value = 'Total';
          worksheet.getCell('AB4').style = subtitleStyle;

          worksheet.mergeCells('AC4:AC4');
          worksheet.getCell('AC4').value = 'Básico #';
          worksheet.getCell('AC4').style = subtitleStyle;

          worksheet.mergeCells('AD4:AD4');
          worksheet.getCell('AD4').value = 'Básico %';
          worksheet.getCell('AD4').style = subtitleStyle;

          // Rechazo inicial CN
          worksheet.mergeCells('AG4:AG4');
          worksheet.getCell('AG4').value = 'Cantidad';
          worksheet.getCell('AG4').style = subtitleStyle;

          worksheet.mergeCells('AH4:AH4');
          worksheet.getCell('AH4').value = '%';
          worksheet.getCell('AH4').style = subtitleStyle;

          // Rechazo inicial CN CP  
          worksheet.mergeCells('AI4:AI4');
          worksheet.getCell('AI4').value = 'Cantidad';
          worksheet.getCell('AI4').style = subtitleStyle;

          worksheet.mergeCells('AJ4:AJ4');
          worksheet.getCell('AJ4').value = '%';
          worksheet.getCell('AJ4').style = subtitleStyle;

          // Rechazo inicial CN CP SF
          worksheet.mergeCells('AK4:AK4');
          worksheet.getCell('AK4').value = 'Cantidad';
          worksheet.getCell('AK4').style = subtitleStyle;

          worksheet.mergeCells('AL4:AL4');
          worksheet.getCell('AL4').value = '%';
          worksheet.getCell('AL4').style = subtitleStyle;

        } else if (cadenaComercial === '2') {
          // Suburbia
          // títulos principales
          worksheet.mergeCells('A1:A4');
          worksheet.getCell('A1').value = 'Rank';
          worksheet.getCell('A1').style = titleStyle;

          worksheet.mergeCells('B1:B4');
          worksheet.getCell('B1').value = 'Número de tienda';
          worksheet.getCell('B1').style = titleStyle;

          worksheet.mergeCells('C1:C4');
          worksheet.getCell('C1').value = 'Tienda';
          worksheet.getCell('C1').style = titleStyle;
      
          worksheet.mergeCells('D1:D4');
          worksheet.getCell('D1').value = 'Zona';
          worksheet.getCell('D1').style = titleStyle;
      
          worksheet.mergeCells('E1:K1');
          worksheet.getCell('E1').value = 'Reactivaciones';
          worksheet.getCell('E1').style = titleStyle;
      
          worksheet.mergeCells('L1:R1');
          worksheet.getCell('L1').value = 'Migraciones';
          worksheet.getCell('L1').style = titleStyle;
      
          worksheet.mergeCells('S1:X1');
          worksheet.getCell('S1').value = 'Cuentas nuevas';
          worksheet.getCell('S1').style = titleStyle;
      
          worksheet.mergeCells('Y1:Y4');
          worksheet.getCell('Y1').value = '% DE RETENCIÓN';
          worksheet.getCell('Y1').style = titleStyle;
      
          worksheet.mergeCells('Z1:AA1');
          worksheet.getCell('Z1').value = 'Rechazo inicial';
          worksheet.getCell('Z1').style = titleStyle;
      
          worksheet.mergeCells('AB1:AB4');
          worksheet.getCell('AB1').value = '¿Si hoy fuera cierre de mes, ganarías estrella?';
          worksheet.getCell('AB1').style = titleStyle;
      
          worksheet.mergeCells('AC1:AE1');
          worksheet.getCell('AC1').value = 'IPPF';
          worksheet.getCell('AC1').style = titleStyle;
      
          // SUBTITULOS REACTIVACIONES
          worksheet.mergeCells('E2:E4');
          worksheet.getCell('E2').value = 'Meta Global (C + CS)';
          worksheet.getCell('E2').style = subtitleStyle;
      
          worksheet.mergeCells('F2:F4');
          worksheet.getCell('F2').value = 'Avance Global (C + CS)';
          worksheet.getCell('F2').style = subtitleStyle;
      
          worksheet.mergeCells('G2:G4');
          worksheet.getCell('G2').value = 'Avance Crédito';
          worksheet.getCell('G2').style = subtitleStyle;
      
          worksheet.mergeCells('H2:H4');
          worksheet.getCell('H2').value = 'Meta CS';
          worksheet.getCell('H2').style = subtitleStyle;
      
          worksheet.mergeCells('I2:I4');
          worksheet.getCell('I2').value = 'Avance CS';
          worksheet.getCell('I2').style = subtitleStyle;
      
          worksheet.mergeCells('J2:J4');
          worksheet.getCell('J2').value = 'Faltan';
          worksheet.getCell('J2').style = subtitleStyle;
      
          worksheet.mergeCells('K2:K4');
          worksheet.getCell('K2').value = '% Avance';
          worksheet.getCell('K2').style = subtitleStyle;
      
          // SUBTITULOS MIGRACIONES
          worksheet.mergeCells('L2:L4');
          worksheet.getCell('L2').value = 'Meta Global (C + CS)';
          worksheet.getCell('L2').style = subtitleStyle;
      
          worksheet.mergeCells('M2:M4');
          worksheet.getCell('M2').value = 'Avance Global (C + CS)';
          worksheet.getCell('M2').style = subtitleStyle;
      
          worksheet.mergeCells('N2:N4');
          worksheet.getCell('N2').value = 'Avance Crédito';
          worksheet.getCell('N2').style = subtitleStyle;
      
          worksheet.mergeCells('O2:O4');
          worksheet.getCell('O2').value = 'Meta CS';
          worksheet.getCell('O2').style = subtitleStyle;
      
          worksheet.mergeCells('P2:P4');
          worksheet.getCell('P2').value = 'Avance CS';
          worksheet.getCell('P2').style = subtitleStyle;
      
          worksheet.mergeCells('Q2:Q4');
          worksheet.getCell('Q2').value = 'Faltan';
          worksheet.getCell('Q2').style = subtitleStyle;
      
          worksheet.mergeCells('R2:R4');
          worksheet.getCell('R2').value = '% Avance';
          worksheet.getCell('R2').style = subtitleStyle;
      
          // SUBTITULOS CUENTAS NUEVAS
          worksheet.mergeCells('S2:S4');
          worksheet.getCell('S2').value = 'Total CN';
          worksheet.getCell('S2').style = subtitleStyle;
      
          worksheet.mergeCells('T2:T4');
          worksheet.getCell('T2').value = '# Básico con LC > 5000';
          worksheet.getCell('T2').style = subtitleStyle;
      
          worksheet.mergeCells('U2:U4');
          worksheet.getCell('U2').value = '% Básico con LC > 5000';
          worksheet.getCell('U2').style = subtitleStyle;
      
          worksheet.mergeCells('V2:V4');
          worksheet.getCell('V2').value = 'Cuentas totales canceladas en el mismo mes';
          worksheet.getCell('V2').style = subtitleStyle;
      
          worksheet.mergeCells('W2:W4');
          worksheet.getCell('W2').value = '% Cancelación';
          worksheet.getCell('W2').style = subtitleStyle;
      
          worksheet.mergeCells('X2:X4');
          worksheet.getCell('X2').value = 'Cuentas totales rechazadas';
          worksheet.getCell('X2').style = subtitleStyle;
      
          worksheet.mergeCells('Z2:Z4');
          worksheet.getCell('Z2').value = 'Anterior';
          worksheet.getCell('Z2').style = subtitleStyle;
      
          worksheet.mergeCells('AA2:AA4');
          worksheet.getCell('AA2').value = 'Actual';
          worksheet.getCell('AA2').style = subtitleStyle;
      
          // SUBTITULOS IPPF
          worksheet.mergeCells('AC2:AC4');
          worksheet.getCell('AC2').value = 'Meta';
          worksheet.getCell('AC2').style = subtitleStyle;
      
          worksheet.mergeCells('AD2:AD4');
          worksheet.getCell('AD2').value = 'Al mes anterior';
          worksheet.getCell('AD2').style = subtitleStyle;
      
          worksheet.mergeCells('AE2:AE4');
          worksheet.getCell('AE2').value = 'Faltante';
          worksheet.getCell('AE2').style = subtitleStyle;
        }

        if (cadenaComercial === '1') {
          // ANCHO DE LAS COLUMNAS
          worksheet.getColumn('A').width = 10; // Rank
          worksheet.getColumn('B').width = 25; // Número de tienda
          worksheet.getColumn('C').width = 35; // Tienda
          worksheet.getColumn('D').width = 20; // Zona
          worksheet.getColumn('E').width = 20; // Meta Global (C + CS)
          worksheet.getColumn('F').width = 25; // Avance Global (C + CS)
          worksheet.getColumn('G').width = 15; // Avance Crédito
          worksheet.getColumn('H').width = 10; // Meta CS
          worksheet.getColumn('I').width = 15; // Avance CS
          worksheet.getColumn('J').width = 10; // Faltan
          worksheet.getColumn('K').width = 10; // % Avance
          worksheet.getColumn('L').width = 20; // Meta Global (C + CS)
          worksheet.getColumn('M').width = 25; // Avance Global (C + CS)
          worksheet.getColumn('N').width = 15; // Avance Crédito
          worksheet.getColumn('O').width = 10; // Meta CS
          worksheet.getColumn('P').width = 15; // Avance CS
          worksheet.getColumn('Q').width = 10; // Faltan
          worksheet.getColumn('R').width = 10; // % Avance
          worksheet.getColumn('S').width = 20; // CN Total
          worksheet.getColumn('T').width = 20; // CN Básico #
          worksheet.getColumn('U').width = 20; // CN Básico %
          worksheet.getColumn('V').width = 20; // CN CP Total
          worksheet.getColumn('W').width = 20; // CN CP Básico #
          worksheet.getColumn('X').width = 20; // CN CP Básico %
          worksheet.getColumn('Y').width = 20; // CN CP SF Total
          worksheet.getColumn('Z').width = 20; // CN CP SF Básico #
          worksheet.getColumn('AA').width = 20; // CN CP SF Básico %
          worksheet.getColumn('AB').width = 20; // CN Total Total
          worksheet.getColumn('AC').width = 20; // CN Total Básico #
          worksheet.getColumn('AD').width = 20; // CN Total Básico %
          worksheet.getColumn('AE').width = 40; // Cuentas totales canceladas en el mismo mes
          worksheet.getColumn('AF').width = 20; // % DE RETENCIÓN
          worksheet.getColumn('AG').width = 20; // CN Cantidad
          worksheet.getColumn('AH').width = 20; // CN %
          worksheet.getColumn('AI').width = 20; // CN CP Cantidad
          worksheet.getColumn('AJ').width = 20; // CN CP %
          worksheet.getColumn('AK').width = 20; // CN CP SF Cantidad
          worksheet.getColumn('AL').width = 20; // CN CP SF %
          worksheet.getColumn('AM').width = 20; // % Cancelación
          worksheet.getColumn('AN').width = 25; // Cuentas totales rechazadas
          worksheet.getColumn('AO').width = 20; // Anterior
          worksheet.getColumn('AP').width = 20; // Actual
          worksheet.getColumn('AQ').width = 60; // ¿Si hoy fuera cierre de mes, ganarías estrella?
          worksheet.getColumn('AR').width = 20; // Meta
          worksheet.getColumn('AS').width = 20; // Al mes anterior
          worksheet.getColumn('AT').width = 20; // Faltante
        } else if (cadenaComercial === '2') {
            // ANCHO DE LAS COLUMNAS
            worksheet.getColumn('A').width = 10; // Rank
            worksheet.getColumn('B').width = 25; // Número de tienda
            worksheet.getColumn('C').width = 35; // Tienda
            worksheet.getColumn('D').width = 20; // Zona
            worksheet.getColumn('E').width = 20; // Meta Global (C + CS)
            worksheet.getColumn('F').width = 25; // Avance Global (C + CS)
            worksheet.getColumn('G').width = 15; // Avance Crédito
            worksheet.getColumn('H').width = 10; // Meta CS
            worksheet.getColumn('I').width = 15; // Avance CS
            worksheet.getColumn('J').width = 10; // Faltan
            worksheet.getColumn('K').width = 10; // % Avance
            worksheet.getColumn('L').width = 20; // Meta Global (C + CS)
            worksheet.getColumn('M').width = 25; // Avance Global (C + CS)
            worksheet.getColumn('N').width = 15; // Avance Crédito
            worksheet.getColumn('O').width = 10; // Meta CS
            worksheet.getColumn('P').width = 15; // Avance CS
            worksheet.getColumn('Q').width = 10; // Faltan
            worksheet.getColumn('R').width = 10; // % Avance
            worksheet.getColumn('S').width = 20; // Total CN
            worksheet.getColumn('T').width = 25; // # Básico con LC > 5000
            worksheet.getColumn('U').width = 20; // % Básico con LC > 5000
            worksheet.getColumn('V').width = 40; // Cuentas totales canceladas en el mismo mes
            worksheet.getColumn('W').width = 20; // % Cancelación
            worksheet.getColumn('X').width = 35; // Cuentas totales rechazadas
            worksheet.getColumn('Y').width = 30; // % DE RETENCIÓN
            worksheet.getColumn('Z').width = 20; // Anterior
            worksheet.getColumn('AA').width = 20; // Actual
            worksheet.getColumn('AB').width = 60; // ¿Si hoy fuera cierre de mes, ganarías estrella?
            worksheet.getColumn('AC').width = 20; // Meta
            worksheet.getColumn('AD').width = 20; // Al mes anterior
            worksheet.getColumn('AE').width = 20; // Faltante
        }    
        
        const startingRow = 5;
        res.data.avances.forEach((report, index) => {
          const row = worksheet.getRow(startingRow + index);
          // console.log(report.zona, "ZONA");
          for (let col = 1; col <= 47; col++) {
            if (index > 0 || col > 4) {
              row.getCell(col).style = cellStyle;
            }
          }

          if (cadenaComercial === '1') {
            // Mapeo de campos a columnas
            row.getCell(1).value = report.rank; // Rank
            row.getCell(2).value = report.id; // Número de tienda
            row.getCell(3).value = report.tienda;  // Tienda
            row.getCell(4).value = report.zona; // Zona
            row.getCell(5).value = report.metaReactivacion; // Meta Global (C + CS)
            row.getCell(6).value = report.avanceReactivacion;  // Avance Global (C + CS)
            row.getCell(7).value = report.avanceReactivacionesAsesorCredito; // Avance Crédito
            row.getCell(8).value = report.metaReactivacionCs; // Meta CS
            row.getCell(9).value = report.avanceReactivacionesAsesorCss; // Avance CS
            row.getCell(10).value = report.faltanReactivaciones; // Faltan
            row.getCell(11).value = normalizePercentage(report.porcentajeReactivacion); // % Avance
            row.getCell(12).value = report.metaMigracion; // Meta Global (C + CS)
            row.getCell(13).value = report.avanceMigracion; // Avance Global (C + CS)
            row.getCell(14).value = report.avanceMigracionesAsesorCredito; // Avance Crédito
            row.getCell(15).value = report.metaMigracionCs;// Meta CS
            row.getCell(16).value = report.avanceMigracionesAsesorCss; // Avance CS
            row.getCell(17).value = report.faltanteMigraciones; // Faltan
            row.getCell(18).value = normalizePercentage(report.porcentajeMigracion); // % Avance
            // Cuentas nuevas
            row.getCell(19).value = report.cuentasNuevas?.cn.total; // CN Total
            row.getCell(20).value = report.cuentasNuevas?.cn.totalBasico; // CN Básico #
            row.getCell(21).value = normalizePercentage(report.cuentasNuevas?.cn.totalBasicoPorcentaje); // CN Básico %
            row.getCell(22).value = report.cuentasNuevas?.cncp.total; // CN CP Total
            row.getCell(23).value = report.cuentasNuevas?.cncp.totalBasico; // CN CP Básico #
            row.getCell(24).value = normalizePercentage(report.cuentasNuevas?.cncp.totalBasicoPorcentaje); // CN CP Básico %
            row.getCell(25).value = report.cuentasNuevas?.cncpsf.total; // CN CP SF Total
            row.getCell(26).value = report.cuentasNuevas?.cncpsf.totalBasico; // CN CP SF Básico #
            row.getCell(27).value = normalizePercentage(report.cuentasNuevas?.cncpsf.totalBasicoPorcentaje); // CN CP SF Básico %
            row.getCell(28).value = report.cuentasNuevas?.total; // CN Total Total
            row.getCell(29).value = report.cuentasNuevas?.totalBasico; // CN Total Básico #
            row.getCell(30).value = normalizePercentage(report.cuentasNuevas?.totalBasicoPorcentaje); // CN Total Básico %
            row.getCell(31).value = report.canceladas; // Cuentas totales canceladas en el mismo mes
            row.getCell(32).value = normalizePercentage(report.promedioRetencion); // % DE RETENCIÓN
            // Rechazo inicial
            row.getCell(33).value = report.rechazoTotalCN; // CN Cantidad
            row.getCell(34).value = normalizePercentage(report.rechazoInicialCN); // CN %
            row.getCell(35).value = report.rechazoTotalCNCP; // CN CP Cantidad
            row.getCell(36).value = normalizePercentage(report.rechazoInicialCP); // CN CP %
            row.getCell(37).value = report.rechazoTotalCNCPSF; // CN CP SF Cantidad
            row.getCell(38).value = normalizePercentage(report.rechazoInicialCPSF); // CN CP SF %
            row.getCell(39).value = normalizePercentage(report.porcentajeCanceladas);  // % Cancelación
            row.getCell(40).value = report.totalCuentasRechazo; // Cuentas totales rechazadas
            row.getCell(41).value = normalizePercentage(report.rechazoInicalAnterior); // Anterior
            row.getCell(42).value = normalizePercentage(report.rechazoInicalActual);  // Actual
            row.getCell(43).value = report.ganaEstrella; // ¿Si hoy fuera cierre de mes, ganarías estrella?
            row.getCell(44).value = normalizePercentage(report.metaIppf); // Meta
            row.getCell(45).value = normalizePercentage(report.metaIppfAnterior); // Al mes anterior
            row.getCell(46).value = normalizePercentage(report.ippfFaltante); // Faltante
          } else if (cadenaComercial === '2') { 
            // Mapeo de campos a columnas
            row.getCell(1).value = report.rank; // Rank
            row.getCell(2).value = report.id; // Número de tienda
            row.getCell(3).value = report.tienda;  // Tienda
            row.getCell(4).value = report.zona; // Zona
            row.getCell(5).value = report.metaReactivacion; // Meta Global (C + CS)
            row.getCell(6).value = report.avanceReactivacion;  // Avance Global (C + CS)
            row.getCell(7).value = report.avanceReactivacionesAsesorCredito; // Avance Crédito
            row.getCell(8).value = report.metaReactivacionCs; // Meta CS
            row.getCell(9).value = report.avanceReactivacionesAsesorCss; // Avance CS
            row.getCell(10).value = report.faltanReactivaciones; // Faltan
            row.getCell(11).value = normalizePercentage(report.porcentajeReactivacion); // % Avance
            row.getCell(12).value = report.metaMigracion; // Meta Global (C + CS)
            row.getCell(13).value = report.avanceMigracion; // Avance Global (C + CS)
            row.getCell(14).value = report.avanceMigracionesAsesorCredito; // Avance Crédito
            row.getCell(15).value = report.metaMigracionCs;// Meta CS
            row.getCell(16).value = report.avanceMigracionesAsesorCss; // Avance CS
            row.getCell(17).value = report.faltanteMigraciones; // Faltan
            row.getCell(18).value = normalizePercentage(report.porcentajeMigracion); // % Avance
            // Cuentas nuevas
            row.getCell(19).value = report.cuentasNuevas?.tlc.total; // Total CN
            row.getCell(20).value = report.cuentasNuevas?.tlc.totalBasico; // # Básico con LC > 5000
            row.getCell(21).value = normalizePercentage(report.cuentasNuevas?.tlc.totalBasicoPorcentaje); // % Básico con LC > 5000
            row.getCell(22).value = report.canceladas; // Cuentas totales canceladas en el mismo mes
            row.getCell(23).value = normalizePercentage(report.porcentajeCanceladas);  // % Cancelación
            row.getCell(24).value = report.totalCuentasRechazo; // Cuentas totales rechazadas
            row.getCell(25).value = normalizePercentage(report.promedioRetencion); // % DE RETENCIÓN
            row.getCell(26).value = normalizePercentage(report.rechazoInicalAnterior); // Anterior
            row.getCell(27).value = normalizePercentage(report.rechazoInicalActual);  // Actual
            row.getCell(28).value = report.ganaEstrella; // ¿Si hoy fuera cierre de mes, ganarías estrella?
            row.getCell(29).value = normalizePercentage(report.metaIppf); // Meta
            row.getCell(30).value = normalizePercentage(report.metaIppfAnterior); // Al mes anterior
            row.getCell(31).value = normalizePercentage(report.ippfFaltante); // Faltante
          }
        });

        // buffer del workbook y descargar el archivo
        const buffer = await workbook.xlsx.writeBuffer();
        const cadenaComercialNombre = cadenaComercial === "1" ? "Liverpool" : "Suburbia";
        const nombreArchivo = `Reporte_Tiendas_${cadenaComercialNombre}_${meta}_${mes}_${anio}.xlsx`;
        saveAs(new Blob([buffer]), nombreArchivo);
      } else {
        console.error('No hay datos para descargar');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <button onClick={handleDownloadExcel} className="btn btn-primary mt-2 mb-2">
        Descargar todas las tiendas
    </button>
  );
};

export default ReporteTiendasAll;
