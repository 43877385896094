// Libraries
import axios from "axios";
import { notification } from "./Helpers";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    apiKey: process.env.REACT_APP_APY_KEY,
  },
});
// console.log(process.env.REACT_APP_APY_KEY)

instance.interceptors.request.use((req) => {
  // Obtenemos el token
  const token = localStorage.getItem("token");
  // El token se pide en todos lados menos en el login, pot lo que verificamos que ese token no se envíe ahí
  if (req.url !== "login/acceso") req.headers.Authorization = token;

  return req;
});

instance.interceptors.response.use((res) => {
  // Si el status es 2, significa que el token venció
  if (res.data.status === 2)
    notification("La sesión ha expirado. Por favor, incia sesión de nuevo.");

  // Si el status es 1, hubo un error
  if (res.data.status === 0) notification(res.data.msg);

  // Loggeamos la respuesta para hacer debug
  console.log(`${res.config.url}`);
  console.log(res.data);
  console.groupEnd();

  return res.data;
});

export default instance;
